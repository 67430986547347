
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import Order from "../../API/APIServices/Order";
import moment from "moment";
import Loader from "../../Inventory/Props/Loader";

function FeedbackItemWiseModal(props) {

    const { itemId, filteredData } = props

    const [loading, setLoading] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const [feedbackByItem, setFeedbackByItem] = useState();
    const [totalQty, setTotalQty] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await Order.getFeedbackReportByItemId(itemId, filteredData);
                const total = data.reduce((sum, data) => sum + (data.qty), 0);
                setFeedbackByItem(data);
                setTotalQty(total)
                setLoading(false);
            }
            catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData()
    }, [])

    const fetchTotal = (serviceType, status) => {
        const data = feedbackByItem && feedbackByItem.filter(item => item.orderType == serviceType && item.orderStatus == status)
        // const length = data && data.length
        // return length;
        const cancelQty = data && data.reduce((sum, item) => sum + (item.cancelQty || 0), 0);
        const totalQty = data && data.reduce((sum, item) => sum + (item.qty || 0), 0);
        return (totalQty - cancelQty);
    }

    const fetchCancelQty = (serviceType) => {
        const data = feedbackByItem && feedbackByItem.filter(item => item.orderType == serviceType)
        const totalQty = data && data.reduce((sum, item) => sum + (item.cancelQty || 0), 0);
        return totalQty;
    }

    const fetchTotalQty = (serviceType) => {
        const data = feedbackByItem && feedbackByItem.filter(item => item.orderType == serviceType)
        const qty = data && data.reduce((sum, data) => sum + (data.qty), 0);
        return qty;
    }

    return (
        <div>
            <div className="content_box">
                <div className="company_control">
                    <div className="col-sm-12">
                        <div className="table-resposive" >
                            <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                <thead textAlign="center" className="thead_scroll_orderlist table-tbody th-bg-color" >
                                    <tr className="">
                                        <th className="td_col6 SNO-Width">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                        </th>
                                        <th className="td_col6">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Type</div>
                                        </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Qty</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Time</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Confirm On</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Confirm By</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Done Time</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Status</div>
                                        </div> </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody_body_m1 overflow-pymt">
                                    {feedbackByItem && feedbackByItem.map((item, index) => (
                                        <tr className="table_tbody_Order_List">
                                            <td className="td_col6 td_sno-btn">{index + 1}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderType}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.qty}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemName}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.time}</td>
                                            {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{moment.utc(item.confirmOn).utcOffset("+05:30").format('DD-MM-YYYY, h:mm A')}</td> */}
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{moment.utc(item.confirmOn).utcOffset("+05:30").format('DD-MM-YYYY, h:mm A')}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.confirmBy}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderNo}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderStatus == "Done" ? moment.utc(item.orderDoneTime).utcOffset("+05:30").format(' h:mm A') : ""}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderStatus}</td>
                                        </tr>
                                    ))}
                                    {/* ************************ Footer ************************** */}
                                    <tr className="table_tbody_Order_List"
                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1} rowSpan={4} style={{ textAlign: "end" }}></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1} rowSpan={4} style={{}}><span className="ans"><span className="ques">Total Qty:-</span> {totalQty}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Order Type</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Confirm</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Done</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Pending</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Delivered</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Returned</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Cancelled</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Total Qty</td>
                                    </tr>
                                    <tr className="table_tbody_Order_List"
                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Table Order</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Table Order", "Confirm")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Table Order", "Done")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Table Order", "Pending")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Table Order", "Delivered")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Table Order", "Returned")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchCancelQty("Table Order", "Cancel")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotalQty("Table Order")}</span></td>
                                    </tr>
                                    <tr className="table_tbody_Order_List"
                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Home Delivery</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Home Delivery", "Confirm")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Home Delivery", "Done")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Home Delivery", "Pending")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Home Delivery", "Delivered")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Home Delivery", "Returned")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchCancelQty("Home Delivery", "Cancel")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotalQty("Home Delivery")}</span></td>
                                    </tr>
                                    <tr className="table_tbody_Order_List"
                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Pickup Order</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Pick up", "Confirm")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Pick up", "Done")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Pick up", "Pending")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Pick up", "Delivered")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotal("Pick up", "Returned")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchCancelQty("Pick up", "Cancel")}</span></td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}><span className="ans">{fetchTotalQty("Pick up")}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div >
    );
}

export default FeedbackItemWiseModal;
