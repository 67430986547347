import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef, useContext } from "react";
import Joyride from "react-joyride";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import Gst_Calculation from "../../../API/APIServices/Gst_Calculation";
import LensInfoForInvoice from "../../Props/LensInfoForInvoice";
import ProductList from "../../Props/ProductList";
import BillPaymentInvoice from "../../Props/BillPaymentInvoice";
import AccountMaster from "../../Master/AccountMaster/AccountMaster";
import PreviousOrder from "../../Dashboard/PreviousOrder";
import BillSeriesAPI from "../../../API/APIServices/BillSeriesMaster";
import BillNumberingAPI from "../../../API/APIServices/BillNumbering";
import BillLayoutAPI from "../../../API/APIServices/BillLayoutSetting";
import BillTypeAPI from "../../../API/APIServices/BillType";
import GodownAPI from "../../../API/APIServices/Godown";
import AgentAPI from "../../../API/APIServices/Agent";
import AccountAPI from "../../../API/APIServices/Account";
import StatusAPI from "../../../API/APIServices/Status";
import BillSundryAPI from "../../../API/APIServices/BillSundry";
import TransactionTypeSettingAPI from "../../../API/APIServices/TransactionTypeSetting";
import MobileBottomButton from "../../Props/MobileBottomButton";
import {
  useParams,
  Redirect,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Transaction from "../../../API/APIServices/Transaction";
import { Alert } from "bootstrap";

/**********************Start From(Shivendra 06062024)**********************/

function SaleInvoice(props) {
  const nextInput = useRef([]);
  const nextCellRefs = useRef([]);
  const mainPageInputRef = useRef(null);
  const mainPageInputFocus2 = useRef(null);
  const lensPageInputRef = useRef(null);
  const prodPageInputRef = useRef(null);
  const [ReferenceNumber, setReferenceNumber] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  // }
  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    setSliderOpen(!isSliderOpen);
  };

  const [inputPosition, setInputPosition] = useState(null);

  const getPosition = (rowIndex, columnIndex) => {
    const inputRef = nextCellRefs.current[rowIndex]?.[columnIndex];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom, // Position below the input
        left: rect.left,
        width: rect.width,
      });
    }
  };

  /*********************{Button Script}*********************/

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const [inputValues, setInputValues] = useState({
    TransType: id,
    TransName: "Sale",
    billSeriesMasterType: 29,
    billSeriesId: 0,
    billSeriesName: "",
    billTaxTypeId: 0,
    billTaxTypeName: "",
    billDate: "",
    billNumber: "",
    godownId: 0,
    godownName: "",
    bookedById: 0,
    bookedByName: "",
    DeliveryDate: "",
    vochStatusId: 0,
    vochStatusName: "",
    Remark: "",
    Remark1: "",
    Remark2: "",
    Remark3: "",
    TotalQty: 0,
    TotalPrice: 0,
    TotalDisc: 0,
    TotalItemAmt: 0,
    TotalMrp: 0,
    TotalSundry: 0,
    LoyalityAmt: 0,
    CouponAmt: 0,
    TaxableAmt: 0,
    GrossAmt: 0,
    PaidAmt: 0,
    DueAmt: 0,
    isActive: true,
    isDefault: false,
  });

  useEffect(() => {
    console.log(inputValues);
  }, [inputValues]);

  const [itemMasterRows, setItemMasterRows] = useState([
    {
      rowIndex: 0,
      d1_Id: 0,
      d2_Id: 0,
      productName: "",
      parentGrp: 0,
      parentGrpName: "",
      productUnit: 0,
      productAltUnit: 0,
      unitConvFactor: 0,
      enableBarcode: true,
      defaultBarcode: "",
      hsnCode: "",
      shortDescription: "",

      taxCategory1: 0,
      taxCat1_Cgst: 0,
      taxCat1_Sgst: 0,
      taxCat1_Igst: 0,
      taxCat1_Cess: 0,
      taxLimit: 0,
      taxCategory2: 0,
      taxCat2_Cgst: 0,
      taxCat2_Sgst: 0,
      taxCat2_Igst: 0,
      taxCat2_Cess: 0,
      taxAmt_Cgst: 0,
      taxAmt_Sgst: 0,
      taxAmt_Igst: 0,
      taxAmt_Cess: 0,

      Qty: 0,
      FreeQty: 0,
      purchasePrice: 0,
      purchaseDiscount: 0,
      saleProfit: 0,
      salePrice: 0,
      saleDiscount: 0,
      itemPrice: 0,
      costPrice: 0,
      mrpPrice: 0,
      DiscPer: 0,
      DoubleDis: 0,
      DiscAmt: 0,
      amt_after_discount: 0,
      totalDisc_With_sundry: 0,
      itemWiseTaxbleAmt: 0,
      TotalAmt: 0,
      salePriceForPur: 0,
      availableQuantity: 0,

      iCateRateA: null,
      iCateRateB: null,
      iCateRateC: null,
      iCateRateD: null,
      iCateRateE: null,
      iCateRateF: null,
      iCateRateG: null,
      iCateRateH: null,
      iCateRateI: null,
      iCateRateJ: null,

      loyaltyPoint: null,
      salesManItemIncentive: null,
      BrkrName: null,
      BrkgAmt: null,
      sellerCommision: null,
      isReturnable: null,
      isStockable: null,
      isGoodsService: false,

      lens_Eye: "",
      lens_SPH: null,
      lens_CYL: null,
      lens_AXIS: null,
      lens_Dia: null,
      lens_Add: null,

      Attribute1: "",
      Attribute2: "",
      Attribute3: "",
      Attribute4: "",
      Attribute5: "",
      Note1: "",
      Note2: "",
      BatchNo: "",
      MgfDt: "",
      ExpDt: "",

      isAttributeItem: true,
      iAttributeParent: 0,
      batchDetails: [],
      itemOptionalFields: [],
    },
  ]);

  const [BillSeriesSet, setBillSeries] = useState([]);

  const [TaxTypeSet, setTaxType] = useState([]);
  const [SelectedTaxTypeData, setSelectedTaxType] = useState([]);
  useEffect(() => {
    console.log(itemMasterRows, TaxTypeSet, SelectedTaxTypeData);
  }, [itemMasterRows, TaxTypeSet, SelectedTaxTypeData]);

  const [sundryDiscDistri, setSundryDiscDistri] = useState(false);

  console.log(sundryDiscDistri);

  useEffect(() => {
    if (SelectedTaxTypeData.length > 0) {
      const sundryDiscDistriData = SelectedTaxTypeData[0].sundryDiscDistri;
      setSundryDiscDistri(sundryDiscDistriData);
    }
  }, [SelectedTaxTypeData]);

  const [GodownSet, setGodown] = useState([]);
  const [AgentSet, setAgent] = useState([]);
  const [StatusMasterList, setStatusMasterList] = useState([]);

  const [TransTypeSetting, setTransTypeSetting] = useState();
  const [IsExistTransInputSet, setIsExistTransInput] = useState(false);
  const [TransInputSet, setTransInput] = useState({
    masterType: 217,
    transType: inputValues.TransType,
    transName: inputValues.TransName,
    title: false,
    dateOfBirth: false,
    anniversaryDate: false,
    pos: false,
    accBal: false,
    creditLimit: false,
    referredBy: false,
    bookedBy: false,
    godown: false,
    deliveryStatus: false,
    deliveryDate: false,
    remark: false,
    remark1: false,
    remark2: false,
    remark3: false,
    extraField1: false,
    extraField2: false,
    extraField3: false,
    extraField4: false,
    extraField5: false,
  });

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [AccountMasterList, setAccountMasterList] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [InputValueAccMaster, setInputValueAccMaster] = useState({
    AccId: 0,
    namePrefix: "",
    AccName: "",
    AccPhoneNo: "",
    AccDob: "",
    AccAnn: "",
    AccPOS: "",
    AccBalance: "",
    AccCreditLimit: "",
    AccAddress: "",
    AccStateId: 0,
    AccState: "",
    AccRefById: 0,
    AccRefferedBy: "",
  });

  useEffect(() => {
    console.log(InputValueAccMaster);
  }, [InputValueAccMaster]);

  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [filteredRefferedBy, setFilteredRefferedBy] = useState([]);

  const [sundryList, setSundryList] = useState([]);
  const [sundryRows, setSundryRows] = useState([
    {
      rowIndex: 0,
      id: 0,
      name: "",
      printName: "",
      sundryType: 0,
      methodApplied: "",
      absolutePercentAmount: false,
      defaultValue: 0,
      percentOfAmount: 100,
      applyOnBill: 1,
      roundOffSundryAmount: false,
      amtRoundOffConfig: 0,
      amtAdjustSaleAccount: false,
      selectedAccountSale: 0,
      amtAdjustPurchaseAccount: false,
      selectedAccountPurchase: 0,
      roundOffLimit: 0,
      Percent: 0,
      Amount: 0,
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredSundry: [],
    },
  ]);

  useEffect(() => {
    console.log(sundryRows);
  }, [sundryRows]);

  const GetDataForAll = async () => {
    GetProductDataFunction();
    GetBillSundryeDataFunction();
    GetTransBillDataFunction();
    GetAccountDataFunction();
  };

  const GetTransBillDataFunction = async () => {
    setLoading(true);
    var TransData = await Transaction.GetTransBillData(
      location?.state?.refID || ReferenceNumber
    );
    if (TransData) {
      var data = await AccountAPI.getAccountById(TransData.partyId);
      debugger;
      var AccData = data.item1[0];
      if (AccData) {
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: AccData.d2Id,
          namePrefix: AccData.namePrefix || "",
          AccName: AccData.name || "",
          AccPhoneNo: AccData.mobileNo || "", //email enableLoyalty namePrefix
          AccDob: AccData.dob || "",
          AccAnn: AccData.dom || "",
          //AccPOS: AccData.AccPOS || "",
          // AccBalance: AccData.openingCrDr || "",
          AccCreditLimit: AccData.creditlimit || "",
          AccAddress: AccData.address || "",
          AccStateId: AccData.stateId || "",
          AccState: AccData.stateName || "",
          AccRefById: TransData.refById || "",
          AccRefferedBy: TransData.refByName || "",
        }));
      }

      setInputValues((prevValues) => ({
        ...prevValues,
        TransType: TransData.transtype,
        TransName: TransData.TransName,
        billSeriesMasterType: TransData.billSeriesMasterType,
        billSeriesId: TransData.billSeriesId,
        billSeriesName: TransData.billSeriesName,
        billTaxTypeId: TransData.billTypeId,
        billTaxTypeName: TransData.billTypeName,
        billDate: TransData.billDate,
        billNumber: TransData.billNumber,
        godownId: TransData.godownId,
        godownName: TransData.godownName,
        bookedById: TransData.bookedById,
        bookedByName: TransData.bookedByName,
        DeliveryDate: TransData.billDeliveryDate,
        vochStatusId: TransData.billStatus,
        vochStatusName: TransData.billStatusName,
        Remark: TransData.billRemark || "",
        Remark1: TransData.billRemark1 || "",
        Remark2: TransData.billRemark2 || "",
        Remark3: TransData.billRemark3 || "",
      }));
      setLoading(false);
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  const GetProductDataFunction = async () => {
    // setLoading(true);
    var Productdata = await Transaction.GetProductTempData(
      location?.state?.refID || ReferenceNumber
    );
    debugger;
    var data = Productdata.items;
    if (data) {
      console.log(data);
      var ProductDataList = [];
      for (var i = 0; i < data?.length; i++) {
        var obj = {
          rowIndex: Number(data[i].indx) || 0,
          defaultBarcode: data[i].itmCode || "",
          // d1_Id: Number(data[i].itemId_d2) || 0,
          d2_Id: Number(data[i].itmId_d2) || 0,
          productName: data[i].itemName || "",
          parentGrp: Number(data[i].itmGrpId_d2) || 0,
          parentGrpName: data[i].itmGrpName || "",
          godownId: Number(data[i].godownId) || 0,
          godownName: data[i].godownName || "",
          masterDescription: data[i].masterDescriptn || "",
          shortDescription: data[i].itemDescriptn || "",
          // itemAttributes: data[i].itemAttributes || [],
          batchNo: Number(data[i].batchNo) || "",
          mgfDate: data[i].mgfDate || "",
          expDate: data[i].expDate || "",
          productUnit: Number(data[i].unitId) || 0,
          Qty: Number(data[i].qty) || 0,
          FreeQty: Number(data[i].freeQty) || 0,
          itemPrice: Number(data[i].priceUnit) || 0,
          DiscPer: Number(data[i].disc1Per) || 0,
          DiscAmt: Number(data[i].disc1Amt) || 0,
          DoubleDis: Number(data[i].disc2Per) || 0,
          discountAmt2: Number(data[i].disc2Amt) || 0,
          salePrice: Number(data[i].salePrice) || 0,
          TotalAmt: Number(data[i].itmNetAmt) || 0,
          mrpPrice: Number(data[i].itmMrp) || 0,
          purPageSaleProfPer: Number(data[i].purPageSaleProfPer) || 0,
          purPageSalePricePer: Number(data[i].purPageSalePricePer) || 0,
          purPageMrpPrice: Number(data[i].purPageMrpPrice) || 0,
          lens_Eye: Number(data[i].lens_Eye) || "",
          lens_Dia: Number(data[i].lens_Dia) || "",
          lens_AXIS: Number(data[i].lens_AXIS) || 0,
          lens_SPH: Number(data[i].lens_SPH) || 0,
          lens_CYL: Number(data[i].lens_CYL) || 0,
          lens_Add: Number(data[i].lens_Add) || 0, // Swapped 'lens_Add' and 'add'
          extraFields: data[i].extraFields || [],
        };
        ProductDataList.push(obj);
        setItemMasterRows(ProductDataList);
        // setLoading(false);
      }
      var AmountData = Productdata.transSummary;
      console.log(AmountData);
      setInputValues((prevValues) => ({
        ...prevValues,
        TotalQty: AmountData.transTotalQty,
        TotalPrice: AmountData.transTotalPrice,
        TotalDisc: AmountData.transTotalDisc,
        TotalItemAmt: AmountData.transTotalPrice,
        TotalSundry: AmountData.transTotalSundryAmt,
        TaxableAmt: AmountData.transTaxable,
        GrossAmt: AmountData.transGrossValue,
        PaidAmt: AmountData.transPaidAmount,
        DueAmt: AmountData.transBalanceAmount,
        // TotalMrp: AmountData.,
        // LoyalityAmt: AmountData.,
        // CouponAmt: AmountData.,
      }));
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  const GetBillSundryeDataFunction = async () => {
    // setLoading(true);

    var SundryData = await Transaction.GetSundryTempData(
      location?.state?.refID || ReferenceNumber
    );
    debugger;

    if (SundryData) {
      console.log(SundryData);
      var data = SundryData.result;
      debugger;

      var SundryDataList = [];
      for (var i = 0; i < data?.length; i++) {
        var obj = {
          rowIndex: Number(data[i].RowIndex) || 0,
          id: Number(data[i].SundryId) || 0,
          name: data[i].SundryName,
          printName: data[i].SundryNature,
          sundryType: 0,
          methodApplied: "",
          absolutePercentAmount: false,
          defaultValue: 0,
          percentOfAmount: 100,
          applyOnBill: 1,
          roundOffSundryAmount: false,
          amtRoundOffConfig: 0,
          amtAdjustSaleAccount: false,
          selectedAccountSale: 0,
          amtAdjustPurchaseAccount: false,
          selectedAccountPurchase: 0,
          roundOffLimit: 0,
          Percent: Number(data[i].SundryPer) || 0,
          Amount: Number(data[i].SundryAmt) || 0,
          isDropdownOpen: false,
          highlightedIndex: -1,
          filteredSundry: [],
        };
        SundryDataList.push(obj);
      }
      if (SundryDataList.length > 0) {
        setSundryRows(SundryDataList);
      }
      // setLoading(false);
    } else {
      console.log("error");
      // setLoading(false);
    }
  };

  const GetAccountDataFunction = async () => {
    setLoading(true);
    var AccData = await Transaction.GetAccountTempData(
      location?.state?.refID || ReferenceNumber
    );
    if (AccData) {
      debugger;
      console.log(AccData);
      setLoading(false);
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      // Navigate to "/saleinvoice/12" if no ID is found in the URL
      navigate("/saleinvoice/12");
    } else {
      // Check if location.state exists and refID is available
      if (location.state && location.state.refID) {
        setReferenceNumber(location.state.refID);
      } else {
        GetTransRefNo();
      }
      if (location.state && location.state.isEdit) {
        setIsEdit(location.state.isEdit);
        GetDataForAll();
      }
    }
  }, [id, navigate, location]);

  const GetTransRefNo = async () => {
    var RefNoData = await Transaction.GetTransRefNo(id);
    // debugger;
    if (RefNoData.isSuccess === true) {
      setReferenceNumber(RefNoData.result.referenceNumber);
    } else {
      alert("Error while getting Transaction Reference No.");
    }
  };

  useEffect(() => {
    const fetchAllList = async () => {
      setLoading(true);
      debugger;

      try {
        const filterData1 = [
          {
            fieldName: "masterType",
            operatorName: "equal",
            compareValue: inputValues.billSeriesMasterType,
          },
        ];
        if (inputValues.billSeriesMasterType) {
          const result1 = await BillSeriesAPI.getBillSeriesMasterFiltered(
            filterData1
          );
          if (result1.length > 0) {
            setBillSeries(result1);
          }
        }

        const filterData2 = [
          {
            fieldName: "billType",
            operatorName: "equal",
            compareValue: inputValues.TransType,
          },
        ];
        BillTypeAPI.GetFilteredBillType(filterData2).then(function (result1) {
          debugger;
          setTaxType(result1);
        });

        GodownAPI.getGodown().then(function (result1) {
          setGodown(result1);
        });

        AgentAPI.GetAgent().then(function (result1) {
          setAgent(result1);
        });

        /*AccountAPI.getAccount().then(function (result1) {
                            setAccountMasterList(result1);
                        })*/

        StatusAPI.GetStatus().then(function (result1) {
          setStatusMasterList(result1);
        });

        TransactionTypeSettingAPI.GetTransactionTypeSetting(
          inputValues.TransType
        ).then(function (result1) {
          setTransTypeSetting(result1);
        });

        TransactionTypeSettingAPI.GetVoucherWise_InputSetting(
          inputValues.TransType
        ).then(function (result1) {
          if (result1 && result1.length > 0) {
            setTransInput(result1[0]);
          }
          //setTransInput(result1[0]);
          setIsExistTransInput(true);
        });

        BillSundryAPI.GetBillSundry().then(function (result1) {
          debugger;
          setSundryList(result1);
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchAllList();
  }, [inputValues.TransType]);

  const [loading, setLoading] = useState(true);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    companyData,
  } = useContext(MyContext);

  var currencyDecimalPlaces = companyData?.currencyDecimalPlaces || 2;

  /***********title***************/
  useState(() => {
    document.title = "Sale Invoice";
  }, []);

  /************Image add******************/
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener("contextmenu", handleContextMenu);

    //Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  /********************Tab*******************/

  const [activeTab, setActiveTab] = useState(11); // Initialize the active tab index
  const [activeTab1, setActiveTab1] = useState(1); // Initialize the active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleTabClick1 = (index) => {
    setActiveTab1(index);
  };
  const [tabcontent11, setTabContent11] = useState(true);
  const [tabcontent22, setTabContent22] = useState(false);
  //const [tabcontent33, setTabContent33] = useState(false);
  const [tabcontent44, setTabContent44] = useState(false);

  const showtabs11 = () => {
    setTabContent11(true);
    setTabContent22(false);
    //setTabContent33(false)
    setTabContent44(false);
  };
  const showtabs22 = () => {
    setTabContent11(false);
    setTabContent22(true);
    setTabContent44(false);
  };
  const showtabs44 = () => {
    setTabContent11(false);
    setTabContent22(false);
    setTabContent44(true);
  };

  {
    /********************Tab*******************/
  }

  /************modal box************/

  const [isModalOpen1, setModalOpen1] = useState(false);

  const showModal1 = () => {
    setModalOpen1(true);
  };
  const hideModal1 = () => {
    setModalOpen1(false);
  };

  const [isModalOpen2, setModalOpen2] = useState(false);
  const showModal2 = () => {
    setModalOpen2(true);
  };
  const hideModal2 = () => {
    setModalOpen2(false);
  };
  /**********************{modal box}**********************/

  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  /*****************Onboarding code Open************************/

  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>Upload Item Image!</h2>,
        placement: "bottom",
        target: "#step-1",
        title: "First step",
      },
      {
        content: <h2>Here is second step!</h2>,
        placement: "bottom",
        target: "#step-2",
        title: "Second step",
      },
      {
        content: <h2>Here is third step!</h2>,
        placement: "bottom",
        target: "#step-3",
        title: "Third step",
      },
      {
        content: <h2>Here is fourth step!</h2>,
        placement: "bottom",
        target: "#step-4",
        title: "Fourth step",
      },
      {
        content: <h2>Here is fifth step!</h2>,
        placement: "bottom",
        target: "#step-5",
        title: "Fifth step",
      },
      {
        content: <h2>Here is six step!</h2>,
        placement: "bottom",
        target: "#step-6",
        title: "Six step",
      },
    ],
  });

  /*****************Onboarding code Close************************/

  /*************************** Party Detail ***************************************/

  const [PartyDetailModal, setPartyDetailModal] = useState(false);
  const OpenPartyModal = () => {
    setPartyDetailModal(true);
  };
  const ClosePartyModal = () => {
    setPartyDetailModal(false);
  };

  const [MobileSettingSlider, setMobileSettingSlider] = useState(false);

  const bottomSliderOpen = () => {
    setMobileSettingSlider(true);
  };
  const closeBottomSlider = () => {
    setMobileSettingSlider(false);
  };

  const [CouponModal, setCouponModal] = useState(false);

  const openCouponModal = () => {
    setCouponModal(true);
  };
  const closeCouponModal = () => {
    setCouponModal(false);
  };

  const ApplyTaxClick = () => {
    const val = document.getElementById("BillType").value;
    console.log(val);

    var newRows;
    if (val === "12% GST(L)") {
      newRows = [
        { id: 1, TaxName: "CGST", Percent: "6%", Amount: "500" },
        { id: 2, TaxName: "SGST", Percent: "6%", Amount: "500" },
      ];
      setSundryRows([...newRows]);
    } else if (val === "12% GST(I)") {
      newRows = [{ id: 1, TaxName: "IGST", Percent: "12%", Amount: "1000" }];
      setSundryRows([...newRows]);
    }
  };

  const [AccountMasterModal, setAccountMasterModal] = useState(false);

  const openAccountMasterModal = (val) => {
    if (val === "Add") {
      setAccountMasterModal(true);
      //document.getElementById('PartySelectBox').value = "";
      setIsDropdownOpen1(false);
    }
  };

  const closeAccountMasterModal = () => {
    setAccountMasterModal(false);
  };

  //---------------Fill List Of All Required Items (Load Event)-----------------------

  //---------------Fill Bill Numbering Setting (Load Event)-----------------------

  const [BillNumberingSetting, setBillNumberingSetting] = useState([]);
  const [BillLayoutSetting, setBillLayoutSetting] = useState([]);
  const [BillTypeSetting, setBillTypeSetting] = useState([]);

  useEffect(() => {
    const fetchBillNumberingSett = async (
      billSeriesMasterType,
      billSeriesId
    ) => {
      try {
        const data1 = await BillNumberingAPI.getBillNumberingbyId(
          billSeriesMasterType,
          billSeriesId
        );
        setBillNumberingSetting(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBillNumberingSett(
      inputValues.billSeriesMasterType,
      inputValues.billSeriesId
    );
  }, [inputValues.billSeriesMasterType, inputValues.billSeriesId]);

  //---------------Fill Bill Layout Setting (Load Event)-----------------------

  useEffect(() => {
    const fetchBillLayoutSett = async (billSeriesId) => {
      try {
        const data1 = await BillLayoutAPI.getBillLayoutSettingById(
          billSeriesId
        );
        setBillLayoutSetting(data1);
        //console.log(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBillLayoutSett(inputValues.billSeriesId);
  }, [inputValues.billSeriesId]);

  //---------------Fill Bill Type Setting (Load Event)-----------------------

  useEffect(() => {
    const fetchBillTypeSett = async (billTaxTypeId) => {
      try {
        const data1 = await BillTypeAPI.GetBillTypeById(billTaxTypeId);
        setBillTypeSetting(data1);
        console.log(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBillTypeSett(inputValues.billTaxTypeId);
  }, [inputValues.billTaxTypeId]);

  //---------------Focus on the next input field if Enter is pressed-----------------------

  const handleKeyDownMainPage = (e) => {
    if (e.key === "Enter") {
      debugger;
      let nextIndex = nextInput.current.indexOf(e.target) + 1;
      if (e.target.name == "LoyalityAmt") {
        nextIndex = 102;
      }
      if (e.target.name == "AccRefferedBy") {
        try {
          lensPageInputRef.current.focus();
        } catch (e) {
          mainPageInputRef.current.focus();
        }
      } else if (nextIndex < nextInput.current.length) {
        nextInput.current[nextIndex].focus();
      } else {
      }
    }
  };

  //---------------For Series,BillType,Godown,Party Detail Inpute Change-----------------------

  const handleInputChange_Series = (e) => {
    debugger;
    var { name, type, value, checked } = e.target;

    console.log(name);

    /*setInputValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));*/

    if (value !== "null") {
      if (name === "billDate" || name === "DeliveryDate") {
        if (value != "") {
          setInputValues((prevValues) => {
            const updatedValues = {
              ...prevValues,
              [name]: value || 0,
            };

            const grossAmt =
              (parseFloat(updatedValues.TaxableAmt) || 0) -
              (parseFloat(updatedValues.LoyalityAmt) || 0) -
              (parseFloat(updatedValues.CouponAmt) || 0);
            return {
              ...updatedValues,
              GrossAmt: grossAmt.toFixed(2),
            };
          });
        }
      } else if (name === "billNumber") {
        setInputValues((prevValues) => {
          const updatedValues = {
            ...prevValues,
            [name]: value || 0,
          };

          const grossAmt =
            (parseFloat(updatedValues.TaxableAmt) || 0) -
            (parseFloat(updatedValues.LoyalityAmt) || 0) -
            (parseFloat(updatedValues.CouponAmt) || 0);
          return {
            ...updatedValues,
            GrossAmt: grossAmt.toFixed(2),
          };
        });
      } else {
        setInputValues((prevValues) => {
          const updatedValues = {
            ...prevValues,
            [name]: parseFloat(value) || 0,
          };

          const grossAmt =
            (parseFloat(updatedValues.TaxableAmt) || 0) -
            (parseFloat(updatedValues.LoyalityAmt) || 0) -
            (parseFloat(updatedValues.CouponAmt) || 0);
          return {
            ...updatedValues,
            GrossAmt: grossAmt.toFixed(2),
          };
        });
      }
    }
    debugger;
    if (name === "billTaxTypeId") {
      const SelectedTaxTypeData = TaxTypeSet.filter(
        (item) => item.id == e.target.value
      );
      if (SelectedTaxTypeData.length == 1) {
        setSelectedTaxType(SelectedTaxTypeData);
      }
    }

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  useEffect(() => {
    // console.log(inputValues);
  }, [inputValues]);

  //---------------Account and RefferedBy Change-----------------------

  const handleAccountMasterChange = async (event) => {
    const { name, value } = event.target;
    // debugger;
    //console.log(`Changing ${name} to ${value}`);

    setInputValueAccMaster((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Filter items based on the input value
    const filteredData = [
      {
        fieldName: "name",
        operatorName: "contains",
        compareValue: value || "",
      },
      { fieldName: "MasterType", operatorName: "equal", compareValue: "2" },
    ];
    const SearchItem = await AccountAPI.getAccountWithFilter(filteredData);

    if (name === "AccName") {
      if (SearchItem) {
        setFilteredAccount(SearchItem);
      }
      setIsDropdownOpen1(true);
    } else if (name === "AccRefferedBy") {
      if (SearchItem) {
        setFilteredRefferedBy(SearchItem);
      }
      setIsDropdownOpen2(true);
    }
  };

  const suggestionBoxRef = useRef(null);
  const itemRefs = useRef([]); // Reference for each list item

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    if (
      highlightedIndex >= 0 &&
      itemRefs.current[highlightedIndex] &&
      suggestionBoxRef.current
    ) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedIndex]);

  const handleAccountSelection = (id, selectedAcc, _name) => {
    debugger;
    if (id === selectedAcc.d2Id && _name === "AccName") {
      setInputValueAccMaster((prevValues) => ({
        ...prevValues,
        AccId: id,
        namePrefix: selectedAcc.namePrefix || "",
        AccName: selectedAcc.name || "",
        AccPhoneNo: selectedAcc.mobileNo || "", //email enableLoyalty namePrefix
        AccDob: selectedAcc.dob || "",
        AccAnn: selectedAcc.dom || "",
        //AccPOS: selectedAcc.AccPOS || "",
        AccBalance: selectedAcc.openingCrDr || "",
        AccCreditLimit: selectedAcc.creditlimit || "",
        AccAddress: selectedAcc.address || "",
        AccStateId: selectedAcc.stateId || "",
        AccState: selectedAcc.stateName || "",
        AccRefferedBy: selectedAcc.referralByName || "",
      }));

      setIsDropdownOpen1(false);
    } else if (id === selectedAcc.d2Id && _name === "AccRefferedBy") {
      debugger;
      setInputValueAccMaster((prevValues) => ({
        ...prevValues,
        AccRefById: id,
        AccRefferedBy: selectedAcc.name,
      }));

      setIsDropdownOpen2(false);
    }
  };

  const closeSuggestionBox = (e) => {
    debugger;
    if (e.target.name == "AccName") {
      var data = filteredAccount.filter((name) => name.name == e.target.value);
      if (data.length != 1) {
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: 0,
          namePrefix: "",
          AccPhoneNo: "",
          AccDob: "",
          AccAnn: "",
          AccPOS: "",
          AccBalance: "",
          AccCreditLimit: "",
          AccAddress: "",
          AccStateId: "",
          AccState: "",
          AccRefById: "",
          AccRefferedBy: "",
        }));
      }
    }

    onChangeSaveMethod();
    setTimeout(() => {
      setIsDropdownOpen2(false);
      setIsDropdownOpen1(false);
    }, 500);
  };

  //---------------Sundry Name Change-----------------------

  const handleSundryNameChange = (event, rowIndex) => {
    const { value } = event.target;
    setSundryRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].name = value;
      newRows[rowIndex].filteredSundry = sundryList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      newRows[rowIndex].isDropdownOpen = true;
      newRows[rowIndex].highlightedIndex = -1;
      return newRows;
    });
  };

  const handleSundrySelection = (rowIndex, selectedSundry) => {
    debugger;
    if (selectedSundry.methodApplied == "discount") {
      setSundryRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          id: selectedSundry.id,
          name: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          Percent: selectedSundry.absolutePercentAmount
            ? 0
            : selectedSundry.defaultValue || 0,
          Amount: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || 0
            : 0,
          isDropdownOpen: false,
          filteredSundry: [],
        };
        // onChangeSundrySaveMethod(newRows);
        return newRows;
      });
    } else {
      setSundryRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          id: selectedSundry.id,
          name: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          Percent: selectedSundry.absolutePercentAmount
            ? 0
            : selectedSundry.defaultValue || 0,
          Amount: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || 0
            : 0,
          isDropdownOpen: false,
          filteredSundry: [],
        };
        // onChangeSundrySaveMethod(newRows);
        return newRows;
      });
    }
  };

  const handleSundryRowKeyDown = (event, rowIndex, columnIndex) => {
    if (event.key === "Enter") {
      const newRow = {
        id: sundryRows.length + 1,
        name: "",
        printName: "",
        sundryType: 0,
        methodApplied: "",
        absolutePercentAmount: false,
        defaultValue: 0,
        percentOfAmount: 100,
        applyOnBill: 1,
        roundOffSundryAmount: false,
        amtRoundOffConfig: 0,
        amtAdjustSaleAccount: false,
        selectedAccountSale: 0,
        amtAdjustPurchaseAccount: false,
        selectedAccountPurchase: 0,
        roundOffLimit: 0,
        Percent: 0,
        Amount: 0,
        isDropdownOpen: false,
        highlightedIndex: -1,
        filteredSundry: [],
      };

      if (rowIndex === sundryRows.length - 1) {
        if (event.target.name === "Amount") {
          setSundryRows((prevRows) => {
            const newRows = [...prevRows, newRow];
            return newRows;
          });
        }
      }

      // Calculate next column index
      const nextColumnIndex = columnIndex + 1;
      const nextRowCellRef = nextCellRefs.current[rowIndex][nextColumnIndex];

      if (nextRowCellRef) {
        nextRowCellRef.focus();
      } else {
        setTimeout(() => {
          const nextRowIndex = rowIndex + 1;
          let nextRowCellId = `cell-${nextRowIndex}-0`;
          let nextRowCell = document.getElementById(nextRowCellId);

          if (nextRowCell) {
            nextRowCell.focus();
          }
        }, 10);
      }
    }
  };

  const handleSundryCellChange = (event, rowIndex) => {
    const { name, value } = event.target;

    /*const updatedRows = [...sundryRows];
            updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                [event.target.name]: event.target.value,
            };
            setSundryRows(updatedRows);
            */

    setSundryRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        [name]: value,
      };

      // TotalSundryAmount Calculation
      // const { Percent, Amount } = newRows[rowIndex];
      // if (name === "Percent" || name === "Amount") {
      //   let sunType_ = 0;
      //   if (name === "Percent") {
      //     sunType_ = 1;
      //   }
      //   if (name === "Amount") {
      //     sunType_ = 2;
      //   }
      //   const sunRowAmt = calculateSundryTotalAmount(
      //     inputValues.TotalItemAmt,
      //     Percent,
      //     Amount,
      //     sunType_
      //   );

      //   if (name !== "Amount") {
      //     newRows[rowIndex].Amount = sunRowAmt.sunRowAmt; // Use sunRowAmt directly
      //   }
      // }

      // updateSundryTotals(newRows);

      return newRows;
    });
  };

  const deleteSundryRow = (rowIndex) => {
    const updatedRows = sundryRows.filter((_, index) => index !== rowIndex);
    setSundryRows(updatedRows);
    updateSundryTotals(updatedRows);

    onChangeSundrySaveMethod();
  };

  //--------------- Sundry Row Input Calculation -----------------------

  const calculateSundryTotalAmount = (
    totalItemAmount,
    sunPercent,
    sunAmount,
    sunType_
  ) => {
    let sunRowAmt = 0;
    if (sunType_ === 1) {
      //  Percentage
      sunRowAmt = totalItemAmount * (sunPercent / 100);
    } else if (sunType_ === 2) {
      //  Amount
      sunRowAmt = sunAmount;
    }

    // return { sunRowAmt };
  };

  //--------------- Update the total Sundry amount -----------------------
  const updateSundryTotals = (rows) => {
    // let totalAmount = 0;
    // rows.forEach((row) => {
    //   if (row.sundryType === 1) {
    //     totalAmount += parseFloat(row.Amount) || 0;
    //   } else {
    //     totalAmount -= parseFloat(row.Amount) || 0;
    //   }
    // });
    // let finalTaxable = inputValues.TotalItemAmt - totalAmount;
    // setInputValues((prevValues) => ({
    //   ...prevValues,
    //   TotalSundry: totalAmount.toFixed(2),
    //   TaxableAmt: finalTaxable.toFixed(2),
    // }));
  };

  //---------------Auto-suggestion account Change-----------------------

  const handleKeyDown_AccountSuggestion = (e, ToFilterData) => {
    debugger;

    if (!ToFilterData.length) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % (ToFilterData.length + 1) // +1 for "Add New" option
        );

        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? ToFilterData.length : prevIndex - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < ToFilterData.length) {
          if (e.target.name === "AccName") {
            handleAccountSelection(
              ToFilterData[highlightedIndex].d2Id,
              ToFilterData[highlightedIndex],
              "AccName"
            );
          } else if (e.target.name === "AccRefferedBy") {
            handleAccountSelection(
              ToFilterData[highlightedIndex].d2Id,
              ToFilterData[highlightedIndex],
              "AccRefferedBy"
            );
          }
        }
        break;
      case "Escape":
        setIsDropdownOpen1(false);
        break;
      default:
        break;
    }
  };

  //---------------Auto-suggestion sundry Change-----------------------

  const handleKeyDown_SundrySuggestion = (e, rowIndex) => {
    const { filteredSundry, highlightedIndex } = sundryRows[rowIndex];
    if (filteredSundry && !filteredSundry.length) {
      handleSundryRowKeyDown(e, rowIndex, 1);
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            (highlightedIndex + 1) % filteredSundry.length;
          return newRows;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            highlightedIndex === 0
              ? filteredSundry.length
              : highlightedIndex - 1;
          return newRows;
        });
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredSundry.length) {
          handleSundrySelection(rowIndex, filteredSundry[highlightedIndex]);
        }
        handleSundryRowKeyDown(e, rowIndex, 1);
        break;
      case "Escape":
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].isDropdownOpen = false;
          return newRows;
        });
        break;
      default:
        break;
    }
  };

  //---------------Save Input Show/Hide Setting Change-----------------------

  const handleHideAndShow_InputBox = (e) => {
    const { name, checked } = e.target;

    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...TransInputSet,
      [name]: checked,
    };

    setTransInput(updatedValues);

    setLoading(true);
    try {
      if (IsExistTransInputSet) {
        const isSuccess =
          TransactionTypeSettingAPI.UpdateVoucherWise_InputSettingAsync(
            updatedValues
          );
        if (isSuccess) {
          setLoading(false);
          alert("update");
        } else {
          alert("error while updating");
        }
      } else {
        const isSuccess =
          TransactionTypeSettingAPI.CreateVoucherWise_InputSettingAsync(
            updatedValues
          );
        if (isSuccess) {
          setLoading(false);
          alert("save");
          setTimeout(() => {
            setIsExistTransInput(true);
          }, 1000);
        } else {
          alert("error while updating");
        }
      }
    } catch (e) {}
    setLoading(false);

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  /********************************************/

  //------------------- GST CALCULATION ITEM WISE -------------------
  /*const handleGSTCalculation = async (newRows) => {
  
          const result = await Gst_Calculation.ItemWiseGSTCalculation(
              newRows, itemMasterRows, setItemMasterRows, BillTypeSetting, inputValues, sundryRows);
      };*/

  //------------------- GST CALCULATION SUNDRY WISE -------------------
  function convertStringToJSON(inputString) {
    return inputString.split(",").reduce((acc, pair) => {
      const [key, value] = pair.split(":").map((str) => str.trim()); // Split and trim key-value pairs
      acc[key] = isNaN(value) ? value : Number(value); // Convert numeric strings to numbers
      return acc;
    }, {});
  }
  const SaveMethod = async () => {
    debugger;

    if (
      !InputValueAccMaster.AccId ||
      InputValueAccMaster.AccId == 0 ||
      InputValueAccMaster.AccId == ""
    ) {
      var accountSave = await AccountAPI.insertAccount(InputValueAccMaster, []);
      debugger;
      if (accountSave.isSuccess == false) {
        if (accountSave.errorMessages[0] == "Invalid data name") {
          alert("Account already exists");
          return;
        }
      }

      if (accountSave.isSuccess == true) {
        var AccIdinJson = convertStringToJSON(accountSave.result.result);

        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: AccIdinJson.d2id,
        }));

        // handleAccountSelection(AccIdinJson.d2id);
        await onChangeSaveMethod(AccIdinJson.d2id, 1);
        await saveMethod2();
      } else {
        alert("Error while creating Account");
      }
    } else {
      saveMethod2();
    }
  };

  const saveMethod2 = async () => {
    debugger;
    setTimeout(async () => {
      // Make the callback function async
      try {
        var TransBillData = await Transaction.SaveTransactions(ReferenceNumber);
        if (TransBillData.isSuccess === true) {
          alert("Trans Bill Data saved");
        } else {
          alert("Error in Trans Bill Data");
        }
      } catch (error) {
        console.error("Error while saving transaction:", error);
        alert("Error in saving transaction");
      }
    }, 1000);
  };

  const onChangeSaveMethod = async (d2id, Method) => {
    // Set d2id to AccId only if AccId is 0
    debugger;
    if (InputValueAccMaster.AccId == 0 && Method == 1) {
      InputValueAccMaster.AccId = d2id; // Corrected to assignment
    }

    // Proceed only if AccId is valid (not 0)
    if (InputValueAccMaster.AccId && InputValueAccMaster.AccId != 0) {
      try {
        // Attempt to save the transaction bill data
        const TransBillData = await Transaction.SetTransBillData(
          inputValues,
          InputValueAccMaster,
          ReferenceNumber
        );

        if (TransBillData.isSuccess === true) {
          console.log("Trans Bill Data saved");
        } else {
          alert("Error in Trans Bill Data");
        }
      } catch (error) {
        console.error(
          "Error occurred while saving the transaction bill data:",
          error
        );
      }
    }
  };

  const [ApiCallProductAllowed, setApiCallProductAllowed] = useState(true);

  useEffect(() => {
    console.log(ApiCallProductAllowed);
  }, [ApiCallProductAllowed]);

  const onChangeProductSaveMethod = async (e, Rowidx, Index) => {
    debugger;
    // Check if API call is allowed before proceeding

    if (!ApiCallProductAllowed) {
      console.log("API Call is in progress, please wait.");
      return; // Exit the function if API call is still in progress
    }

    setApiCallProductAllowed(false); // Disable further API calls

    // Filter the row to update
    var RowToUpdate = itemMasterRows.filter((item) => item.rowIndex === Rowidx);
    console.log(ApiCallProductAllowed);

    // Ensure there's a valid product name
    if (RowToUpdate[0].productName || RowToUpdate[0].productName !== "") {
      try {
        // Call the API to save product temp data
        var ProductTempData = await Transaction.SetProductTempData(
          RowToUpdate,
          ReferenceNumber
        );

        // Handle API response
        if (ProductTempData.isSuccess === false) {
          console.log("API call failed. Response:", ProductTempData);
        } else if (ProductTempData.isSuccess === true) {
          console.log("Product Temp Data saved successfully.");

          // NewRowIndex should contain the new row index to be updated
          var NewRowIndex = ProductTempData.result;

          if (NewRowIndex.length === 1) {
            // Update itemMasterRows with the new row index
            setItemMasterRows((prevRows) => {
              return prevRows.map((row, idx) => {
                if (idx === Index) {
                  return { ...row, rowIndex: NewRowIndex[0] };
                }
                return row;
              });
            });
          }

          // alert(e.target.name);
          debugger;
          if (e.target.name !== "mrpPrice" && e.target.name !== "TotalAmt") {
            GetProductDataFunction();
          }
        } else if (ProductTempData === "no item") {
          console.log("No Item found in the response.");
        } else {
          console.log("Error in API response: ", ProductTempData);
        }
      } catch (error) {
        console.error("Error while calling the API:", error);
      } finally {
        // Re-enable API call once the current call is completed
        setApiCallProductAllowed(true);
      }
    } else {
      console.log("No item selected");
      setApiCallProductAllowed(true); // Re-enable API calls even when no product is selected
    }
  };

  // useEffect(() => {
  //   onChangeSundrySaveMethod();
  // }, [sundryRows]);
  const [ApiCallSundryAllowed, setApiCallSundryAllowed] = useState(true);

  const onChangeSundrySaveMethod = async (e, Rowidx, Index) => {
    debugger;

    // Check if API call is allowed before proceeding
    if (!ApiCallSundryAllowed) {
      console.log("API Call is in progress, please wait.");
      return; // Exit the function if API call is still in progress
    }

    setApiCallSundryAllowed(false); // Disable further API calls

    // Filter the row to update
    var RowToUpdate = sundryRows.filter((item) => item.rowIndex === Rowidx);

    // Ensure there's a valid sundry data
    if (RowToUpdate[0]?.sundryName || RowToUpdate[0]?.sundryName !== "") {
      try {
        // Call the API to save sundry temp data
        var SundryTempData = await Transaction.SetSundryTempData(
          RowToUpdate,
          ReferenceNumber
        );

        // Handle API response
        if (SundryTempData.isSuccess === false) {
          console.log("API call failed. Response:", SundryTempData);
        } else if (SundryTempData.isSuccess === true) {
          console.log("Sundry Temp Data saved successfully.");

          // NewRowIndex should contain the new row index to be updated
          var NewRowIndex = SundryTempData.result;

          if (NewRowIndex.length === 1) {
            // Update sundryRows with the new row index
            setSundryRows((prevRows) => {
              return prevRows.map((row, idx) => {
                if (idx === Index) {
                  return { ...row, rowIndex: NewRowIndex[0] };
                }
                return row;
              });
            });
          }
          GetBillSundryeDataFunction();
        } else if (SundryTempData === "no item") {
          console.log("No Item found in the response.");
        } else {
          console.log("Error in API response: ", SundryTempData);
        }
      } catch (error) {
        console.error("Error while calling the API:", error);
      } finally {
        // Re-enable API call once the current call is completed
        setApiCallSundryAllowed(true);
      }
    } else {
      console.log("No item selected");
      setApiCallSundryAllowed(true); // Re-enable API calls even when no sundry is selected
    }
  };

  // const onChangeSundrySaveMethod = async (newRows) => {
  //   debugger;
  //   setApiCallSundryAllowed(false);
  //   if (ApiCallSundryAllowed) {
  //     if (newRows || newRows.length == 0 || newRows.length == undefined) {
  //       var ProductTempData = await Transaction.SetSundryTempData(
  //         sundryRows,
  //         ReferenceNumber
  //       );
  //     } else {
  //       var ProductTempData = await Transaction.SetSundryTempData(
  //         newRows,
  //         ReferenceNumber
  //       );
  //     }
  //     // debugger;
  //     setApiCallSundryAllowed(true);
  //     if (ProductTempData.isSuccess === true) {
  //       var NewRowIndex = ProductTempData.result; // [1, 2, 3]

  //       setSundryRows((prevRows) => {
  //         return prevRows.map((row, index) => {
  //           // Check if the index is within bounds of NewRowIndex array
  //           if (index < NewRowIndex.length) {
  //             return { ...row, rowIndex: NewRowIndex[index] }; // Update rowIndex with corresponding value
  //           }
  //           return row; // Keep the other rows unchanged if index exceeds NewRowIndex length
  //         });
  //       });

  //       setApiCallSundryAllowed(true);
  //     } else {
  //       console.log("Wait");
  //     }
  //   } else {
  //     console.log("Error in Product Temp Data");
  //   }
  // };

  return (
    <div>
      <div>
        <div>
          <div className="rightmaster-company">
            {/* <TopNamePage heading="ModifyProduct"/> */}
            {/* <div>
              <Joyride
                continuous
                callback={() => {}}
                run={run}
                steps={steps}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                showProgress
              />
              {[].map((item) => {
                return (
                  <div
                    key={item}
                    id={`step-${item}`}
                    style={{
                      border: "1px solid white",
                      width: "100px",
                      height: "100px",
                      background: "#0c1d2b",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div> */}

            <div
              className="panel-heading"
              style={{ backgroundColor: "#212e4f" }}
            >
              <div className="panel-head-title">
                <h2
                  className="panel-title"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  Sale Invoice
                </h2>
              </div>
              {/********************Top Buttons*******************/}

              {/********************Modal Box Open*******************/}

              {/********************{Top Buttons}*******************/}
              <MainButton
                isBtnSaleInvoiceSetting={true}
                OpenPartyModal={OpenPartyModal}
                isBtnOrder={true}
                isBtnChallan={true}
                showModal1={showModal1}
                showModal2={showModal2}
                SaveButton={SaveMethod}
              />
            </div>
            {/********************Modal Box End*******************/}
            {/* <div>
              <DateTime />
            </div> */}
            <div className="content_box">
              <div className="company-info-top-im">
                <div className="tab-head">
                  <div id="tabs">
                    <div className="tab-content-box">
                      <div className="tab-content-1">
                        {" "}
                        {/*height25vh*/}
                        <div
                          className="company-info-top-left table-left-padding mobileFlex-direction"
                          style={{ padding: "15px" }}
                        >
                          <div className="">
                            <div className="input-box-head">
                              {/*************************** Bill Data ***************/}
                              <div className="width100mobile">
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillSeries"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillSeries"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onBlur={onChangeSaveMethod}
                                      name="billSeriesId"
                                      value={inputValues.billSeriesId}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[1] = el)}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        -Select-
                                      </option>
                                      {BillSeriesSet && BillSeriesSet.length > 0
                                        ? BillSeriesSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billSeriesName}
                                            </option>
                                          ))
                                        : null}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Bill Series
                                    </label>
                                  </div>

                                  <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillNo"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="text"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      value={inputValues.billNumber}
                                      onBlur={onChangeSaveMethod}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[2] = el)}
                                      name="billNumber"
                                      required
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Bill No
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    {/* <div class="saleinvoicetop-input-container"> */}
                                    <select
                                      id="saleinvoicetopSelect"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onBlur={onChangeSaveMethod}
                                      name="billSeriesId"
                                      value={inputValues.billSeriesId}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[1] = el)}
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {BillSeriesSet && BillSeriesSet.length > 0
                                        ? BillSeriesSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billSeriesName}
                                            </option>
                                          ))
                                        : null}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Series
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      value={inputValues.billNumber}
                                      onBlur={onChangeSaveMethod}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[2] = el)}
                                      name="billNumber"
                                      required
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill No
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillDate width50mobile"
                                    style={{ margin: "1%", width: "48%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="date"
                                      // style={{ fontSize: "12px" }}
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onKeyDown={handleKeyDownMainPage}
                                      name="billDate"
                                      value={inputValues.billDate}
                                      ref={(el) => (nextInput.current[3] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Date
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1"
                                    id="DivBillType"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="billTaxTypeId"
                                      value={inputValues.billTaxTypeId}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[4] = el)}
                                      onBlur={onChangeSaveMethod}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          TaxTypeSet !== null &&
                                          Array.isArray(TaxTypeSet)
                                        ) {
                                          return TaxTypeSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billTypeName}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Bill Types
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="date"
                                      // style={{ fontSize: "12px" }}
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onKeyDown={handleKeyDownMainPage}
                                      name="billDate"
                                      value={inputValues.billDate}
                                      ref={(el) => (nextInput.current[3] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Date
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                  <div class="saleinvoicetop-input-container">
                                    <select
                                      // id="saleinvoicetopSelect"
                                      id="BillType"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="billTaxTypeId"
                                      value={inputValues.billTaxTypeId}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[4] = el)}
                                      onBlur={onChangeSaveMethod}
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {(() => {
                                        if (
                                          TaxTypeSet !== null &&
                                          Array.isArray(TaxTypeSet)
                                        ) {
                                          return TaxTypeSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billTypeName}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Types
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>

                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 input-Width50"
                                    id="DivGodown"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.godown
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <select
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="godownId"
                                      value={inputValues.godownId}
                                      onBlur={onChangeSaveMethod}
                                      onKeyDown={handleKeyDownMainPage}
                                      required
                                      ref={(el) => (nextInput.current[5] = el)}
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          GodownSet !== null &&
                                          Array.isArray(GodownSet)
                                        ) {
                                          return GodownSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Godown
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 input-Width50"
                                    id="DivBookedBy"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.bookedBy
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <select
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="bookedById"
                                      onBlur={onChangeSaveMethod}
                                      value={inputValues.bookedById}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[6] = el)}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          AgentSet !== null &&
                                          Array.isArray(AgentSet)
                                        ) {
                                          return AgentSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Booked By
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <select
                                      // id="saleinvoicetopSelect"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="godownId"
                                      value={inputValues.godownId}
                                      onBlur={onChangeSaveMethod}
                                      onKeyDown={handleKeyDownMainPage}
                                      required
                                      ref={(el) => (nextInput.current[5] = el)}
                                    >
                                      <option value="">-Select-</option>
                                      {(() => {
                                        if (
                                          GodownSet !== null &&
                                          Array.isArray(GodownSet)
                                        ) {
                                          return GodownSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Godown
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <select
                                      // id="saleinvoicetopSelect"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="bookedById"
                                      onBlur={onChangeSaveMethod}
                                      value={inputValues.bookedById}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[6] = el)}
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {(() => {
                                        if (
                                          AgentSet !== null &&
                                          Array.isArray(AgentSet)
                                        ) {
                                          return AgentSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Booked By
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>

                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%", margin: "0" }}
                                >
                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      id="CreditLimit"
                                      type="number"
                                      name="AccCreditLimit"
                                      value={InputValueAccMaster.AccCreditLimit}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[15] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                      autoComplete="off"
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Credit Limit
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="text"
                                      value={InputValueAccMaster.AccRefferedBy}
                                      name="AccRefferedBy"
                                      onKeyDown={(e) => {
                                        handleKeyDown_AccountSuggestion(
                                          e,
                                          filteredRefferedBy
                                        );
                                        handleKeyDownMainPage(e);
                                      }}
                                      autoComplete="off"
                                      onChange={handleAccountMasterChange}
                                      onBlur={closeSuggestionBox}
                                      ref={(el) => (nextInput.current[16] = el)}
                                      required
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Referred By
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                    {isDropdownOpen2 && (
                                      <ul
                                        className="ItemmasterDuggestionsaleinvoice"
                                        ref={suggestionBoxRef}
                                      >
                                        {filteredRefferedBy.map(
                                          (ref, index) => (
                                            <li
                                              key={index}
                                              ref={(el) =>
                                                (itemRefs.current[index] = el)
                                              }
                                              onClick={() =>
                                                handleAccountSelection(
                                                  ref.d2Id,
                                                  ref,
                                                  "AccRefferedBy"
                                                )
                                              }
                                              style={{
                                                // paddingBottom: "2px",
                                                // cursor: "pointer",
                                                // borderBottom: "1px solid #ccc",
                                                backgroundColor:
                                                  highlightedIndex === index
                                                    ? "#e6f7ff"
                                                    : "transparent",
                                              }}
                                            >
                                              {ref.name}
                                            </li>
                                          )
                                        )}
                                        <li
                                          key={filteredAccount.length}
                                          ref={(el) =>
                                            (itemRefs.current[
                                              filteredAccount.length
                                            ] = el)
                                          }
                                          addNew_btn_saleInvoice
                                          onClick={() =>
                                            openAccountMasterModal("Add")
                                          }
                                          className="addNew_btn_saleInvoice"
                                          style={{
                                            cursor: "pointer",
                                            borderBottom: "1px solid #ccc",
                                            backgroundColor:
                                              highlightedIndex ===
                                              filteredAccount.length
                                                ? "#e6f7ff"
                                                : "#5e709e",
                                          }}
                                        >
                                          Add New{" "}
                                          <i class="fa-solid fa-plus"></i>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/*************************** Party Details ***************/}
                              <div className="width100mobile margin-25-mob">
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <select
                                    id="NameTitle"
                                    className="form-control nameTitle inputEffect"
                                    style={{
                                      width: "20%",
                                      display:
                                        TransInputSet && TransInputSet.title
                                          ? "block"
                                          : "none",
                                    }}
                                    onBlur={onChangeSaveMethod}
                                    onChange={handleAccountMasterChange}
                                    name="namePrefix"
                                    value={InputValueAccMaster.namePrefix}
                                    onKeyDown={handleKeyDownMainPage}
                                    ref={(el) => (nextInput.current[7] = el)}
                                  >
                                    <option>Mr.</option>
                                    <option>Mrs.</option>
                                    <option>Ms.</option>
                                  </select> */}

                                  <div
                                    class="saleinvoicetop-input-container"
                                    style={{ width: "15%" }}
                                  >
                                    <select
                                      id="NameTitle"
                                      onBlur={onChangeSaveMethod}
                                      onChange={handleAccountMasterChange}
                                      name="namePrefix"
                                      value={InputValueAccMaster.namePrefix}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[7] = el)}
                                      required
                                    >
                                      <option value="">--</option>
                                      <option>Mr.</option>
                                      <option>Mrs.</option>
                                      <option>Ms.</option>
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Title
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  {/* <div style={{ width: "100%" }}>
                                    <div
                                      className="input-containerEffect mt-1"
                                      id="PartyInput"
                                    >
                                      <input
                                        className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                        type="text"
                                        value={InputValueAccMaster.AccName}
                                        name="AccName"
                                        onKeyDown={(e) =>
                                          handleKeyDown_AccountSuggestion(
                                            e,
                                            filteredAccount
                                          )
                                        }
                                        autoComplete="off"
                                        required
                                        onBlur={closeSuggestionBox}
                                        onChange={handleAccountMasterChange}
                                        onDoubleClick={() =>
                                          openAccountMasterModal("Add")
                                        }
                                        style={{
                                          fontSize: "14px",
                                          width: "100%",
                                        }}
                                      />

                                      <label
                                        id="input1"
                                        className="labelEffect1"
                                      >
                                        Part
                                        <span style={{ color: "red" }}>
                                          y
                                        </span>{" "}
                                        A/c
                                      </label>

                                      {isDropdownOpen1 && (
                                        <ul
                                          className="ItemmasterDuggestionsaleinvoice"
                                          ref={suggestionBoxRef}
                                        >
                                          {filteredAccount.map((Acc, index) => (
                                            <li
                                              key={index}
                                              ref={(el) =>
                                                (itemRefs.current[index] = el)
                                              }
                                              onClick={() =>
                                                handleAccountSelection(
                                                  Acc.d2Id,
                                                  Acc,
                                                  "AccName"
                                                )
                                              }
                                              style={{
                                                // padding: "2px 5px",
                                                // cursor: "pointer",
                                                // borderBottom: "1px solid #ccc",
                                                backgroundColor:
                                                  highlightedIndex === index
                                                    ? "#e6f7ff"
                                                    : "transparent",
                                              }}
                                            >
                                              {Acc.name}
                                            </li>
                                          ))}
                                          <li
                                            key={filteredAccount.length}
                                            ref={(el) =>
                                              (itemRefs.current[
                                                filteredAccount.length
                                              ] = el)
                                            }
                                            onClick={() =>
                                              openAccountMasterModal("Add")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              borderBottom: "1px solid #ccc",
                                              padding: "2px 5px",
                                              backgroundColor:
                                                highlightedIndex ===
                                                filteredAccount.length
                                                  ? "#e6f7ff"
                                                  : "transparent",
                                            }}
                                          >
                                            Add New
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div> */}

                                  <div
                                    class="saleinvoicetop-input-container"
                                    style={{ width: "82%" }}
                                  >
                                    <input
                                      type="text"
                                      ref={(el) => (nextInput.current[8] = el)}
                                      value={InputValueAccMaster.AccName}
                                      name="AccName"
                                      onKeyDown={(e) => {
                                        handleKeyDown_AccountSuggestion(
                                          e,
                                          filteredRefferedBy
                                        );
                                        handleKeyDownMainPage(e);
                                      }}
                                      autoComplete="off"
                                      required
                                      onBlur={closeSuggestionBox}
                                      onChange={handleAccountMasterChange}
                                      onDoubleClick={() =>
                                        openAccountMasterModal("Add")
                                      }
                                      style={{
                                        fontSize: "14px",
                                        width: "100%",
                                      }}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Part
                                      <span style={{ color: "red" }}>
                                        y
                                      </span>{" "}
                                      A/c
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                    {isDropdownOpen1 && (
                                      <ul
                                        className="ItemmasterDuggestionsaleinvoice"
                                        ref={suggestionBoxRef}
                                      >
                                        {filteredAccount.map((Acc, index) => (
                                          <li
                                            key={index}
                                            ref={(el) =>
                                              (itemRefs.current[index] = el)
                                            }
                                            onClick={() =>
                                              handleAccountSelection(
                                                Acc.d2Id,
                                                Acc,
                                                "AccName"
                                              )
                                            }
                                            style={{
                                              // padding: "2px 5px",
                                              // cursor: "pointer",
                                              // borderBottom: "1px solid #ccc",
                                              backgroundColor:
                                                highlightedIndex === index
                                                  ? "#e6f7ff"
                                                  : "transparent",
                                            }}
                                          >
                                            {Acc.name}
                                          </li>
                                        ))}
                                        <li
                                          key={filteredAccount.length}
                                          ref={(el) =>
                                            (itemRefs.current[
                                              filteredAccount.length
                                            ] = el)
                                          }
                                          className="addNew_btn_saleInvoice"
                                          addNew_btn_saleInvoice
                                          onClick={() =>
                                            openAccountMasterModal("Add")
                                          }
                                          style={{
                                            cursor: "pointer",
                                            borderBottom: "1px solid #ccc",
                                            backgroundColor:
                                              highlightedIndex ===
                                              filteredAccount.length
                                                ? "#e6f7ff"
                                                : "#5e709e",
                                          }}
                                        >
                                          {" "}
                                          Add New
                                          <i class="fa-solid fa-plus"></i>
                                        </li>
                                      </ul>
                                    )}
                                  </div>

                                  {/* <div
                                    onClick={toggleSlider}
                                    className="btn-lavender mt-1"
                                  >
                                    <i
                                      className="fa-solid fa-right-from-bracket"
                                      onClick={toggleSlider}
                                    ></i>
                                  </div> */}
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="text"
                                      name="AccAddress"
                                      value={InputValueAccMaster.AccAddress}
                                      onBlur={onChangeSaveMethod}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[9] = el)}
                                      required
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Address
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="number"
                                      name="AccPhoneNo"
                                      value={InputValueAccMaster.AccPhoneNo}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[10] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Mobile
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="text"
                                      name="AccAddress"
                                      value={InputValueAccMaster.AccAddress}
                                      onBlur={onChangeSaveMethod}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[9] = el)}
                                      required
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Address
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="number"
                                      name="AccPhoneNo"
                                      value={InputValueAccMaster.AccPhoneNo}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[10] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Mobile
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                                {/***********/}
                                {/*<div className="input-box-SI input-height2 paddingzero" style={{ width: "100%" }}>
                                                                    <div className="addedGridView" style={{ marginTop: "-5px" }}>
                                                                        {(() => {
                                                                            if (selectedData.length != null) {
                                                                                
                                                                                return (
                                                                                    selectedData.map((data) => (
                                                                                        <div className="input-containerEffect mt-2" style={{ margin: "1%" }} key={data.id}>
                                                                                            <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale" type="text" style={{ width: "98%" }} required />
                                                                                            <label id="input1" className="labelEffect1">{data.name}</label>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            return console.log("not found");;
                                                                        })()}
                                                                    </div>
                                                                </div>*/}
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect width50mobile"
                                    id="DivDateofBirth"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet &&
                                        TransInputSet.dateOfBirth
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      id="DateofBirth"
                                      type="date"
                                      name="AccDob"
                                      value={InputValueAccMaster.AccDob}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[11] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Date of birth
                                    </label>
                                  </div>

                                  <div
                                    className="input-containerEffect width50mobile"
                                    id="DivAnniversaryDate "
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet &&
                                        TransInputSet.anniversaryDate
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                      id="AnniversaryDate"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="date"
                                      name="AccAnn"
                                      value={InputValueAccMaster.AccAnn}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[12] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Anniversary Date
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      id="DateofBirth"
                                      type="date"
                                      name="AccDob"
                                      value={InputValueAccMaster.AccDob}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[11] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Date of birth
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      id="AnniversaryDate"
                                      type="date"
                                      name="AccAnn"
                                      value={InputValueAccMaster.AccAnn}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[12] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Anniversary Date
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{
                                    width: "100%",
                                    margin: "0px",
                                  }}
                                >
                                  {/* <div
                                    className="input-containerEffect width50mobile"
                                    id="DivPOS"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.pos
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                    id="POS"
                                      type="text"
                                      name="AccPOS"
                                      value={InputValueAccMaster.AccPOS}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[13] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      POS
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect width50mobile"
                                    id="DivAccountBalance"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.accBal
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                    id="AccountBalance"
                                      type="number"
                                      name="AccBalance"
                                      value={InputValueAccMaster.AccBalance}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[14] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Acc Balance
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      id="POS"
                                      type="text"
                                      name="AccPOS"
                                      value={InputValueAccMaster.AccPOS}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[13] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      POS
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      id="AccountBalance"
                                      type="number"
                                      name="AccBalance"
                                      value={InputValueAccMaster.AccBalance}
                                      onChange={handleAccountMasterChange}
                                      onKeyDown={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[14] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Acc Balance
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*********************** Order List ********************/}
                            {isModalOpen1 && (
                              <div className="modal">
                                <div className="modal-content1">
                                  <div className="modal-title">
                                    <h3 className="modal-h">Order List</h3>
                                    <span
                                      className="close"
                                      onClick={hideModal1}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div className="modal-content-im">
                                    <div className="table-container">
                                      <table className="table-t">
                                        <thead className="thead-groupmaster">
                                          <tr>
                                            <th className="table-th table-sn">
                                              SN
                                            </th>
                                            <th className="table-th">
                                              Bill No
                                            </th>
                                            <th className="table-th">Date</th>
                                            <th className="table-th ">
                                              Bill Type
                                            </th>
                                            <th className="table-th">
                                              Blnc Qty
                                            </th>
                                            <th className="table-th">
                                              Total Amt
                                            </th>
                                            <th className="table-th">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          className="table-overflow"
                                          style={{
                                            overflowY: "scroll",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <tr>
                                            <td>1</td>
                                            <td>1343534</td>
                                            <td>10/01/2023</td>
                                            <td>GST</td>
                                            <td>Amount</td>
                                            <td>1000</td>
                                            <td>Pending</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <PageTabs />

                            {/*********************** Challan List ********************/}
                            {isModalOpen2 && (
                              <div className="modal">
                                <div className="modal-content1">
                                  <div className="modal-title">
                                    <h3 className="modal-h">Challan List</h3>
                                    <span
                                      className="close"
                                      onClick={hideModal2}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div className="modal-content-im">
                                    <div className="table-container">
                                      <table className="table-t">
                                        <thead className="thead-groupmaster">
                                          <tr>
                                            <th className="table-th table-sn">
                                              SN
                                            </th>
                                            <th className="table-th">
                                              Bill No
                                            </th>
                                            <th className="table-th">Date</th>
                                            <th className="table-th ">
                                              Bill Type
                                            </th>
                                            <th className="table-th">
                                              Blnc Qty
                                            </th>
                                            <th className="table-th">
                                              Total Amt
                                            </th>
                                            <th className="table-th">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          className="table-overflow"
                                          style={{
                                            overflowY: "scroll",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <tr>
                                            <td>1</td>
                                            <td>1343534</td>
                                            <td>10/01/2023</td>
                                            <td>GST</td>
                                            <td>Amount</td>
                                            <td>1000</td>
                                            <td>Pending</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/**************************Lens Part ***************************/}
                        <LensInfoForInvoice
                          inputValues={inputValues}
                          activeTab1={activeTab1}
                          setActiveTab1={setActiveTab1}
                          handleTabClick1={handleTabClick1}
                          mainPageInputRef={mainPageInputRef}
                          lensPageInputRef={lensPageInputRef}
                          ReferenceNumber={ReferenceNumber}
                          suggestionBoxRef={suggestionBoxRef}
                          openAccountMasterModal={openAccountMasterModal}
                          highlightedIndex={highlightedIndex}
                          itemRefs={itemRefs}
                          itemMasterRows={itemMasterRows}
                        />
                      </div>
                      {/****************************************************** Product Table **************************************************************************/}
                      <p style={{ paddingLeft: "10px", position: "absolute" }}>
                        Ref No :-{ReferenceNumber}
                      </p>
                      <div>
                        <ProductList
                          setInputValues={setInputValues}
                          inputValues={inputValues}
                          mainPageInputRef={mainPageInputRef}
                          itemMasterRows={itemMasterRows}
                          setItemMasterRows={setItemMasterRows}
                          //handleGSTCalculation = {handleGSTCalculation}
                          BillTypeSetting={BillTypeSetting}
                          sundryRows={sundryRows}
                          suggestionBoxRef={suggestionBoxRef}
                          itemRefs={itemRefs}
                          currencyDecimalPlaces={currencyDecimalPlaces}
                          onChangeProductSaveMethod={onChangeProductSaveMethod}
                        />
                      </div>
                      {/****************************************************** Total Details *************************************************************************/}
                      <div className="tabs-main1 height30vh">
                        <div id="tabs" className="tab_a1">
                          <div
                            className="tab-btn-box1 tab-b3 bg-color"
                            style={{ backgroundColor: "#5e709e" }}
                          >
                            <div
                              className={`tab-b1 tab-2 active11 ${
                                activeTab === 11 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(11);
                                showtabs11();
                              }}
                            >
                              <label className="c-w col-black">Sundry</label>
                            </div>
                            <div
                              className={`tab-b1 tab-2 ${
                                activeTab === 22 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(22);
                                showtabs22();
                              }}
                            >
                              <label className="c-w col-black">GST</label>
                            </div>
                            {/*<div*/}
                            {/*    className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}*/}
                            {/*    onClick={() => { handleTabClick(33); showtabs33() }} >*/}
                            {/*    <label className="c-white col-black">Coupon</label>*/}
                            {/*</div>*/}
                            <div
                              className={`tab-b1 tab-2 ${
                                activeTab === 44 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(44);
                                showtabs44();
                              }}
                            >
                              <label className="c-w col-black">Loyality</label>
                            </div>
                          </div>

                          <div className="tab-content-box1 ">
                            {/*************** Sundry *****************************/}
                            {tabcontent11 && (
                              <div
                                className="tab-content-1"
                                // style={{ padding: "5px" }}
                              >
                                <div className="input-box-top-head">
                                  <div className="">
                                    <div>
                                      <table
                                        className="table-scrol1"
                                        style={{ border: "0" }}
                                      >
                                        <thead
                                          className="table-tbody"
                                          style={{ backgroundColor: "#e9e5e5" }}
                                        >
                                          <tr className="table-tr1">
                                            <th
                                              style={{
                                                width: "30px",
                                                textAlign: "center",
                                              }}
                                              className="table-th-1"
                                            >
                                              SN
                                            </th>
                                            <th className=" td-col2">
                                              Taxes & Othr Charge
                                            </th>
                                            <th className="table-th-6">@%</th>
                                            <th className="table-th-5">
                                              Amount
                                            </th>
                                            <th className="table-th-4">
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          className=" table-container-tbody"
                                          style={{
                                            height: "130px",
                                            margin: "5px 0px 3px 0px ",
                                            overflowY: "scroll",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          {sundryRows.map((row, rowIndex) => (
                                            <tr
                                              className="table-tr1"
                                              key={rowIndex}
                                            >
                                              <td
                                                className="table-td-2"
                                                style={{
                                                  textAlign: "center",
                                                  width: "30px",
                                                }}
                                              >
                                                {rowIndex + 1}
                                              </td>
                                              <td
                                                className="td-col2"
                                                style={{ position: "relative" }}
                                              >
                                                <input
                                                  id={`cell-${rowIndex}-0`}
                                                  className="table-input-13 input-box-td1"
                                                  type="text"
                                                  value={row.name}
                                                  onChange={(e) =>
                                                    handleSundryNameChange(
                                                      e,
                                                      rowIndex
                                                    )
                                                  }
                                                  onKeyDown={(e) =>
                                                    handleKeyDown_SundrySuggestion(
                                                      e,
                                                      rowIndex
                                                    )
                                                  }
                                                  ref={(input) => {
                                                    if (
                                                      !nextCellRefs.current[
                                                        rowIndex
                                                      ]
                                                    )
                                                      nextCellRefs.current[
                                                        rowIndex
                                                      ] = [];
                                                    nextCellRefs.current[
                                                      rowIndex
                                                    ][1] = input;
                                                  }}
                                                  onBlur={() => {
                                                    row.isDropdownOpen = false;
                                                  }}
                                                  onClick={() =>
                                                    getPosition(rowIndex, 1)
                                                  } // Get position on click
                                                />
                                                {row.isDropdownOpen && (
                                                  <ul
                                                    className="ItemmasterDuggestionsaleinvoice"
                                                    ref={suggestionBoxRef}
                                                    style={{
                                                      top: `${inputPosition.top}px`,
                                                      left: `${inputPosition.left}px`,
                                                      width: `${inputPosition.width}px`,
                                                    }}
                                                  >
                                                    {row.filteredSundry.map(
                                                      (item, index) => (
                                                        <li
                                                          key={item.id}
                                                          onClick={() =>
                                                            handleSundrySelection(
                                                              rowIndex,
                                                              item
                                                            )
                                                          }
                                                          ref={(el) =>
                                                            (itemRefs.current[
                                                              index
                                                            ] = el)
                                                          }
                                                          style={{
                                                            // paddingBottom:
                                                            //   "2px",
                                                            // cursor: "pointer",
                                                            // borderBottom:
                                                            //   "1px solid #ccc",
                                                            backgroundColor:
                                                              row.highlightedIndex ===
                                                              index
                                                                ? "#e6f7ff"
                                                                : "transparent",
                                                          }}
                                                        >
                                                          {item.name}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                )}

                                                <input
                                                  type="hidden"
                                                  id="hddn_sunId"
                                                  value={row.id}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_sundryType"
                                                  value={row.sundryType}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_defaultValue"
                                                  value={row.defaultValue}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_methodApplied"
                                                  value={row.methodApplied}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_absolutePercentAmount"
                                                  value={
                                                    row.absolutePercentAmount
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_percentOfAmount"
                                                  value={row.percentOfAmount}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_applyOnBill"
                                                  value={row.applyOnBill}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_roundOffSundryAmount"
                                                  value={
                                                    row.roundOffSundryAmount
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_amtRoundOffConfig"
                                                  value={row.amtRoundOffConfig}
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_amtAdjustSaleAccount"
                                                  value={
                                                    row.amtAdjustSaleAccount
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_selectedAccountSale"
                                                  value={
                                                    row.selectedAccountSale
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_amtAdjustPurchaseAccount"
                                                  value={
                                                    row.amtAdjustPurchaseAccount
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_selectedAccountPurchase"
                                                  value={
                                                    row.selectedAccountPurchase
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  id="hnnd_roundOffLimit"
                                                  value={row.roundOffLimit}
                                                />
                                              </td>
                                              <td className="table-td-2 table-th-6">
                                                <input
                                                  className="table-input-13 input-box-td2"
                                                  type="text"
                                                  name="Percent"
                                                  // value={row.Percent}
                                                  value={parseFloat(
                                                    row.Percent
                                                  ).toFixed(
                                                    currencyDecimalPlaces
                                                  )}
                                                  onChange={(e) =>
                                                    handleSundryCellChange(
                                                      e,
                                                      rowIndex
                                                    )
                                                  }
                                                  onBlur={(e) =>
                                                    onChangeSundrySaveMethod(
                                                      e,
                                                      row.rowIndex,
                                                      rowIndex
                                                    )
                                                  }
                                                  onKeyDown={(e) =>
                                                    handleSundryRowKeyDown(
                                                      e,
                                                      rowIndex,
                                                      2
                                                    )
                                                  }
                                                  ref={(input) => {
                                                    if (
                                                      !nextCellRefs.current[
                                                        rowIndex
                                                      ]
                                                    )
                                                      nextCellRefs.current[
                                                        rowIndex
                                                      ] = [];
                                                    nextCellRefs.current[
                                                      rowIndex
                                                    ][2] = input;
                                                  }}
                                                />
                                              </td>
                                              <td className="table-td-2 table-th-5">
                                                <input
                                                  className="table-input-13 input-box-td2"
                                                  type="text"
                                                  name="Amount"
                                                  // value={}
                                                  value={parseFloat(
                                                    row.Amount
                                                  ).toFixed(
                                                    currencyDecimalPlaces
                                                  )}
                                                  onChange={(e) =>
                                                    handleSundryCellChange(
                                                      e,
                                                      rowIndex
                                                    )
                                                  }
                                                  onBlur={(e) =>
                                                    onChangeSundrySaveMethod(
                                                      e,
                                                      row.rowIndex,
                                                      rowIndex
                                                    )
                                                  }
                                                  onKeyDown={(e) =>
                                                    handleSundryRowKeyDown(
                                                      e,
                                                      rowIndex,
                                                      3
                                                    )
                                                  }
                                                  ref={(input) => {
                                                    if (
                                                      !nextCellRefs.current[
                                                        rowIndex
                                                      ]
                                                    )
                                                      nextCellRefs.current[
                                                        rowIndex
                                                      ] = [];
                                                    nextCellRefs.current[
                                                      rowIndex
                                                    ][3] = input;
                                                  }}
                                                />
                                              </td>
                                              <td
                                                className="table-td-2 table-th-4"
                                                style={{ textAlign: "center" }}
                                              >
                                                <button
                                                  className="btn-table1"
                                                  style={{ margin: "0" }}
                                                  onClick={() =>
                                                    deleteSundryRow(rowIndex)
                                                  }
                                                >
                                                  <i className="fa-solid fa-trash delete-btn"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/*************** GST *****************************/}
                            {tabcontent22 && (
                              <div className="tab-content-1 height-115">
                                <div className="input-box-top-head">
                                  <div className="input-box-head-left1">
                                    <div className="input-box-head-1">
                                      <div className="input-box-SI-2 ">
                                        <div className="input-box-m7">
                                          <div className="space-between">
                                            <div className="button-col-s1">
                                              <button
                                                type="button"
                                                className="button-col-s2"
                                              >
                                                Bill Wise
                                              </button>
                                            </div>
                                            <div className="button-col-s1">
                                              <button
                                                type="button"
                                                className="button-col-s2"
                                              >
                                                Item Wise
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s1 SaleTaxBtn"></span>
                                            <label>Tax not calculated</label>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s4 SaleTaxBtn"></span>
                                            <label>Tax not apply</label>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s3 SaleTaxBtn"></span>
                                            <label>Tax value not apply</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/*************** Coupon *****************************/}

                            {/************************* Loyality ***********************/}
                            {tabcontent44 && (
                              <div className="tab-content-3">
                                <div className="input-box-top-head">
                                  <div className="input-box-head-left2">
                                    <div className="input-box-head-1">
                                      <table style={{ width: "100%" }}>
                                        <thead>
                                          <tr>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              S.No.
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Item Details
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Loyalty Point
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Loyalty Amount
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="td">1</td>
                                            <td className="td">Lens</td>
                                            <td className="td">50</td>
                                            <td className="td">50</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              className="space-between"
                              style={{ padding: "7px" }}
                            >
                              <button onClick={ApplyTaxClick}>Apply Tax</button>
                              <span className="span1">
                                Total Sundry Amt: &nbsp;&nbsp;
                                <span className="pay_amt1">
                                  {parseFloat(inputValues.TotalSundry).toFixed(
                                    currencyDecimalPlaces
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/***************************** Bill Payment Invoice **************************/}
                        <div className="table-row1">
                          {/*<div className="col_58" style={{ backgroundColor:"#98a1b7", color:"black", borderLeft:"1px solid black"} }>Bill Payment Details</div>*/}
                          <div>
                            <BillPaymentInvoice
                              setInputValues={setInputValues}
                              inputValues={inputValues}
                              mainPageInputFocus2={mainPageInputFocus2}
                              ReferenceNumber={ReferenceNumber}
                              currencyDecimalPlaces={currencyDecimalPlaces}
                            />
                          </div>
                        </div>
                        {/***************************** Total **************************/}
                        <div className="table-row2">
                          <span className="table-ax-1"></span>
                          <div
                            className="padding-20 totalBg-color display-center"
                            style={{
                              backgroundColor: "rgba(94, 112, 158, 0.12)",
                              height: "100%",
                            }}
                          >
                            <div className="space-between mt-1">
                              <label className="c-b">Loyality</label>
                              <div
                                className="flex-end"
                                style={{ width: "50%" }}
                              >
                                <div
                                  className="image-input-container"
                                  style={{ width: "100%" }}
                                >
                                  <input
                                    type="text"
                                    placeholder=""
                                    className=" modify_im_input12 mode_limit mode_category"
                                    onChange={(e) =>
                                      handleInputChange_Series(e)
                                    }
                                    name="LoyalityAmt"
                                    value={inputValues.LoyalityAmt}
                                    onKeyDown={handleKeyDownMainPage}
                                    ref={
                                      mainPageInputFocus2 /*el => nextInput.current[101] = el*/
                                    }
                                    required
                                  />
                                  <button className=" apply-button input-image">
                                    Use
                                  </button>{" "}
                                  {/*onClick={handleApplyCoupon}*/}
                                </div>
                              </div>
                            </div>
                            <div className="space-between mt-1">
                              <label className="c-b">Coupon</label>
                              <div
                                className="flex-end"
                                style={{ width: "50%" }}
                              >
                                <div
                                  className="image-input-container"
                                  style={{ width: "100%" }}
                                >
                                  <input
                                    className=" modify_im_input12 mode_limit mode_category"
                                    type="text"
                                    placeholder="Enter Coupon"
                                    onChange={(e) =>
                                      handleInputChange_Series(e)
                                    }
                                    name="CouponAmt"
                                    value={inputValues.CouponAmt}
                                    onKeyDown={handleKeyDownMainPage}
                                    ref={(el) => (nextInput.current[102] = el)}
                                    required
                                  />
                                  <button
                                    className=" apply-button input-image"
                                    onClick={openCouponModal}
                                  >
                                    View
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="space-between mt-1">
                              <label className="c-b">Taxable Value</label>
                              <input
                                className="modify_im_input12 mode_limit mode_category"
                                type="text"
                                style={{ width: "50%", color: "red" }}
                                onChange={(e) => handleInputChange_Series(e)}
                                name="TaxableAmt"
                                // value={inputValues.TaxableAmt}
                                value={parseFloat(
                                  inputValues.TaxableAmt
                                ).toFixed(currencyDecimalPlaces)}
                                disabled
                              />
                            </div>
                            <div className="space-between mt-1">
                              <label className="c-b">Gross Value</label>
                              <input
                                className="modify_im_input12 mode_limit mode_category"
                                type="text"
                                style={{ width: "50%", color: "red" }}
                                onChange={(e) => handleInputChange_Series(e)}
                                name="GrossAmt"
                                // value={inputValues.GrossAmt}
                                value={parseFloat(inputValues.GrossAmt).toFixed(
                                  currencyDecimalPlaces
                                )}
                                disabled
                              />
                            </div>
                            {/*<div className="space-between mt-1">*/}
                            {/*    <label className="c-b">Allow Loyality</label>*/}
                            {/*    <div className="col-d-1">*/}
                            {/*        <input onChange={handleToggleChangsi3} disabled={isCheckboxDisabledsi3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                            {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> Loyalty Amount</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="space-between mt-1">*/}
                            {/*    <label className="c-b">Add Discount</label>*/}
                            {/*    <div className="col-d-1">*/}
                            {/*        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                            {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> (Party Wise Catgry Disc)</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </div>
                      <div id="saleRemarkPart">
                        <div className="space-evenly saleRemarkPart">
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivDeliveryDate"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.deliveryDate
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                              type="date"
                              style={{ padding: "0px 7px" }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="DeliveryDate"
                              value={inputValues.DeliveryDate}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[103] = el)}
                              required
                              // onBlur={onChangeSaveMethod}
                            />
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Delivery Date &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivStatus"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.deliveryStatus
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 width-100 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onBlur={onChangeSaveMethod}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="vochStatusId"
                              value={inputValues.vochStatusId}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[104] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                -Select-
                              </option>
                              {(() => {
                                if (
                                  StatusMasterList !== null &&
                                  Array.isArray(StatusMasterList)
                                ) {
                                  return StatusMasterList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ));
                                }
                              })()}
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Status &nbsp; &nbsp; &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark"
                            style={{
                              margin: "1%",
                              // width: "20%",/
                              display:
                                TransInputSet && TransInputSet.remark
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                              type="text"
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark"
                              value={inputValues.Remark}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[105] = el)}
                              required
                              onBlur={onChangeSaveMethod}
                            />
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark1"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark1
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark1"
                              value={inputValues.Remark1}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[106] = el)}
                              required
                              onBlur={onChangeSaveMethod}
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="1">
                                Special Customer
                              </option>
                              <option Classname="form-control s-1" value="2">
                                Test1
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark1 &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark2"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark2
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark2"
                              value={inputValues.Remark2}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[107] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="0">
                                TEST2
                              </option>
                              <option Classname="form-control s-1" value="1">
                                CAMP CUSTOMER
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark2 &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark3"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark3
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark3"
                              value={inputValues.Remark3}
                              onKeyDown={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[108] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="1">
                                CHARITY
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark3 &nbsp; &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <MobileBottomButton bottomSliderOpen={bottomSliderOpen} />
            </div>
            {loading && <Loader />}
          </div>
        </div>
      </div>
      {/************************* Party Detail Modal *****************************/}
      {PartyDetailModal && (
        <div className="modal">
          <div className="modal-content2">
            <div className="modal-title">
              <h3 className="modal-h">Invoice Setting</h3>
              <span className="close" onClick={ClosePartyModal}>
                &times;
              </span>
            </div>
            <div className="ModalBoxItem_FullHeight">
              <div className="ModalBoxItem_Left">
                <div className="modal-content-im ">
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.title}
                            name="title"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Title<i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/*{CheckBoxData.map((data, index) => (
                                        <div className="input-box-m1 modal-2" >
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch" key={data.id}>
                                                    <input type="checkbox" onChange={() => handleSliderButton(index)} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">{data.name}</label>
                                            </div>
                                        </div>
                                    ))}*/}
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.dateOfBirth}
                            name="dateOfBirth"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Date Of Birth</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.anniversaryDate}
                            name="anniversaryDate"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Anniversary Date
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.pos}
                            name="pos"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">POS</label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.accBal}
                            name="accBal"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Acc Bal.</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.creditLimit}
                            name="creditLimit"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Credit Limit</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.referredBy}
                            name="referredBy"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Referred By</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBoxItem_Right">
                <div className="modal-content-im ">
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.bookedBy}
                            name="bookedBy"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Booked By
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.godown}
                            name="godown"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Godown
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.deliveryStatus}
                            name="deliveryStatus"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Delivery Status
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.deliveryDate}
                            name="deliveryDate"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Delivery Date
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark}
                            name="remark"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark1}
                            name="remark1"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark1
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark2}
                            name="remark2"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark2
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark3}
                            name="remark3"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark3
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/******************************* Bottom Slider*************************/}
      {MobileSettingSlider && (
        <div className="modal-back">
          <div className="bottomSlider">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Setting</label>
              <label
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={closeBottomSlider}
              >
                x
              </label>
            </div>
            <div className="space-between">
              <label></label>
            </div>
          </div>
        </div>
      )}
      {/******************************* Coupon Modal*************************/}
      {CouponModal && (
        <div className="modal-back">
          <div className="partyDetail-modal">
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>View Coupon</label>
                <label
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={closeCouponModal}
                >
                  x
                </label>
              </div>
              <hr />
              <div style={{ margin: "10px", padding: "10px" }}>
                <div className="couponBox">
                  <div className="couponLeftSide">
                    <label
                      className="rotated-text"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      20% OFF
                    </label>
                  </div>
                  <div className="" style={{ padding: "10px", width: "100%" }}>
                    <div className="space-between">
                      <label className="couponName">FRAMEFREE</label>
                      <button
                        className="apply-button"
                        style={{ padding: "5px" }}
                      >
                        Apply
                      </button>
                    </div>
                    <div>
                      <label style={{ color: "grey" }}>
                        Save &#x20B9;50 on this order.
                      </label>
                      <br />
                      <label style={{ color: "grey" }}>
                        Use code FRAMEFREE & get flat 20% off on this order.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="couponBox mt-3">
                  <div className="couponLeftSide">
                    <label
                      className="rotated-text"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      20% OFF
                    </label>
                  </div>
                  <div className="" style={{ padding: "10px", width: "100%" }}>
                    <div className="space-between">
                      <label className="couponName">FRAMEFREE</label>
                      <button
                        className="apply-button"
                        style={{ padding: "5px" }}
                      >
                        Apply
                      </button>
                    </div>
                    <div>
                      <label style={{ color: "grey" }}>
                        Save &#x20B9;50 on this order.
                      </label>
                      <br />
                      <label style={{ color: "grey" }}>
                        Use code FRAMEFREE & get flat 20% off on this order.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2" />
              <div className="flex-end">
                <button
                  className="greenBtn"
                  onClick={closeCouponModal}
                  style={{ width: "20%" }}
                >
                  Apply
                </button>
                <button
                  className="redBtn"
                  onClick={closeCouponModal}
                  style={{ width: "20%" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/******************************* Account Master Modal*************************/}
      {AccountMasterModal && (
        <div className="modal-back">
          <div
            className="partyDetail-modal"
            style={{ height: "90%", width: "90%", backgroundColor: "white" }}
          >
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label></label>
                <label
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={closeAccountMasterModal}
                >
                  x
                </label>
              </div>
              <AccountMaster />
            </div>
          </div>
        </div>
      )}
      {/******************************* Previous order Modal*************************/}
      <div
        onClick={toggleSlider}
        className={`slider-container ${isSliderOpen ? "open" : ""}`}
      >
        <div onClick={handleModalClick} style={{ padding: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label></label>
            <label
              style={{ cursor: "pointer", fontSize: "24px" }}
              onClick={toggleSlider}
            >
              x
            </label>
          </div>
          <PreviousOrder />
        </div>
      </div>
    </div>
  );
}

export default SaleInvoice;
