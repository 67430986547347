// import { useState } from "react";
import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import TableButton from "./../../Props/TableButton";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
import { useNavigate } from "react-router-dom";
import DataTable from "../../Master/TableMaster/DataTable";
import MiscellaneousMaster from "../../Master/AccountMaster/MiscellaneousMaster";
import Transaction from "../../../API/APIServices/Transaction";

function Salelist() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    // input5,
  } = useContext(MyContext);

  const navigate = useNavigate(); // Hook to navigate programmatically
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TransType, setTransType] = useState(12);

  const changetablevalue = (e) => {
    const value = e.target.value;
    console.log(value);
    setTransType(value);
    fetchData(value);
  };
  // console.log(TransType);

  useEffect(() => {
    setTransType(TransType);
  }, [TransType]);

  const fetchData = async (TransType) => {
    // setLoading(true);
    try {
      const val = await Transaction.GetTransactionList(TransType);
      // debugger;
      // console.log(val); 
      const newData = val.item1.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          PartyName: item.PartyName,
          Mobile: item.Mobile,
          VouchSeries: item.VouchSeries,
          billStatus: item.billStatus,
          //   VouchNo: item.VouchNo === null ? "-----" : item.VouchNo,
          BillAmt: item.BillAmt === null ? "-----" : item.BillAmt,
          //   BillAmtPaid: item.BillAmtPaid,
          billStatus: item.billStatus,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.TransId, TransType)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.TransId)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(TransType);
    fetchData(TransType);
  }, []);

  const Edit = async (TransId, TransTypevalue) => {
    const data = await Transaction.GetTransactionById(TransId, TransTypevalue);
    // debugger;
    console.log(data);
    setEditData(data);
    setRefID(data);
    // Navigate to the new page with state (passing refID)
    navigate(`/saleinvoice/${TransTypevalue}`, {
      state: { refID: data, isEdit: true },
    });
  };

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await MiscellaneousMasterApi.deleteMiscellaneousMaster(
        getdeleteId,
        TransType
      );
      if (Boolean(val) === true) {
        fetchData();
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const [data, setData] = useState({});
  const [editData, setEditData] = useState("");
  const [RefID, setRefID] = useState("");

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNo", accessor: "sno" },
    { Header: "Party Name", accessor: "PartyName" },
    { Header: "Mobile", accessor: "Mobile" },
    { Header: "Vouch Series", accessor: "VouchSeries" },
    // { Header: "VouchNo", accessor: "VouchNo" },
    { Header: "Bill Amount", accessor: "BillAmt" },
    // { Header: "BillAmtPaid", accessor: "BillAmtPaid" },
    { Header: "Bill Status", accessor: "billStatus" },
    // { Header: "Phone", accessor: "phone" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    // { Header: "", accessor: "" },
    {
      Header: (
        // <input type="text" placeholder="Name" ref={input1} />
        <select onChange={(e) => changetablevalue(e)} value={TransType}>
          <option value="12">Sale Invoice</option>
          <option value="13">Sale Return</option>
          <option value="14">Purchase Invoice</option>
          <option value="15">Purchase Return</option>
        </select>
      ),
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Party Name" ref={input1} />,
      accessor: "",
    },
    {
      Header: <input type="text" placeholder="Mobile" ref={input2} />,
      accessor: "printName",
    },
    {
      Header: <input type="text" placeholder="Vouch Series" ref={input3} />,
      accessor: "availableFrom",
    },
    {
      Header: <input type="text" placeholder="Bill Amount" ref={input4} />,
      accessor: "availableTo",
    },
    {
      Header: <input type="text" placeholder="Bill Status" />,
      accessor: "billStatus",
    },
  ];

  return (
    <div className="">
      <PageTabs locationTab={"/MiscellaneousMaster"} modelOpen={true} />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Sale List</h2>
          <TableButton
            PageCreate={`/saleinvoice/${TransType}`}
            filterData={filterData}
            columns={columns}
            data={mainData}
            PageName="Masters"
          />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />

        {alertBoxDelete && (
          <AlertBox
            HeadingTop="DELETE"
            Heading="Are You sure you want to delete this"
            OpenAlertBox={OpenAlertBoxDelete}
            onYesClick={DeleteAccountConfirm}
          />
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
}

export default Salelist;
