
import DateTime from "../Components/DateTime";
import React, { useEffect, useState } from "react";
import PageTabs from "../Components/PageTabs";
import Order from "../../API/APIServices/Order";
import Loader from "../Props/Loader";

function ModalFeedbackFollowUp(props) {
    // useState(() => {
    //     document.title = 'Feedback Follow Up';
    // }, []);

    const [loading, setLoading] = useState(false)

    const { ItemDetail, hideModalEdit } = props;

    const [followUpMsg, setFollowUpMsg] = useState("");

    useEffect(() => {
        if (ItemDetail.length > 0) {
            setFollowUpMsg(ItemDetail[0].feedbackFollowUp);
        }
    }, [])

    const handleFollowChange = (e) => {
        setFollowUpMsg(e.target.value);
    }

    const SubmitFollowUpMsg = async () => {
        if (followUpMsg === "") {
            alert("Update Follow Msg")
            return 0;
        }
        setLoading(true);
        const updatedValues = [
            {
                operationType: 0,
                path: "feedbackFollowUp",
                op: "Add",
                from: "string",
                value: followUpMsg
            },]
        await Order.updateOrderPartially(updatedValues, ItemDetail[0].ord1Id);
        alert("Updated")
        setLoading(false);
        hideModalEdit()
    }

    return (
        <div>
            <PageTabs />
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <h3 className="feedback-followup">Follow-Up Message</h3>
                <div className="company_control">
                    <div className="input-box-tab-head">
                        <div className="input-box-m2">
                            <textarea className="modify_im_textarea mode_category2 followup-height"
                                type="text" placeholder="" value={followUpMsg} onChange={handleFollowChange} required />
                        </div>
                    </div>
                </div>
                <div className="input-order-buttons submit-flex">
                    <div className="input-remark-left01 input_RMK">
                        <div className="button-m5 btn_ord_pad" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button class="greenBtn" onClick={SubmitFollowUpMsg}>Submit</button>
                            <button class="redBtn" onClick={hideModalEdit}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default ModalFeedbackFollowUp;
