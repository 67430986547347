import React, { useContext, useEffect, useState } from 'react'
import ModalQueryName from '../../../Inventory/ModalBox/ModalQueryName';
import MyContext from '../../../Inventory/MyContext';
import Loader from '../../../Inventory/Props/Loader';
import AlertBoxError from '../../../Inventory/Props/AlertBoxError';
import Query from '../../../API/APIServices/Query';
import AlertBox from '../../../Inventory/Props/AlertBox';
// import GlobalSelector from './GlobalSelector'

const ReportDesignSection = (props) => {

    const {
        OpenAlertBox,
        alertBox,
        OpenAlertBoxEdit,
        alertBoxEdit,
        alertBoxError,
        OpenAlertBoxError,
        TemplateData,
        setTemplateData,
    } = useContext(MyContext);

    const [loading, setLoading] = useState(false);

    const [inputValuesForQuery, setInputValuesForQuery] = useState({
        id: 0,
        title: "",
        query: "",
        query2: "",
        query3: "",
        isActive: true,
    });

    const [QueryData, setQueryData] = useState([]);

    const fetchQueryData = async () => {
        const data = await Query.getQuery1();
        if (data) {
            setQueryData(data);
        } else {
            console.log(data);
        }
    };

    useEffect(() => {
        fetchQueryData();
    }, [])

    const [queryModal, setQueryModal] = useState(false);

    const openQueryModal = () => {
        setQueryModal(true);
    }

    const closeQueryModal = () => {
        fetchQueryData();
        setQueryModal(false);
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const handleInputChangeForQuery = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValuesForQuery((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const SaveQuery = () => {
        setLoading(true);
        const isSuccess = Query.insertQuery(inputValuesForQuery);
        if (isSuccess) {
            OpenAlertBox();
            setLoading(false);
            setInputValuesForQuery({
                title: "",
                query: "",
                query2: "",
                query3: "",
                isActive: true,
            });
            alert("Your Query is saved");
        } else {
            alert("Error while Insert");
        }
    };

    const updateQuery = () => {
        setLoading(true);
        const isSuccess = Query.updateQuery(inputValuesForQuery);
        if (isSuccess) {
            OpenAlertBox();
            setLoading(false);
            setInputValuesForQuery({
                id: 0,
                title: "",
                query: "",
                query2: "",
                query3: "",
                isActive: true,
            });
            alert("Your Query is saved");
        } else {
            alert("Error while Updating");
        }
    };

    const deleteQuery = () => {
        setLoading(true);
        const isSuccess = Query.deleteQuery(inputValuesForQuery);
        if (isSuccess) {
            OpenAlertBox();
            setLoading(false);
            setInputValuesForQuery({
                id: 0,
                title: "",
                query: "",
                query2: "",
                query3: "",
                isActive: true,
            });
            alert("Your Query is Deleted");
        } else {
            alert("error while updating");
        }
    };

    const [fieldName, setFieldName] = useState([]);
    const [sqlQuery, setSqlQuery] = useState()

    const handleQueryDataChange = async (e) => {
        const id = e.target.value;
        var fieldNameData = await Query.getQueryColumnName(id);
        setFieldName(fieldNameData);

        // const queryData = QueryData.find(item => item.id == id);
        // setSqlQuery(queryData?.query)
        setSqlQuery(id);
    }

    const [fieldValue, setFieldValue] = useState();

    const handleFieldNameSelect = (e) => {
        setFieldValue(e.target.value);
    }

    const AddBtnClick = async () => {
        try {
            setTemplateData(prevState => {
                if (!prevState.insertQuery) {
                    return {
                        ...prevState,
                        insertQuery: sqlQuery ? sqlQuery : null,
                    };
                }
                const isQueryPresent = prevState.insertQuery.includes(sqlQuery);
                if (!isQueryPresent && sqlQuery) {
                    return {
                        ...prevState,
                        // insertQuery: `${prevState.insertQuery} ; ${sqlQuery}`,
                        insertQuery: `${sqlQuery}`,
                    };
                } else {
                    return prevState;
                }
            });
            handleButtonClick();
            localStorage.setItem("VariableName", `@${fieldValue}`)
            await navigator.clipboard.writeText(`@${fieldValue}`);
            console.log('Copied to clipboard: ' + fieldValue);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    // const [inputValue, setInputValue] = useState('')

    // const handlePaste = async (event) => {
    //     event.preventDefault();
    //     const pastedData = await navigator.clipboard.readText();
    //     if (pastedData.includes('@')) {
    //         setInputValue(pastedData);
    //     } else {
    //         console.log("Paste failed: Pasted content must contain '@'");
    //     }
    // };

    const [showToast, setShowToast] = useState(false);

    const handleButtonClick = () => {
        setShowToast(true);

        setTimeout(() => {
            setShowToast(false);
        }, 500);
    };

    return (
        <div className="flex-container">
            <div className="flex-contparent">
                <div className="typography-sectionoption">
                    <p className="general-paragraph">Query Name</p>
                    <div className='flex-start'>
                        <select name="display" className='selectorstyle height-33'
                            onChange={handleQueryDataChange}>
                            <option value="0">Select</option>
                            {QueryData && QueryData.map((data) => (
                                <option value={data.id} key={data.id}>{data.title}</option>
                            ))}
                        </select>
                        <button className="edit_component_btn" onClick={openQueryModal}>Query Build</button>
                    </div>
                </div>
            </div>
            <div className="flex-contparent">
                <div className="typography-sectionoption">
                    <p className="general-paragraph">Field Name</p>
                    <div className='flex-start'>
                        <select name="display" className='selectorstyle height-33' onChange={handleFieldNameSelect}>
                            <option value="0"></option>
                            {fieldName && fieldName.map((data, index) => (
                                <option value={data} key={index}>{data}</option>
                            ))}
                        </select>
                        <button className="edit_component_btn" onClick={AddBtnClick}>Add</button>
                    </div>
                    {showToast && (
                        <div className="toastDiv-webTemplate" >
                            Copied
                        </div>
                    )}
                </div>
            </div>

            {/* <div className="flex-flex-parent">
                <p className="general-paragraph">Test</p>
                <input type="text" className='flex-input' onClick={handlePaste} onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
            </div> */}

            {/************************* Query Builder Modal *****************************/}
            {queryModal && (
                <div className="modal-back" onClick={closeQueryModal}>
                    <div onClick={handleModalClick} className="partyDetailPosModal" style={{ height: "auto", width: "35%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label style={{ fontWeight: "600", color: "black", fontSize: "18px" }}>QUERY BUILDER</label>
                                <label style={{ cursor: "pointer", fontSize: "24px", alignItems: "baseline", fontWeight: "600" }} onClick={closeQueryModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ margin: "10px", padding: "0px 10px" }}>
                                <ModalQueryName
                                    SaveQuery={SaveQuery}
                                    handleInputChangeForQuery={handleInputChangeForQuery}
                                    inputValuesForQuery={inputValuesForQuery}
                                    setInputValuesForQuery={setInputValuesForQuery}
                                    updateQuery={updateQuery}
                                    deleteQuery={deleteQuery}
                                    closeModal={closeQueryModal} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* ************************************************************************ */}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Item Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}

            {alertBoxError && (
                <AlertBoxError
                    HeadingTop="ERROR"
                    Heading="Error While Updating Data"
                    onYesClick={OpenAlertBoxError}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxError}
                />
            )}
            {loading && <Loader />}
        </div>
    )
}

export default ReportDesignSection
