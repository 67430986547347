import { useContext, useEffect, useState } from "react";
import "./POSInvoice.css";
import OrderApi from "../../API/APIServices/Order";
import { useNavigate } from "react-router-dom";
import ItemDetailModalBox from "./ItemDetailModalBox";
import Order from "../../API/APIServices/Order";
import GstModalOrderView from "./GstModalOrderView";
import OrderDetailStatusModal from "./OrderDetailStatusModal";
import PaymantModeModel from "./PaymantModeModel";
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import moment from "moment";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import MyContext from "../../Inventory/MyContext";
import ReportDesign from "./ReportDesign";

const PickUp = (props) => {
  const [hoverStates, setHoverStates] = useState({});
  const [selectedDate, setSelectedDate] = useState('default');
  const [RemarkId, setRemarkId] = useState([]);
  const [loading, setLoading] = useState()

  const { formatDate, orderListPickUp, setOrderListPickUp, handlePaymentPay,
    fetchOrderData, OrderWiseButtonClick, fetchOrderDataPickup, handleRefreshBtn, startIndexPickup, summary } = props;
  const { UserDetails, maskMobileNo } = useContext(MyContext)


  //console.log(orderListPickUp);

  // useEffect(() => {
  //   if (orderListPickUp && orderListPickUp.length > 0) {
  //     fetchTotalAmt(orderListPickUp);
  //   }
  // }, [orderListPickUp])

  // const [orderTotalAmt, setOrderTotalAmt] = useState(0);
  // const [orderCancelAmt, setOrderCancelAmt] = useState(0);
  // const [orderBalAmt, setOrderBalAmt] = useState(0);
  // const [orderPaidAmt, setOrderPaidAmt] = useState(0);
  // const [totalReturn, setTotalReturn] = useState(0);
  // const [totalReturnRequest, setTotalReturnRequest] = useState(0);

  // const fetchTotalAmt = (orderReport) => {
  //   const total = orderReport.reduce((sum, data) => sum + (data.grandTotal), 0);
  //   setOrderTotalAmt(total.toFixed(2));

  //   const cancelTotal = orderReport.reduce((sum, data) => sum + (data.o1CancelledAmt), 0);
  //   setOrderCancelAmt(cancelTotal.toFixed(2));

  //   const balTotal = orderReport.reduce((sum, data) => sum + (data.grandTotal - (data.o1CancelledAmt + data.paymentAmount)), 0);
  //   setOrderBalAmt(balTotal.toFixed(2));

  //   const paidTotal = orderReport.reduce((sum, data) => sum + (data.paymentAmount), 0);
  //   setOrderPaidAmt(paidTotal.toFixed(2));

  //   const returnTotal = orderReport.filter(item => item.overallOrderStatus == "Returned");
  //   setTotalReturn(returnTotal.length)

  //   const returnRequestTotal = orderReport.filter(item => item.overallOrderStatus == "Return Request");
  //   setTotalReturnRequest(returnRequestTotal.length)
  // }

  const RemarkSet = (e, id, rowIndex) => {
    const newRemarkId = e.target.value;
    const newDataArray = [...RemarkId]; // Copy the original array
    newDataArray[rowIndex] = {
      ...newDataArray[rowIndex],
      RemarkId: newRemarkId,
    };
    setRemarkId(newDataArray);
  };

  const remarkUpdate = (id, index) => {
    const dataToUpdate = [
      {
        operationType: 0,
        path: "Remarks",
        op: "Add",
        from: "string",
        value: RemarkId[index].RemarkId,
      },
    ];
    console.log("Data to update order:", dataToUpdate);
    try {
      const updateDeliveryBoy = Order.updateOrderPartially(dataToUpdate, id);
      if (updateDeliveryBoy) {
        alert("Remarks saved")
      }
    } catch (error) {
      console.error("Error updating order:", error);
    }
  }

  const [selectedDateFD, setSelectedDateFD] = useState('default');

  const [previousDates, setPreviousDates] = useState([]);
  const navigate = useNavigate();

  const handleMouseEnter = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: true },
    }));
  };

  // const { formatDate, setOrderListHomeDelivery, fetchOrderData, orderListHomeDelivery } = props

  const closeItemDetailModal = () => {
    setItemDetailModal(false);
  };

  const handleMouseLeave = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: false },
    }));
  };

  const [GstModal, setGstModal] = useState(false);
  const [ord1Id, setOrd1Id] = useState();

  const openGstModal = async (id) => {
    setOrd1Id(id);
    await handleGetItem(id);
    setGstModal(true);
  };

  const closeGstModal = () => {
    setGstModal(false);
  };

  const isButtonHovered = (rowIndex, btnId) => {
    return hoverStates[rowIndex]?.[btnId];
  };

  const [ItemDetailModal, setItemDetailModal] = useState(false);
  const [ItemDetail, setItemDetail] = useState({});

  //const [orderList, setOrderList] = useState();
  const openItemDetailModal = async (id) => {
    await handleGetItem(id);
    setItemDetailModal(true);
  };

  const UpdatePaymentPartially = async (e, data) => {
    if (window.confirm("Are you sure you want to change the payment status")) {
      // payment status and paid amount and balance amount  
      console.log(e.target.value)
      console.log(data)

      const value = Number(e.target.value);

      const storedArrayString = localStorage.getItem('loginAccountUserData');
      var loginUser;
      if (storedArrayString) {
        loginUser = JSON.parse(storedArrayString);
      }

      const datetime = await CurrentDateAndTime.getCurrentDateTime();
      // const datetime = new Date(isoString);

      const updatedValues = [
        {
          operationType: 0,
          path: "PaymentModeId",
          op: "Add",
          from: "string",
          value: value
        },
        {
          operationType: 0,
          path: "balanceAmt",
          op: "Add",
          from: "string",
          value: "0"
        },
        {
          operationType: 0,
          path: "paymentAmount",
          op: "Add",
          from: "string",
          value: Number(data.grandTotal) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0))
        },
        {
          operationType: 0,
          path: "paymentStatus",
          op: "Add",
          from: "string",
          value: "Done"
        },
        {
          operationType: 0,
          path: "paymentDoneOn",
          op: "Add",
          from: "string",
          value: datetime
        },
        {
          operationType: 0,
          path: "paymentDoneBy",
          op: "Add",
          from: "string",
          value: loginUser.userId
        }
      ]

      await Order.updateOrderPartially(updatedValues, data.ord1Id);

      const filterData1 = [{
        ord1Id: data.ord1Id,
        paymentModeId: value,
        paymentAmount: Number(data.grandTotal) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0)),
        transId: "",
        payUId: 0,
        bankRefNo: "",
        status: "",
        paymentTypeMethod: "",
      }]

      await Order.createPaymentModeWithOrder(filterData1)
      fetchOrderDataPickup()
      alert("Updated")

    }

    // const Update = [
    //   {
    //     operationType: 0,
    //     path: "Remarks",
    //     op: "Add",
    //     from: "string",
    //     value: id,
    //   },
    // ];
    // console.log("Data to update order:", Update); // Logging for debugging
    // try {
    //   const update = Order.updateOrderPartially(Update);
    //   if (update) {
    //     alert("Updated Remark")
    //   }
    //   // Handle success or further processing
    // } catch (error) {
    //   console.error("Error updating order:", error); // Error handling
    //   // Handle error condition
    // }
  }

  const handleGetItem = async (id) => {
    var filterData = {
      fieldName: "ord1Id",
      operatorName: "equal",
      compareValue: id,
    };
    // const billdata = await Order.getOrderListWithFilter(filterData);
    const orderlistdata = await Order.getOrderItemWiseList(id);
    setItemDetail(orderlistdata);
    console.log(orderlistdata);
  };

  const [showBillPrint, setShowBillPrint] = useState(false);

  const closeBillPrint = () => {
    setShowBillPrint(false)
  }

  const handlePrintButton = (id) => {
    setOrd1Id(id)
    setShowBillPrint(true)
    // navigate("/OrderTakerBillPrint", {
    //   state: {
    //     id,
    //   },
    // });
  };

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    OnChangeDate(e)
  };

  const handleDateChangefordelivery = (e) => {
    setSelectedDateFD(e.target.value);
    OnChangeDateFD(e)
  };


  function convertTo12HourFormat(twentyFourHourTime) {
    if (!twentyFourHourTime) {
      return "";
    }

    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }

    return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  useEffect(() => {
    const today = new Date();
    const dates = [];

    for (let i = 0; i < 5; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date);
    }

    setPreviousDates(dates);

    setSelectedDate(formatDate(dates[0]));

  }, []);

  const OnChangeDate = async (e) => {
    console.log(e.target.value)
    if (e.target.value === "default") {
      // Filter criteria for home delivery orders
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 753,
        }
      ];
      try {
        // const data = await OrderApi.getOrderListReport(filterData);
        const data = await OrderApi.getRawOrderList(filterData);
        console.log(data?.orderList)
        setOrderListPickUp(data?.orderList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      const filterData = [
        {
          fieldName: "orderDate",
          operatorName: "Equal",
          compareValue: e.target.value,
        },
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 753,
        }
      ];
      try {
        const data = await OrderApi.getRawOrderReport(filterData);
        setOrderListPickUp(data?.orderList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  const OnChangeDateFD = async (e) => {
    console.log(e.target.value)
    if (e.target.value === "default") {
      // Filter criteria for home delivery orders
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 753,
        }
      ];
      try {
        // const data = await OrderApi.getOrderListReport(filterData);
        const data = await OrderApi.getRawOrderList(filterData);
        console.log(data)
        setOrderListPickUp(data?.orderList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      const filterData = [
        {
          fieldName: "deliveryDate",
          operatorName: "Equal",
          compareValue: e.target.value,
        },
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 753,
        }
      ];
      try {
        const data = await OrderApi.get(filterData);
        setOrderListPickUp(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  useEffect(() => {
    const newDataArray = [...RemarkId]; // Copy the original array
    if (orderListPickUp) {
      if (orderListPickUp.length > 0) {
        // Loop through the orderList to update the remarks in RemarkId
        orderListPickUp.forEach((order, index) => {
          const newRemarkId = order.remarks; // Get the remark from orderList
          newDataArray[index] = { ...newDataArray[index], RemarkId: newRemarkId };
        });
        setRemarkId(newDataArray); // Update the RemarkId state
      }
    }
  }, [orderListPickUp]);

  const [Modal, setModal] = useState(false);

  const openModal = async (id) => {
    await handleGetItem(id);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  // const handlePaymentPay = async (orderNo, ord1Id) => {
  //   navigate("/Request", {
  //     state: {
  //       orderNo,
  //       ord1Id
  //     }
  //   });
  // }

  const [PaymentModal, setPaymentModal] = useState(false);
  const [PaymentModalData, setPaymentModalData] = useState([]);
  const [orderId, setOrderId] = useState();

  const openPaymentModal = (ord1Id, orderId) => {
    handleGetPayment(ord1Id)
    setOrderId({ ord1Id: ord1Id, orderNo: orderId });
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleGetPayment = async (orderId) => {
    const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
    console.log(PaymentData);
    setPaymentModalData(PaymentData);
    if (PaymentData.length > 0) {
      setPaymentModal(true);
    }
    else {
      alert("No Payment Found")
    }
  };

  const handlePayment = async () => {
    const orderNo = orderId.orderNo;
    const ord1Id = orderId.ord1Id;

    navigate("/Request", {
      state: {
        orderNo,
        ord1Id
      }
    });
  }

  const [paymentModeDetails, setPaymentModeDetails] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const filteredData = [
        {
          fieldName: "isDefault",
          operatorName: "Equal",
          compareValue: true
        },
        {
          fieldName: "masterType",
          operatorName: "Equal",
          compareValue: 29
        }
      ]
      const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
      console.log("data", data);
      if (data.length > 0) {
        const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
        console.log(paymentMode)
        setPaymentModeDetails(paymentMode);
      }
      else {
        //alert("Please select default Bill series");
      }
    }
    fetchData();
  }, [])

  const orderStatusFunction = async (event, ord1Id, index) => {
    const eventValue = event.target.value
    setOrderListPickUp(prevValues => {
      const updatedList = [...prevValues];
      updatedList[index] = { ...updatedList[index], overallOrderStatus: eventValue };
      return updatedList;
    });
    setLoading(true);
    const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
    // const formattedDate = new Date(isoString);

    const storedArrayString = localStorage.getItem('loginAccountUserData');
    const loginUser = JSON.parse(storedArrayString);

    const updatedValues = [
      {
        operationType: 0,
        path: "overallOrderStatus",
        op: "Add",
        from: "string",
        value: eventValue
      },
      {
        operationType: 0,
        path: "orderConfirmedBy",
        op: "Add",
        from: "string",
        value: loginUser.userId
      },
      {
        operationType: 0,
        path: "orderConfirmedOn",
        op: "Add",
        from: "string",
        value: formattedDate
      },
    ]
    await OrderApi.updateOrderPartially(updatedValues, ord1Id);
    fetchOrderData();
    // ***************************
    const itemListData = await OrderApi.getOrderItemWiseList(ord1Id);
    console.log(itemListData);
    setLoading(true)
    for (var i = 0; i < itemListData.length; i++) {
      //alert(itemListData[i].kotStatus)
      if (itemListData[i].kotStatus === "Pending") {
        // **************************** Order 3 *********************
        const statusData = {
          ord1Id: ord1Id,
          ord2Id: itemListData[i].ord2Id,
          orderStatus: "Received",
          orderDateTime: formattedDate,
        }
        await OrderApi.insertItemStatus(statusData);

        // ************************** Order2 **********************
        const data = [
          {
            operationType: 0,
            path: "KotStatus",
            op: "Add",
            from: "string",
            value: "Received"
          }
        ]
        await OrderApi.updateItemPartially(data, itemListData[i].ord2Id);
      }
    }
    fetchOrderData();
    alert(`Updated status to ${eventValue}`);
    setLoading(false);
  }

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);
    //console.log(hours + ":" + minutes);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00`;
    }
  }

  const timeFormat = (twentyFourHourTime) => {
    const [startTime, endTime] = twentyFourHourTime.split('-');
    const convertedStartTime = convertTo12HourFormat(startTime.trim());
    const convertedEndTime = convertTo12HourFormat(endTime.trim());
    const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
    return convertedTimeRange;
  }

  const formatTimestamp = (timestampString) => {
    const timestamp = new Date(timestampString);

    const formattedDate = `${("0" + timestamp.getDate()).slice(-2)}-${("0" + (timestamp.getMonth() + 1)).slice(-2)}-${timestamp.getFullYear()}`;

    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    const formattedDateTime = `${formattedDate} , ${formattedTime}`;
    return formattedDateTime;
  }

  return (
    <div>
      <table className="tableStyle mt-4">
        <thead className="table_headerSticky" style={{ top: "-10px" }}>
          <tr>
            <th className="thStyle">Select</th>
            <th className="thStyle">Order No.</th>
            <th className="thStyle">Mobile&nbsp;No</th>
            <th className="thStyle">Total&nbsp;Amt.</th>

            <th className="thStyle">
              <div>
                <label>Order</label>
                <br />
                {/* <select name="" id="" onChange={(e)=>OnChangeDate(e)}> */}
                <select value={selectedDate} onChange={handleDateChange}>
                  <option value="default">Select date</option>
                  {previousDates.map((date, index) => (
                    <option key={index} value={formatDate2(date)}>
                      {formatDate(date)}
                    </option>
                  ))}
                </select>
              </div>
            </th>
            <th className="thStyle">
              <div>
                <label>Delivery</label>
                <br />
                <select value={selectedDateFD} onChange={handleDateChangefordelivery}>
                  <option value="default">Select date</option>
                  {previousDates.map((date, index) => (
                    <option key={index} value={formatDate2(date)}>
                      {formatDate(date)}
                    </option>
                  ))}
                </select>
              </div>
            </th>
            <th className="thStyle">Order Time Slot</th>
            <th className="thStyle">Kot Despatch Time</th>
            <th className="thStyle">Order Status</th>
            <th className="thStyle" style={{ width: "12%" }}>
              Payment
            </th>
            <th className="thStyle" style={{ width: "7%" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody className="tbody_body_m1">
          {(() => {
            if (orderListPickUp !== null && Array.isArray(orderListPickUp)) {
              console.log(orderListPickUp);
              return orderListPickUp
                // .slice().reverse()
                .map((data, rowIndex) => (
                  <tr
                    className="hoverRow"
                    style={{ cursor: "pointer" }}
                    key={rowIndex}
                  >
                    <td
                      className="tdStyle"
                      onClick={() => openItemDetailModal(data.ord1Id)}
                    >
                      {" "}
                      <button style={{ padding: "5px 10px" }}>
                        {rowIndex + 1}
                      </button>
                    </td>
                    <td className="tdStyle">{data.orderNo}</td>
                    <td className="tdStyle">{data.accName}<br />
                      {/* {data.accMobileNo}  */}
                      {UserDetails.canLst3DgtNo ? (maskMobileNo(data.mobileNo)) : (data.mobileNo)}
                    </td>
                    <td className="tdStyle" style={{ width: "10%", textAlign: "center" }}>
                      <span style={{ fontWeight: "600" }}>Total&nbsp;Amt: &#x20B9;</span>
                      {data.grandTotal !== null &&
                        data.grandTotal !== undefined
                        ? data.grandTotal
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Cancel&nbsp;Amt: &#x20B9;</span>
                      {data.o1CancelledAmt !== null &&
                        data.o1CancelledAmt !== undefined
                        ? data.o1CancelledAmt
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Bal.&nbsp;Amt: &#x20B9;</span>
                      {data.grandTotal !== null &&
                        data.grandTotal !== undefined
                        ? data.grandTotal - (data.o1CancelledAmt + data.paymentAmount)
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Paid&nbsp;Amt: &#x20B9;</span>
                      {data.paymentAmount !== null &&
                        data.paymentAmount !== undefined
                        ? data.paymentAmount
                        : 0}
                    </td>
                    <td className="tdStyle">
                      {" "}
                      {formatDate(data.orderDate)}
                      <br /> {convertTo12HourFormat(String(data.orderTime))}
                    </td>
                    <td className="tdStyle">
                      {formatDate(data.deliveryDate)}<br /> {data.timeSlotName}
                      {/* {timeFormat((data.deliveryTime))} */}
                    </td>
                    {/* <td className="tdStyle">{convertTo12HourFormat(String(data.receivedTime))}</td> */}
                    <td className="tdStyle">{data.timeSlotName}</td> {/*{timeFormat(data.deliveryTime)}*/}
                    <td className="tdStyle">{convertTo12HourFormat(String(data.kotDispatchTargetTime))}</td>
                    <td className="tdStyle">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <select
                            className="form-control modify_im_input"
                            style={{ width: "80%" }}
                            value={data.overallOrderStatus}
                            onChange={(event) => orderStatusFunction(event, data.ord1Id, rowIndex)}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Confirm">Confirm</option>
                            <option value="Done">Done</option>
                            <option value="Cancel">Cancel</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                          <i onClick={() => openModal(data.ord1Id)}
                            class="fa-solid fa-circle-info mt-1 icon_ov1"
                            style={{
                              color: "#646464",
                              cursor: "pointer",
                              margin: "0 !important",
                            }}
                          ></i>
                        </div>
                        <div className="mt-2">
                          <label style={{
                            display: data.orderConfirmedByName != "" && data.orderConfirmedByName != null && data.orderConfirmedByName != undefined ? "block" : "none",
                            fontSize: "14px"
                          }} >
                            Confirm By :- <span style={{ fontWeight: "600" }}>{data.orderConfirmedByName}</span><br />
                            {moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}
                          </label>
                        </div>
                      </div>
                    </td>
                    {/* <td className="tdStyle">
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <i onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                          onMouseEnter={() =>
                            handleMouseEnter(rowIndex, "btn1")
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(rowIndex, "btn1")
                          }
                          class="fa-solid fa-note-sticky icon_ov1"
                          style={{ color: "indigo", position: "relative" }}
                        >
                          {isButtonHovered(rowIndex, "btn1") && (
                            <div className="hover-text">Payment Details</div>
                          )}
                        </i>

                        <i onClick={() => handlePaymentPay(data.orderNo, data.ord1Id)}
                          onMouseEnter={() =>
                            handleMouseEnter(rowIndex, "btn2")
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(rowIndex, "btn2")
                          }
                          class="fa-solid fa-sack-dollar icon_ov1"
                          style={{ color: "#7c9504", position: "relative" }}
                        >
                          {isButtonHovered(rowIndex, "btn2") && (
                            <div className="hover-text">Pay Online</div>
                          )}
                        </i>
                      </div>

                      <div
                        className="mt-2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          onChange={(e) =>
                            RemarkSet(e, data.ord1Id, rowIndex)
                          }
                          value={
                            RemarkId[rowIndex]?.RemarkId
                          }
                          style={{ width: "130px", marginRight: "5px" }}
                        />
                        <i
                          onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                          class="fa-solid fa-floppy-disk icon_ov1"
                          style={{
                            color: "brown",
                            cursor: "pointer",
                            position: "relative",
                            fontSize: "20px"
                          }}
                        ></i>
                      </div>
                      <div className="mt-2" style={{ display: "flex" }}>
                        <select
                          style={{
                            width: "70px",
                            padding: "2px 5px",
                            textAlign: "center",
                          }}
                          className="form-control mt-1"
                        >
                          <option>Cash</option>
                          <option>CDC</option>
                          <option>HDFC</option>
                        </select>{" "}
                        &nbsp;
                        <button
                          className="btn mt-1"
                          style={{
                            fontWeight: "600",
                            color: "#574e4e",
                            backgroundColor: "ghostwhite",
                            border: "1px solid darkgrey",
                          }}
                        >

                          {data.paymentStatus ? data.paymentStatus : "Pending"}
                        </button>
                      </div>
                    </div>
                  </td> */}
                    <td className="tdStyle">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "grid", alignItems: "baseline", alignContent: "baseline" }}>
                          <div
                            className="mt-2"
                            style={{ display: "flex", alignItems: "center" }}
                          >

                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Remark"
                                style={{ width: "140px", marginRight: "12px" }}
                                name={`remark-${rowIndex}`}
                                value={inputValues[`remark-${rowIndex}`] || ''}
                                onChange={(event) => handleInputChange(event, rowIndex)}
                              />
                              <i
                                className="fa-solid fa-floppy-disk icon_ov1"
                                style={{ color: "brown", cursor: "pointer" }}
                                onClick={() => updateRemarkPartially(data.ord1Id, rowIndex)}
                              ></i> */}
                            <input
                              type="text"
                              className="form-control remark-ov"
                              placeholder="Remark"
                              onChange={(e) =>
                                RemarkSet(e, data.ord1Id, rowIndex)
                              }
                              value={
                                RemarkId[rowIndex]?.RemarkId
                              }
                              style={{ width: "138px", marginRight: "5px" }}
                            />
                            <i
                              onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                              class="fa-solid fa-floppy-disk icon_ov1"
                              style={{
                                color: "brown",
                                cursor: "pointer",
                                position: "relative",
                                fontSize: "20px"
                              }}
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "btn10")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "btn10")
                              }
                            >
                              {isButtonHovered(rowIndex, "btn10") && (
                                <div className="hover-text">Save Remark</div>
                              )}
                            </i>
                          </div>

                          <div className="mt-2" style={{ display: "flex", marginTop: "10px" }}>
                            <select
                              disabled={data.paymentStatus === "Done" ? true : false}
                              value={data.paymentMode}
                              onChange={(e) => UpdatePaymentPartially(e, data)}
                              style={{
                                width: "70px",
                                padding: "2px 5px",
                                textAlign: "center",
                              }}
                              className="OrderPayMode form-control mt-1"
                            >
                              <option value="0"> --- </option>
                              {(() => {
                                if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                  return paymentModeDetails //.filter(item => item.display == true)
                                    // .filter(data =>
                                    //   data.accountTitle && typeof data.accountTitle === 'string' &&
                                    //   !data.accountTitle.toLowerCase().includes("online" || "cdc"))
                                    .map((data, index) => (
                                      <option key={index} disabled={data.display == true ? false : true} value={data.accountId}>
                                        {data.accountTitle}
                                      </option>
                                    ))
                                }
                              })()}
                            </select>
                            &nbsp;
                            <button
                              className="btn mt-1 btnPaymentStatus-ov"

                            >
                              {data.paymentStatus ? data.paymentStatus : "Pending"}
                            </button>

                            <div
                              className="iconBox-ov"
                            >
                              <i
                                onMouseEnter={() =>
                                  handleMouseEnter(rowIndex, "btn1")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(rowIndex, "btn1")
                                }
                                class="fa-solid fa-note-sticky icon_ov1"
                                style={{
                                  color: "indigo",
                                  position: "relative",
                                }}
                                onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                              >
                                {isButtonHovered(rowIndex, "btn1") && (
                                  <div className="hover-text">Payment Details</div>
                                )}
                              </i>

                              <i
                                onMouseEnter={() =>
                                  handleMouseEnter(rowIndex, "btn2")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(rowIndex, "btn2")
                                }
                                onClick={() => handlePaymentPay(data.orderNo, data.ord1Id, orderListPickUp)}
                                class="fa-solid fa-sack-dollar icon_ov1"
                                style={{
                                  color: "#7c9504",
                                  position: "relative",
                                  // marginLeft: "20px",
                                }}
                              >
                                {isButtonHovered(rowIndex, "btn2") && (
                                  <div className="hover-text">Pay Online</div>
                                )}
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="tdStyle">
                      <div style={{ display: "grid", justifyContent: "center" }}>
                        <i
                          onMouseEnter={() => handleMouseEnter(rowIndex, "btn4")}
                          onMouseLeave={() => handleMouseLeave(rowIndex, "btn4")}
                          class="fa-brands fa-google icon_ov1"
                          style={{ color: "darkgoldenrod", position: "relative" }}
                          onClick={() => openGstModal(data.ord1Id)}
                        >
                          {isButtonHovered(rowIndex, "btn4") && (
                            <div
                              className="hover-text"
                              style={{ marginTop: "4%" }}
                            >
                              GST
                            </div>
                          )}
                        </i>
                        <div style={{ display: data.paymentStatus == "Done" ? "block" : "none" }}>
                          <i
                            onClick={() => handlePrintButton(data.ord1Id)}
                            onMouseEnter={() =>
                              handleMouseEnter(rowIndex, "btn3")
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(rowIndex, "btn3")
                            }
                            class="fa-solid fa-receipt icon_ov1"
                            style={{ color: "darkcyan", position: "relative" }}
                          // onClick={}
                          >
                            {isButtonHovered(rowIndex, "btn3") && (
                              <div
                                className="hover-text"
                                style={{ marginTop: "6%" }}
                              >
                                Receipt
                              </div>
                            )}
                          </i>
                          <br />
                        </div>
                      </div>
                    </td>
                  </tr>
                ));
            } else {
              // alert("else")
            }
          })()}
          {props.isShowTotalAmountInReport && (
            <tr className="table_tbody_Order_List"
              style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
              <td className="td_col6 td-padding-sn td_footer" colSpan={2} style={{ textAlign: "end" }}>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].grandTotalAmt
                  )}

                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].cancelOrderAmt 
                  )}

                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].grandTotalAmt - (summary[0].paidAmt + summary[0].cancelAmt)
                  )}
                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].paidAmt
                  )}
                </span>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                <span style={{ fontWeight: "600" }}>Total Cancel Order: </span>
                {summary && summary.length > 0 && (
                  summary[0].noOfCancelOrder 
                )}
                {/* <br />
                <span style={{ fontWeight: "600" }}>Total Return Request: </span>
                {summary && summary.length > 0 && (
                  summary[0].returnRequestQty
                )}
                <br /> */}
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={4}></td>
            </tr>
          )}
        </tbody>
      </table>

      {GstModal && (
        <GstModalOrderView closeGstModal={closeGstModal} ItemDetail={ItemDetail} ord1Id={ord1Id} />
      )}
      {ItemDetailModal && (
        <ItemDetailModalBox
          closeItemDetailModal={closeItemDetailModal}
          ItemDetail={ItemDetail}
          setItemDetail={setItemDetail}
          serviceTypeId={props.serviceTypeId}
        />
      )}
      {/************************** Info Modal ***************/}
      {Modal && (
        <OrderDetailStatusModal
          closeModal={closeModal}
          ItemDetail={ItemDetail}
        />
      )}
      {/************************** Payment Modal ***************/}
      {PaymentModal && (
        <PaymantModeModel handlePayment={handlePayment} PaymentModalData={PaymentModalData} closePaymentModal={closePaymentModal} />
      )}

      {/* ****************************** BILL PRINT *************************** */}
      {showBillPrint && (
        <div style={{ display: "none" }}> {/**/}
          <div className="modal-back" onClick={closeBillPrint}>
            <div className="billPrintModal" style={{}}>
              <div style={{ padding: "5px", backgroundColor: "white" }}>
                <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} />
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default PickUp;
