import React, { useEffect, useState } from "react";

const SaleItemAddPageComponent = (props) => {
  const {
    TransColumnCb,
    SaleItemAdd,
    showModal3,
    appendValue,
    itemMasterRows,
    onChangeProductSaveMethod,
    handleProductRowKeyDown,
    mainPageInputRef,
    handleInputChange,
    nextCellRefs,
    handleProductNameChange,
    handleKeyDown_ProductSuggestion,
    itemMasterList,
    itemRefs,
    handleProductSelection,
    suggestionBoxRef,
    handleAddRow,
    handleDeleteRow,
    indexToEdit,
  } = props;
  console.log(TransColumnCb);
  debugger;

  const [rowIndex, setRowindex] = useState(0);

  const [ItemTooEdit, setItemTooEdit] = useState([]);

  useEffect(() => {
    console.log(itemMasterRows.length - 1);
    if (indexToEdit === "" || indexToEdit === undefined) {
      setRowindex(itemMasterRows.length - 1); // Default to last item
    } else {
      const index = parseInt(indexToEdit, 10); // Ensure index is a number
      if (index >= 0 && index < itemMasterRows.length) {
        setRowindex(index);
      } else {
        setRowindex(itemMasterRows.length - 1); // Fall back to last item
      }
    }
  }, [indexToEdit, itemMasterRows.length]); // Ensure effect runs when itemMasterRows changes

  useEffect(() => {
    if (rowIndex >= 0 && rowIndex < itemMasterRows.length) {
      setItemTooEdit([itemMasterRows[rowIndex]]); // Set the item at rowIndex
    } else {
      setItemTooEdit([]); // Clear if index is invalid
    }
  }, [rowIndex, itemMasterRows]); // Runs when rowIndex or itemMasterRows changes

  // Debugging logs
  console.log(itemMasterRows);
  console.log(itemMasterRows.length);
  console.log(rowIndex);
  console.log(ItemTooEdit);

  return (
    <div className="SaleItemAddPageComponent">
      <div className="SaleItemAddPageComponent-TopPanel">
        <i onClick={SaleItemAdd} className="fa-solid fa-arrow-left"></i>
        <p>Add Items to Sale</p>
        <i onClick={showModal3} className="fa-solid fa-gear"></i>
      </div>
      {ItemTooEdit.map((row) => (
        <div className="SaleItemAddPageComponent-inputContainer">
          {TransColumnCb.isInputVisible3 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="productName"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.productName}
                onChange={(e) => handleProductNameChange(e, rowIndex)}
                onKeyDown={(e) => handleKeyDown_ProductSuggestion(e, rowIndex)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][3] = input;
                }}
                onBlur={() => {
                  itemMasterList[rowIndex].isDropdownOpen = false;
                }}
                id={`ItemName-td-${rowIndex}`}
              />
              {itemMasterList[rowIndex]?.isDropdownOpen && (
                <ul
                  className="ItemmasterDuggestionsaleinvoice"
                  ref={suggestionBoxRef}
                >
                  {itemMasterList[rowIndex].filteredProduct.map(
                    (item, index) => (
                      <li
                        key={item.id2}
                        onClick={() => handleProductSelection(rowIndex, item)}
                        ref={(el) => (itemRefs.current[index] = el)}
                        style={{
                          backgroundColor:
                            itemMasterList[rowIndex].highlightedIndex === index
                              ? "#e6f7ff"
                              : "transparent",
                        }}
                      >
                        {item.name}
                      </li>
                    )
                  )}
                </ul>
              )}

              <label className="SaleItemAddPageComponent-label">
                {" "}
                Item Name
              </label>
            </div>
          )}
          {TransColumnCb.isInputVisible1 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required
                name="defaultBarcode"
                value={row.defaultBarcode}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 1)}
                ref={mainPageInputRef}
                id={`Code-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Code</label>
            </div>
          )}

          {TransColumnCb.isInputVisible2 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="parentGrpName"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.parentGrpName}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 2)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][2] = input;
                }}
                id={`Group-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Group</label>
            </div>
          )}

          {TransColumnCb.isInputVisible4 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="shortDescription"
                value={row.shortDescription}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 4)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][4] = input;
                }}
                id={`MasterDescription-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Description
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible5 && (
            <div className="SaleItemAddPageComponent-Input-div">
              {/* Print "this" in the label */}
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="productUnit"
                value={row.productUnit}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 5)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][5] = input;
                }}
                id={`Unit-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Product Unit
              </label>{" "}
            </div>
          )}

          {TransColumnCb.isInputVisible6 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="lens_Dia"
                value={row.lens_Dia}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 6)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][6] = input;
                }}
                id={`Dia-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> DIA</label>
            </div>
          )}

          {TransColumnCb.isInputVisible7 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="lens_Eye"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.lens_Eye}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 7)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][7] = input;
                }}
                id={`Eye-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> EYE</label>
            </div>
          )}

          {TransColumnCb.isInputVisible8 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="lens_SPH"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.lens_SPH}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 8)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][8] = input;
                }}
                id={`Sph-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> SPH</label>
            </div>
          )}

          {TransColumnCb.isInputVisible9 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="lens_CYL"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.lens_CYL}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 9)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][9] = input;
                }}
                id={`Cyl-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> CYL</label>
            </div>
          )}

          {TransColumnCb.isInputVisible10 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="lens_AXIS"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.lens_AXIS}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 10)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][10] = input;
                }}
                id={`Axis-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> AXIS</label>
            </div>
          )}

          {TransColumnCb.isInputVisible11 && (
            <div className="SaleItemAddPageComponent-Input-div">
              {" "}
              <input
                type="text"
                name="lens_Add"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.lens_Add}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 11)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][11] = input;
                }}
                id={`Add-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> ADD</label>
            </div>
          )}

          {TransColumnCb.isInputVisible12 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="Attribute1"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.Attribute1}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 12)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][12] = input;
                }}
                id={`Attribute1-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                Attribute1
              </label>{" "}
            </div>
          )}

          {TransColumnCb.isInputVisible13 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Attribute2"
                value={row.Attribute2}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 13)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][13] = input;
                }}
                id={`Attribute2-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                Attribute2
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible14 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                name="Attribute3"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                value={row.Attribute3}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 14)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][14] = input;
                }}
                id={`Attribute3-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Attribute3
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible15 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Attribute4"
                value={row.Attribute4}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 15)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][15] = input;
                }}
                id={`Attribute4-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Attribute4
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible16 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Attribute5"
                value={row.Attribute5}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 16)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][16] = input;
                }}
                id={`Attribute5-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Attribute5
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible17 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Note1"
                value={row.Note1}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 17)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][17] = input;
                }}
                id={`Note1-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Note1</label>
            </div>
          )}

          {TransColumnCb.isInputVisible18 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Note2"
                value={row.Note2}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 18)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][18] = input;
                }}
                id={`Note2-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Note2</label>
            </div>
          )}

          {TransColumnCb.isInputVisible19 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="BatchNo"
                value={row.BatchNo}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 19)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][19] = input;
                }}
                id={`BatchNo-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Batch No
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible20 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="MgfDt"
                value={row.MgfDt}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 20)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][20] = input;
                }}
                id={`MgfDt-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Mgf Dt</label>
            </div>
          )}

          {TransColumnCb.isInputVisible21 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="ExpDt"
                value={row.ExpDt}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 21)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][21] = input;
                }}
                id={`ExpDt-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Exp Dt</label>
            </div>
          )}

          {TransColumnCb.isInputVisible22 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="Qty"
                value={row.Qty}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 22)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][22] = input;
                }}
                //onKeyDown={handleKeyDown} BatchPopup
                id={`Qty-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Qty</label>
            </div>
          )}

          {TransColumnCb.isInputVisible23 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="FreeQty"
                value={row.FreeQty}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 23)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][23] = input;
                }}
                id={`FreeQty-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Free Qty
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible24 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="itemPrice"
                value={row.itemPrice}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 24)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][24] = input;
                }}
                id={`Price-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Price</label>
            </div>
          )}

          {TransColumnCb.isInputVisible25 && (
            <div className="SaleItemAddPageComponent-Input-div">
              {" "}
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="DiscPer"
                value={row.DiscPer}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 25)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][25] = input;
                }}
                id={`DiscPer-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Dis%</label>
            </div>
          )}

          {TransColumnCb.isInputVisible26 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="DoubleDis"
                value={row.DoubleDis}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 26)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][26] = input;
                }}
                id={`DoubleDis-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                Double Dis%
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible27 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="DiscAmt"
                value={row.DiscAmt}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 27)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][27] = input;
                }}
                id={`DiscAmt-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Dis Amt</label>
            </div>
          )}

          {TransColumnCb.isInputVisible28 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="TotalAmt"
                value={row.TotalAmt}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 28)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][28] = input;
                }}
                id={`TotalAmt-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Total Amt
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible29 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="salePriceForPur"
                value={row.salePriceForPur}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 29)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][29] = input;
                }}
                id={`SalePrc-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Sale Prc
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible30 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="saleProfit"
                value={row.saleProfit}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 30)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][30] = input;
                }}
                id={`SalePrft-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Sale Prft
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible31 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="mrpPrice"
                value={row.mrpPrice}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 31)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][31] = input;
                }}
                id={`MrpPrc-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> MRP Prc</label>
            </div>
          )}

          {TransColumnCb.isInputVisible32 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="BrkrName"
                value={row.BrkrName}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 32)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][32] = input;
                }}
                id={`BrkrName-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">
                {" "}
                Brkr Name
              </label>
            </div>
          )}

          {TransColumnCb.isInputVisible33 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="salesManItemIncentive"
                value={row.salesManItemIncentive}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 33)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][33] = input;
                }}
                id={`BrkgPer-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label"> Brkg%</label>
            </div>
          )}

          {TransColumnCb.isInputVisible34 && (
            <div className="SaleItemAddPageComponent-Input-div">
              <input
                type="text"
                className="SaleItemAddPageComponent-Input"
                required // Conditional class name
                name="BrkgAmt"
                value={row.BrkgAmt}
                onChange={(e) => handleInputChange(e, rowIndex)}
                onBlur={(e) =>
                  onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                }
                onKeyDown={(e) => handleProductRowKeyDown(e, rowIndex, 34)}
                ref={(input) => {
                  if (!nextCellRefs.current[rowIndex])
                    nextCellRefs.current[rowIndex] = [];
                  nextCellRefs.current[rowIndex][34] = input;
                }}
                id={`BrkgAmt-td-${rowIndex}`}
              />
              <label className="SaleItemAddPageComponent-label">Brkg Amt</label>
            </div>
          )}
        </div>
      ))}
      
      <div className="SaleItemAddPageComponent-buttons">
        <button onClick={SaleItemAdd}>Add</button>
        <button onClick={() => handleDeleteRow(rowIndex)}>Reset</button>
      </div>
    </div>
  );
};
// <label className="SaleItemAddPageComponent-label">
//   {" "}
//   Input 34
// </label>

export default SaleItemAddPageComponent;
