import React from "react";
import { Route, Routes } from "react-router-dom";
// import NavBar from "../src/Components/NavBar";
import PageTabs from "./Inventory/Components/PageTabs";
import Savebutton from "./Inventory/Components/Savebutton";
import MemberCard from "./Inventory/Master/AccountMaster/MemberCard";
import AccountGroupMasterList from "./Inventory/Master/AccountMaster/AccountGroupMasterList";
import AgentMasterList from "./Inventory/Master/AccountMaster/AgentMasterList";
import AgentMaster from "./Inventory/Master/AccountMaster/AgentMaster";
import AccountGroupMaster from "./Inventory/Master/AccountMaster/AccountGroupMaster";
import MiscellaneousMaster from "./Inventory/Master/AccountMaster/MiscellaneousMaster.jsx";
// import ModifyCompany from "./Inventory/Company/ModifyCompMaster";
import ModifyCompany1 from "./Inventory/Company/ModifyCompMaster1";
// import CheckboxWithInput from "./Inventory/Company/Variant2";
import RazorpayCheckout from "./Restaurant/OrderTaker/RazorpayCheckout.jsx";

import AccountMaster from "./Inventory/Master/AccountMaster/AccountMaster";
import AccountMasterList from "./Inventory/Master/AccountMaster/AccountMasterList";
import DateTime from "./Inventory/Components/DateTime";
import ImagePreview from "./Inventory/Components/ImagePreview";
import AccountMasterview from "./Inventory/Components/AccountMasterview";

import ItemGroupMasterList from "./Inventory/Master/InvertoryMaster/ItemGroupmasterList";
import LensRemarkMaster from "./Inventory/Master/InvertoryMaster/lensRemarkMaster";
import ItemMasterList from "./Inventory/Master/InvertoryMaster/ItemMasterList";
import ItemMaster from "./Inventory/Master/InvertoryMaster/ItemMaster";
// import ItemMaster1 from "./Inventory/Master/InvertoryMaster/ItemMaster1";

import StationMasterList from "./Inventory/Master/AccountMaster/StationMasterList";
import StationMaster from "./Inventory/Master/AccountMaster/StationMaster";

import AccountCategoryMasterList from "./Inventory/Master/AccountMaster/AccountCategoryMasterList";
import AccountCategoryMaster from "./Inventory/Master/AccountMaster/AccountCategoryMaster";

import TransportMasterList from "./Inventory/Master/AccountMaster/TransportMasterList";
import TransportMaster from "./Inventory/Master/AccountMaster/TransportMaster";

import UnitMasterList from "./Inventory/Master/InvertoryMaster/UnitMasterList";
import UnitConversionMasterList from "./Inventory/Master/InvertoryMaster/UnitConversionMasterList";
import UnitMaster from "./Inventory/Master/InvertoryMaster/UnitMaster";

import LensRangePriceMasterList from "./Inventory/Master/InvertoryMaster/LensRangePriceMasterList";
import ProductPriceAccCatWise from "./Inventory/Master/InvertoryMaster/ProductPriceAccCatWise";
import LensRemarkMasterList from "./Inventory/Master/InvertoryMaster/lensRemarkMasterList";
import ItemGroupMaster1 from "./Inventory/Master/InvertoryMaster/ItemGroupMasterUpdate.jsx";
import ItemGroupMaster from "./Inventory/Master/InvertoryMaster/ItemGroupMaster.jsx";

import UnitConversionMaster from "./Inventory/Master/InvertoryMaster/UnitConversionMaster"
import LensRangePriceMaster from "./Inventory/Master/InvertoryMaster/LensRangePriceMaster"

import SaleInvoice from "./Inventory/Transaction/Sale/SaleInvoice";
import SaleInvoice2 from "./Inventory/Transaction/Sale/SaleInvoice2";
//import SaleInvoice3 from "./Inventory/Transaction/Sale/SaleInvoice3";
import SaleVoucherList from "./Inventory/Transaction/Sale/SaleVoucherList";
import ItemAttributeList from "./Inventory/Props/ItemAttributeList";
import ProductList from "./Inventory/Props/ProductList";
import BatchWiseModalBox from "./Inventory/Props/BatchWiseModalBox";
import TableMasterList from "./Inventory/Master/TableMaster/TableMasterList.jsx";
import TableGroupList from "./Inventory/Master/TableMaster/TableGroupList.jsx";
import TableAllocation from "./Inventory/Master/TableMaster/TableAllocation.jsx";

import TaxCategoryList from "./Inventory/Master/BillAndOther/TaxCategoryList";
import BillTypesList from "./Inventory/Master/BillAndOther/BillTypesList";
import TaxCategory from "./Inventory/Master/BillAndOther/TaxCategory";
import SundryMasterList from "./Inventory/Master/BillAndOther/SundryMasterList";
import SundryMaster from "./Inventory/Master/BillAndOther/SundryMaster";
import StatusMasterList from "./Inventory/Master/BillAndOther/StatusMasterList";
import StatusMaster from "./Inventory/Master/BillAndOther/StatusMaster";
import LoyaltyMasterSetting from "./Inventory/Master/BillAndOther/loyaltyMasterSetting";
import BillType from "./Inventory/Master/BillAndOther/BillType";
import UserMasterList from "./Inventory/Master/Userdetails/UserMasterList";
import UserAuthentication from "./Inventory/Master/Userdetails/UserAuthentication";
//import OptWholeSaleInvoice from "./Inventory/OpticalWholesale/Sale/OptWholeSaleInvoice";

// -------------------{Props}----------------------
import UserMaster from "./Inventory/Master/Userdetails/UserMaster";
import NewTable from "./Inventory/Master/BillAndOther/NewTable";
import PosInvoice from "./Restaurant/Orders/POSInvoice.jsx";
import OrderView from "./Restaurant/Orders/OrderView";
import DineIn from "./Restaurant/Orders/DineIn";
import KotOrderList from "./Restaurant/Orders/KotOrderList.jsx";
import Payment from "./Restaurant/Payment.jsx";
import LensCreationGroup from "./Inventory/LensTransaction/LensCreationGroup";
import OrderTakerView from "./Restaurant/OrderTaker/OrderTakerView.jsx";

//import KotOrderView from "./Restaurant/OrderTaker/OrderView";
import OrderDetail from "./Restaurant/OrderTaker/OrderTakerDetail.jsx";
import CustomerInfo from "./Restaurant/OrderTaker/CustomerInfo";
import PaymentGateway from "./Restaurant/OrderTaker/PaymentGateway";
import CancelOrderedItem from "./Restaurant/OrderTaker/CancelOrderedItem";
import TableOrderAllocation from "./Restaurant/OrderTaker/TableAllocation";
import OrderFeedback from "./Restaurant/OrderTaker/OrderFeedback";
import List from "./Restaurant/Chef/List";
import PickupList from "./Restaurant/KotManager/PickupList";
import PickupItemList from "./Restaurant/KotManager/PickupItemList";
import OrderList from "./Restaurant/KotManager/OrderList";
import OrderDetails from "./Restaurant/KotManager/OrderDetails";
import Delivery from "./Restaurant/Delivery/Delivery";
import Attendance from "./Payroll/Attendance";
import ShiftMaster from "./Payroll/Master/ShiftMaster";
import LeaveMaster from "./Payroll/Master/LeaveMaster";
import MonthWiseAttendance from "./Payroll/MonthWiseAttendance";
import LeaveMasterList from "./Payroll/Master/LeaveMasterList";
import ShiftMasterList from "./Payroll/Master/ShiftMasterList";
import ProfessionalMasterList from "./Payroll/Master/ProfessionalMasterList";
import ProfessionalMaster from "./Payroll/Master/ProfessionalMaster";
import SalaryMasterList from "./Payroll/Master/SalaryMasterList";
import SalaryMaster from "./Payroll/Master/SalaryMaster";
// import Variants from "../src/Inventory/Components/AttributeFile/Variants.jsx"
import Variant2 from "../src/Inventory/Company/Variant2.jsx";
import OptionalfieldList from "./Inventory/Props/OptionalfieldList.jsx";
import VehicleMaster from "./Inventory/Master/Userdetails/VehicleMaster.jsx";
import DeliveryIncentive from "./Inventory/Master/Userdetails/DeliveryIncentive.jsx";
import LoginTypeMaster from "./Inventory/Master/Userdetails/LoginTypeMaster.jsx";
import DesignationMaster from "./Inventory/Master/Userdetails/DesignationMaster.jsx";
import XLS from "./Inventory/Master/Userdetails/XLS.jsx";
import GodownMaster from "./Inventory/Master/InvertoryMaster/GodownMaster";
import GodownMasterList from "./Inventory/Master/InvertoryMaster/GodownMasterList";
// import GradeMaster from "./Payroll/Master/GradeMaster";
// import GradeMasterList from "./Payroll/Master/GradeMasterList";
import DepartmentMaster from "./Inventory/Master/Userdetails/DepartmentMaster.jsx";
import UserActivity from "./Inventory/Master/Userdetails/UserActivity.jsx";
import PreviousOrder from "./Inventory/Dashboard/PreviousOrder";
import Table from "./Inventory/Components/tableFilter/Table.jsx";
import TraceLensStatus from "./Inventory/WorkshopOrder/TraceLensStatus";
import Piechart from "./Inventory/Dashboard/Piechart.jsx";
import AddNewItem from "./Inventory/Props/AddNewItem.jsx";
import ProductExchangeList from "./Inventory/Master/ProductExchangeList.jsx";
import ProductExchange from "./Inventory/Master/ProductExchange.jsx";
import ItemDepartmentMaster from "./Inventory/Master/Userdetails/ItemDepartmentMaster.jsx";
// ******************************{Sale Return}*************************************
// import SaleReturnVoucherList from "./Inventory/Transaction/SaleReturn/SaleReturnVoucherList.jsx";
// import SaleReturn from "./Inventory/Transaction/SaleReturn/SaleReturn.jsx";
// ******************************{Sale Purchase}*************************************
// import PurchaseVoucherList from "./Inventory/Transaction/Purchase/PurchaseVoucherList.jsx";
// import PurchaseInvoice from "./Inventory/Transaction/Purchase/PurchaseInvoice.jsx";
// import PurchaseOrder from "./Inventory/Transaction/Purchase/PurchaseOrder.jsx";
// import PurchaseChallan from "./Inventory/Transaction/Purchase/PurchaseChallan.jsx";
// ******************************{Sale SaleIn/Out}*************************************
import GodownTransfer from "./Inventory/Transaction/StockInOut/GodownTransfer.jsx";
import StockIn from "./Inventory/Transaction/StockInOut/StockIn.jsx";
import StockInList from "./Inventory/Transaction/StockInOut/StockInList.jsx";
import StockOut from "./Inventory/Transaction/StockInOut/StockOut.jsx";
import StockOutList from "./Inventory/Transaction/StockInOut/StockOutList.jsx";
import StockRequest from "./Inventory/Transaction/StockInOut/StockRequest.jsx";
// ******************************{Modal Box}*************************************
import MealCourseMaster from "./Inventory/ModalBox/MealCourseMaster.jsx";
// import SaleOrder from "./Inventory/Transaction/Sale/SaleOrder.jsx";
// import SaleChallan from "./Inventory/Transaction/Sale/SaleChallan.jsx";
import DeliveryTimeSlotSetting from "./Restaurant/Orders/DeliveryTimeSlotSetting.jsx";
import LogInMaster from "./Inventory/LogInPage/LogInMaster.jsx";
import OtpVerify from "./Inventory/LogInPage/OtpVerify.jsx";
import CreateBusiness from "./Inventory/LogInPage/CreateBusiness.jsx";
import LogInPanel from "./Inventory/LogInPage/LogInPanel.jsx";
import DamageAndShrinkageList from "./Inventory/Transaction/DamageAndShrinkage/DamageAndShrinkageList.jsx";
import DamageAndShrinkage from "./Inventory/Transaction/DamageAndShrinkage/DamageAndShrinkage.jsx";
import PayInPayOut from "./Inventory/Transaction/PayRecptJrnlCntr/PayInPayOut.jsx";
import AddVoucherList from "./Inventory/Transaction/PayRecptJrnlCntr/AddVoucherList.jsx";
import AddVoucher from "./Inventory/Transaction/PayRecptJrnlCntr/AddVoucher.jsx";
// import SaleOrderList from "./Inventory/Transaction/Sale/SaleOrderList.jsx";
import SmsTemplate1 from "./Inventory/Utilities/SMSMaster/SmsTemplate1.jsx";
import Login from "./Inventory/LogInPage/Login.jsx";
import MiscellaneousMasterList from "./Inventory/Master/AccountMaster/MiscellaneousMasterList.jsx";

import DeliveryTimeSlotSettingList from "./Restaurant/Orders/DeliveryTimeSlotSettingList.jsx";
// import MyContext from "./Inventory/MyContext.jsx";

import RecipeMaster from "./Inventory/Master/Userdetails/RecipeMaster.jsx";
import AddRecipe from "./Inventory/Master/Userdetails/AddRecipe.jsx";
import ClinicMaster from "./Inventory/Transaction/EyeCheckUp/ClinicMaster.jsx";
import EyeCheckUp from "./Inventory/Transaction/EyeCheckUp/EyeCheckUp.jsx";
import EyeCheckUpList from "./Inventory/Transaction/EyeCheckUp/EyeCheckUpList.jsx";
import PatientMaster from "./Inventory/Transaction/EyeCheckUp/PatientMaster.jsx";
// import PurchaseReturn from "./Inventory/Transaction/PurchaseReturn/PurchaseReturn.jsx";
// import PurchaseReturnList from "./Inventory/Transaction/PurchaseReturn/PurchaseReturnList.jsx";
//import MasterSetting from "./Payroll/Master/MasterSetting.jsx";
import AttributeField from "./Inventory/Master/MasterSetting/AttributeField.jsx";
import LoginOrder from './Restaurant/LoginOrder.jsx'
import MasterSetting from "./Inventory/Master/MasterSettingPage/MasterSetting.jsx";

import APIConfigurationMaster from "./Inventory/Utilities/APIConfigurationMaster/APIConfigurationMaster.jsx";
import APIConfigurationMasterList from "./Inventory/Utilities/APIConfigurationMaster/APIConfigurationMasterList.jsx";

import KotPrintPage from './Restaurant/KotManager/KotPrintPage.jsx'
import MultipleKotPrintPage from "./Restaurant/KotManager/MultipleKotPrintPage.jsx";
import FeedBackMaster from "./Restaurant/Orders/FeedbackSetting.jsx"
import CancellationReport from "./Restaurant/Orders/CancellationReceiverAnalysis";
import CancellationReportModal from "./Restaurant/Orders/ModalCancellationAnalysisDetail";
import OrderTakerBillPrint from "./Restaurant/OrderTaker/OrderTakerBillPrint.jsx";
import AddUpdateFormName from "./Inventory/Master/AddUpdateFormName.jsx";
// import AddUpdateFormName3 from "./Inventory/Master/AddUpdateFormName.jsx";
import PaymentReceivedDetail from "./Restaurant/Orders/PaymentReceivedDetail.jsx";
import OrderReport from "./Restaurant/Orders/OrderReport.jsx";
import Salt from "./Inventory/Master/ItemRecipeMaster/Salt.jsx";
import BillPrint from "./Restaurant/Orders/BillPrint.jsx";

import Request from "./Restaurant/Request.jsx";
import FeedbackReport from "./Restaurant/Orders/FeedbackReport.jsx"
import CHEFList from "./Restaurant/Chef/ChefList.jsx";
import ChefDetailPage from "./Restaurant/Chef/ChefDetailPage.jsx";

import DeliveryBoyTrackingReport from "./Restaurant/Delivery/DeliveryBoyTrackingReport.jsx";
import TripWiseDeliveryBoyTrackingReport from "./Restaurant/Delivery/TripWiseDelTrackingReport.jsx";

import ShopBanner from "./ShopBanner/Shop Banner and Image/ShopBanner.jsx";
import MainShopBanner from "./ShopBanner/Page4/MainShopBanner.jsx"
import HomeEyeTestSetting from "./MainSetting/MainSetting.jsx";
import DeliveryPointName from "./Inventory/Master/Userdetails/DeliveryPointName.jsx";
import LensMaster from "../src/Inventory/Master/LensMaster.jsx";
import IncentiveMaster from "./Inventory/Master/Userdetails/IncentiveMaster.jsx";
import ServiceTypeMaster from "./Inventory/Master/Userdetails/ServiceTypeMaster.jsx";
import HomeDeliveryPickupList from "./Restaurant/Orders/HomeDeliveryPickupList.jsx";
import FeedbackItemDepartmentWiseReport from "./Restaurant/Orders/FeedbackItemDepartmentWiseReport.jsx";
import HomePage from "./WebsiteTemplate/Pages/HomePage.jsx";
import ProductStock_Master from "./Inventory/Master/InvertoryMaster/ProductStock_Master.jsx";
import Home from "./WebsiteTemplate/WebsitePages/Home.jsx";
import Response from "./Restaurant/Response.jsx";
import ReportDesigner from "./WebsiteTemplate/WebsitePages/NewTemplatepage.jsx";
import PreviewTemplate from "./WebsiteTemplate/PreviewTemplate.jsx";
import Welcome from "./Inventory/Dashboard/Welcome.jsx";
import ReportDesign from "./Restaurant/Orders/ReportDesign.jsx";
import KotOrderSummary from "./Restaurant/Orders/KotOrderSummary.jsx";
import ItemIncentiveMaster from "./Restaurant/Orders/ItemIncentiveMaster.jsx";
import ItemIncentiveReport from "./Restaurant/Orders/ItemIncentiveReport.jsx";

import TransactionTypeSetting from "./Inventory/Master/TransactionTypeSetting/TransactionTypeSetting.jsx";
import TransactionList from "./Inventory/Transaction/Sale/TranscationList.jsx";
import SaleList from "./Inventory/Transaction/Sale/SaleList.jsx";

const TemplateOne = () => {

    return (
        <div>
            {/* <NavBar /> */}
            <Routes>
                <Route path="/" element={<LogInPanel />} />
                <Route path="/LogInPanel" element={<LogInPanel />} />
                <Route path="/MasterSetting" element={<MasterSetting />} />
                <Route path="/RazorpayCheckout" element={<RazorpayCheckout />} />
                {/* <Route path="/MasterSettingPage" element={<MasterSettingPage />} /> */}
                <Route path="/AttributeField" element={<AttributeField />} />
                <Route path="/LensMaster/:id" element={<LensMaster />} />
                <Route path="/Attendance" element={<Attendance />} />
                <Route path="/ShiftMaster" element={<ShiftMaster />} />
                <Route path="/LeaveMaster" element={<LeaveMaster />} />
                <Route path="/MonthWiseAttendance" element={<MonthWiseAttendance />} />
                <Route path="/LeaveMasterList" element={<LeaveMasterList />} />
                <Route path="/ShiftMasterList" element={<ShiftMasterList />} />
                <Route path="/TransactionList" element={<TransactionList />} />
                <Route path="/ProfessionalMasterList" element={<ProfessionalMasterList />} />
                <Route path="/ProfessionalMaster" element={<ProfessionalMaster />} />
                <Route path="/SalaryMasterList" element={<SalaryMasterList />} />
                <Route path="/SalaryMaster" element={<SalaryMaster />} />
                {/* <Route path="/MasterSetting" element={<MasterSetting />} /> */}
                {/* <Route path="/GradeMaster" element={<GradeMaster />} />
                <Route path="/GradeMasterList" element={<GradeMasterList />} /> */}
                <Route path="/LoginMaster" element={<Login />} />
                <Route path="/LoginTypeMaster" element={<LoginTypeMaster />} />
                <Route path="/LogInPanel" element={<LogInPanel />} />
                <Route path="/OtpVerify" element={<OtpVerify />} />
                <Route path="/CreateBusiness" element={<CreateBusiness />} />
                <Route path="/SaleList" element={<SaleList />} />
                <Route path="/SaleInvoice" element={<SaleInvoice />} />
                <Route path="/AccountGroupMasterList" element={<AccountGroupMasterList />} />
                <Route path="/AgentMasterList" element={<AgentMasterList />} />
                <Route path="/AccountGroupMaster" element={<AccountGroupMaster />} />
                <Route path="/MiscellaneousMaster" element={<MiscellaneousMaster />} />
                <Route path="/MiscellaneousMasterList" element={<MiscellaneousMasterList />} />
                <Route path="/ItemGroupMaster" element={<ItemGroupMaster />} />
                <Route path="/ItemGroupMaster1" element={<ItemGroupMaster1 />} />
                {/* <Route path="/ModifyCompany" element={<ModifyCompany />} /> */}
                <Route path="/ModifyCompany1" element={<ModifyCompany1 />} />
                {/* <Route path="/CheckboxWithInput" element={<CheckboxWithInput />} /> */}
                <Route path="/AccountMaster" element={<AccountMaster />} />
                <Route path="/AccountMasterList" element={<AccountMasterList />} />
                <Route path="/LensRemarkMaster" element={<LensRemarkMaster />} />
                <Route path="/DateTime" element={<DateTime />} />
                <Route path="/ImagePreview" element={<ImagePreview />} />
                <Route path="/AccountMasterview" element={<AccountMasterview />} />
                <Route path="/Savebutton" element={<Savebutton />} />
                <Route path="/ItemGroupMasterList" element={<ItemGroupMasterList />} />
                <Route path="/ItemMaster" element={< ItemMaster />} />
                {/* <Route path="/ItemMaster1" element={< ItemMaster1 />} /> */}
                <Route path="/ItemMasterList" element={< ItemMasterList />} />
                <Route path="/AgentMaster" element={<AgentMaster />} />
                <Route path="/StationMaster" element={<StationMaster />} />
                <Route path="/AccountCategoryMasterList" element={<AccountCategoryMasterList />} />
                <Route path="/TransportMasterList" element={<TransportMasterList />} />
                <Route path="UnitMasterList" element={< UnitMasterList />} />
                <Route path="/UnitConversionMasterList" element={<UnitConversionMasterList />} />
                <Route path="/UnitMaster" element={<UnitMaster />} />
                <Route path="/UnitConversionMaster" element={<UnitConversionMaster />} />
                <Route path="/GodownMasterList" element={<GodownMasterList />} />
                <Route path="/GodownMaster" element={<GodownMaster />} />
                <Route path="/LensRemarkMasterList" element={<LensRemarkMasterList />} />

                <Route path="/LensRangePriceMasterList" element={<LensRangePriceMasterList />} />
                <Route path="/LensRangePriceMaster" element={<LensRangePriceMaster />} />
                <Route path="/AccountCategoryMaster" element={<AccountCategoryMaster />} />
                {/* <Route path="/ItemMasterForPortal" element={<ItemMasterForPortal />} /> */}
                <Route path="/StationMasterList" element={<StationMasterList />} />
                <Route path="/ItemAttributeList" element={<ItemAttributeList />} />
                <Route path="/ProductList" element={<ProductList />} />
                <Route path="/BatchWiseModalBox" element={<BatchWiseModalBox />} />
                <Route path="/TableMasterList" element={<TableMasterList />} />
                <Route path="/TableGroupList" element={<TableGroupList />} />
                <Route path="/TableAllocation" element={<TableAllocation />} />

                <Route path="/TaxCategoryList" element={<TaxCategoryList />} />
                <Route path="/TaxCategory" element={<TaxCategory />} />
                <Route path="/SundryMasterList" element={<SundryMasterList />} />
                <Route path="/SundryMaster" element={<SundryMaster />} />
                <Route path="/StatusMasterList" element={<StatusMasterList />} />
                <Route path="/StatusMaster" element={<StatusMaster />} />
                <Route path="/LoyaltyMasterSetting" element={<LoyaltyMasterSetting />} />
                <Route path="/BillTypesList" element={<BillTypesList />} />
                {/* <Route path="/BillType2" element={<BillType2 />} /> */}
                <Route path="/BillType" element={<BillType />} />
                <Route path="/PageTabs" element={<PageTabs />} />
                <Route path="/NewTable" element={<NewTable />} />

                <Route path="/TransportMaster" element={<TransportMaster />} />
                <Route path="/SaleInvoice2" element={<SaleInvoice2 />} />
                {/* <Route path="/SaleInvoice3" element={<SaleInvoice3 />} /> */}
                <Route path="/SaleVoucherList" element={<SaleVoucherList />} />

                <Route path="/UserMasterList" element={<UserMasterList />} />
                <Route path="/UserAuthentication" element={<UserAuthentication />} />
                <Route path="/UserMaster" element={<UserMaster />} />

                {/*********************{Invetory Master}*********************/}
                <Route path="/SaleInvoice" element={<SaleInvoice />} />
                <Route path="/PosInvoice" element={<PosInvoice />} />
                <Route path="/OrderView" element={<OrderView />} />
                <Route path="/DineIn" element={<DineIn />} />
                <Route path="/KotOrderList" element={<KotOrderList />} />
                {/* <Route path="/OptWholeSaleInvoice" element={<OptWholeSaleInvoice />} /> */}
                <Route path="/Payment" element={<Payment />} />
                <Route path="/LensCreation" element={<LensCreationGroup />} />
                <Route path="/ProductPriceAccCatWise" element={<ProductPriceAccCatWise />} />

                {/***********************Kot Order Taker *******************************/}
                {/* <Route path="/KotOrderView" element={<KotOrderView />} /> */}

                {/* ********************** Add Route ******************************* */}
                <Route path="/SaleInvoice/:id" element={<SaleInvoice />} />
                <Route path="/TransactionTypeSetting" element={<TransactionTypeSetting />} />
                <Route path="/PosInvoice" element={<PosInvoice />} />
                <Route path="/OrderView" element={<OrderView />} />
                <Route path="/KotOrderList" element={<KotOrderList />} />
                {/* <Route path="/OptWholeSaleInvoice" element={<OptWholeSaleInvoice />} /> */}
                <Route path="/Payment" element={<Payment />} />
                <Route path="/LensCreation" element={<LensCreationGroup />} />

                <Route path="/SaleVoucherList" element={<SaleVoucherList />} />
                <Route path="/TraceLensStatus" element={<TraceLensStatus />} />

                {/*********************** Order Taker *******************************/}
                <Route path="/LoginPage" element={<LoginOrder />} />
                <Route path="/OrderTakerView" element={<OrderTakerView />} />
                <Route path="/OrderDetail" element={<OrderDetail />} />
                <Route path="/CustomerInfo" element={<CustomerInfo />} />
                <Route path="/PaymentGateWay" element={<PaymentGateway />} />
                <Route path="/CancelOrderedItem" element={<CancelOrderedItem />} />
                <Route path="/TableOrderAllocation" element={<TableOrderAllocation />} />
                <Route path="/OrderFeedback" element={<OrderFeedback />} />
                {/* <Route path="/ChefList" element={<List />} /> */}
                <Route path="/PickupList" element={<PickupList />} />
                <Route path="/PickupItemList" element={<PickupItemList />} />
                <Route path='/OrderList' element={<OrderList />} />
                <Route path="/KotOrderDetail" element={<OrderDetails />} />
                {/* <Route path="/Delivery" element={<Delivery />} /> */}
                {/* <Route path="/Variants" element={<Variants />} /> */}
                <Route path="/Variant2" element={<Variant2 />} />
                <Route path="/OptionalfieldList" element={<OptionalfieldList />} />
                <Route path="/VehicleMaster" element={<VehicleMaster />} />
                <Route path="/DeliveryIncentive" element={<DeliveryIncentive />} />
                <Route path="/LoginTypeMaster" element={<LoginTypeMaster />} />
                <Route path="/DesignationMaster" element={<DesignationMaster />} />
                <Route path="/XLS" element={<XLS />} />
                <Route path="/DepartmentMaster" element={<DepartmentMaster />} />
                <Route path="/UserActivity" element={<UserActivity />} />
                <Route path="/Table" element={<Table />} />
                <Route path="/PreviousOrder" element={<PreviousOrder />} />
                {/* <Route path="/" element={<ModifyCompany1 />} /> */}
                <Route path="/Piechart" element={<Piechart />} />
                <Route path="/MemberCard" element={<MemberCard />} />
                <Route path="/AddItemPage" element={<AddNewItem />} />
                <Route path="/ProductExchangeList" element={<ProductExchangeList />} />
                <Route path="/ProductExchange" element={<ProductExchange />} />
                <Route path="/ItemDepartmentMaster" element={<ItemDepartmentMaster />} />
                {/* <Route path="/SaleReturnVoucherList" element={<SaleReturnVoucherList />} /> */}
                {/* <Route path="/SaleReturn" element={<SaleReturn />} /> */}
                {/* <Route path="/PurchaseVoucherList" element={<PurchaseVoucherList />} /> */}
                {/* <Route path="/PurchaseOrder" element={<PurchaseOrder />} />
                <Route path="/PurchaseChallan" element={<PurchaseChallan />} />
                <Route path="/PurchaseInvoice" element={<PurchaseInvoice />} /> */}
                <Route path="/GodownTransfer" element={<GodownTransfer />} />
                <Route path="/StockIn" element={<StockIn />} />
                <Route path="/StockInList" element={<StockInList />} />
                <Route path="/StockOut" element={<StockOut />} />
                <Route path="/StockOutList" element={<StockOutList />} />
                <Route path="/StockRequest" element={<StockRequest />} />
                <Route path="/MealCourseMaster" element={<MealCourseMaster />} />
                {/* <Route path="/SaleOrder" element={<SaleOrder />} /> */}
                {/* <Route path="/SaleOrderList" element={<SaleOrderList />} /> */}
                {/* <Route path="/SaleChallan" element={<SaleChallan />} /> */}
                <Route path="/PickupList" element={<PickupList />} />
                <Route path="/PickupItemList" element={<PickupItemList />} />
                <Route path="/OrderList" element={<OrderList />} />
                {/* <Route path="/OrderDetails" element={<OrderDetails />} /> */}
                <Route path="/Delivery" element={<Delivery />} />
                <Route path="/DeliveryTimeSlotSetting" element={<DeliveryTimeSlotSetting />} />
                <Route path="/DeliveryTimeSlotSettingList" element={<DeliveryTimeSlotSettingList />} />
                <Route path="/DeliveryTimeSlotSetting" element={<DeliveryTimeSlotSetting />} />
                <Route path="/LogInMaster" element={<LogInMaster />} />
                <Route path="/OtpVerify" element={<OtpVerify />} />
                <Route path="/CreateBusiness" element={<CreateBusiness />} />
                <Route path="/LogInPanel" element={<LogInPanel />} />
                <Route path="/DamageAndShrinkage" element={<DamageAndShrinkage />} />
                <Route path="/DamageAndShrinkageList" element={<DamageAndShrinkageList />} />
                <Route path="/AddVoucher" element={<AddVoucher />} />
                <Route path="/AddVoucherList" element={<AddVoucherList />} />
                <Route path="/PayInPayOut" element={<PayInPayOut />} />
                <Route path="/SmsTemplate1" element={<SmsTemplate1 />} />
                <Route path="/RecipeMaster" element={<RecipeMaster />} />
                <Route path="/AddRecipe" element={<AddRecipe />} />
                <Route path="/ClinicMaster" element={<ClinicMaster />} />
                <Route path="/EyeCheckUp" element={<EyeCheckUp />} />
                <Route path="/EyeCheckUpList" element={<EyeCheckUpList />} />
                <Route path="/PatientMaster" element={<PatientMaster />} />
                {/* <Route path="/PurchaseReturn" element={<PurchaseReturn />} /> */}
                {/* <Route path="/PurchaseReturnList" element={<PurchaseReturnList />} /> */}
                <Route path="/MasterSetting" element={<MasterSetting />} />

                <Route path="/APIConfigurationMaster" element={<APIConfigurationMaster />} />
                <Route path="/APIConfigurationMasterList" element={<APIConfigurationMasterList />} />

                <Route path="/KotPrintPage" element={<KotPrintPage />} />
                <Route path="/MultipleKotPrintPage" element={<MultipleKotPrintPage />} />
                <Route path="/FeedBackMaster" element={<FeedBackMaster />} />
                <Route path="/CancellationReport" element={<CancellationReport />} />
                <Route path="/CancellationReportModal" element={<CancellationReportModal />} />
                <Route path="/OrderTakerBillPrint" element={<OrderTakerBillPrint />} />
                <Route path="/AddUpdateFormName" element={<AddUpdateFormName />} />
                <Route path="/PaymentReceivedDetail" element={<PaymentReceivedDetail />} />
                <Route path="/OrderReport" element={<OrderReport />} />
                <Route path="/Salt" element={<Salt />} />
                <Route path="/BillPrint" element={<BillPrint />} />
                <Route path="/Request" element={<Request />} />
                <Route path="/FeedbackReport" element={<FeedbackReport />} />
                <Route path="/ChefList" element={<CHEFList />} />
                <Route path="/ChefDetail" element={<ChefDetailPage />} />
                <Route path="/OrderWiseTrackingReport" element={<DeliveryBoyTrackingReport />} />
                <Route path="/TripWiseDelBoyTrackingReport" element={<TripWiseDeliveryBoyTrackingReport />} />
                <Route path="/shopbanner" element={<ShopBanner />} />
                <Route path="/mainshopbanner" element={<MainShopBanner />} />
                <Route path="/mainsetting" element={<HomeEyeTestSetting />} />
                <Route path="/DeliveryPointName" element={<DeliveryPointName />} />
                <Route path="/IncentiveMaster" element={<IncentiveMaster />} />
                <Route path="/ServiceTypeMaster" element={<ServiceTypeMaster />} />
                <Route path="/HomeDeliveryPickupList" element={<HomeDeliveryPickupList />} />
                <Route path="/FeedbackItemDepartmentWiseReport" element={<FeedbackItemDepartmentWiseReport />} />
                <Route path="/ProductStockMaster" element={<ProductStock_Master />} />
                <Route path="/KotOrderSummary" element={<KotOrderSummary />} />
                {/* ******************************************WebsiteTemplate********************************************* */}
                <Route path="/WebHomepage" element={<HomePage />} />
                <Route path="/Response" element={<Response />} />
                <Route path="/ReportDesigner" element={<ReportDesigner />} />
                <Route path="/previewTemplate/:id" element={<PreviewTemplate />} />
                <Route path="/Welcome" element={<Welcome />} />
                <Route path="/ReportDesign" element={<ReportDesign />} />
                <Route path="/ItemIncentiveMaster" element={<ItemIncentiveMaster />} />
                <Route path="/ItemIncentiveReport" element={<ItemIncentiveReport />} />
            </Routes>
        </div>
    );
};

export default TemplateOne;
