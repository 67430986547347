import React from 'react'
import ToolTipMaster from '../../Inventory/Props/ToolTipMaster'

const PosSlider = (props) => {

    const { toggleBottomSlider,
        openPaymentDetailsModal,
        isOpen,
        AllocatedTimeSlots,
        isSliderOpen_bottomUp,
        orderData,
        loginUserDetails,
        moment,
        handleFocus,
        handleChange,
        saveNoOfPerson,
        SavePaymentBtn,
        firstInputRef,
        inputValues1,
        handlePaymentChange,
        paymentDetails,
        openCouponModal, } = props
    return (
        // <div className="">
        <div className={`slider-container_pos ${isSliderOpen_bottomUp ? 'open' : ''}`}>
            <div className="space-between" style={{ width: "100%", backgroundColor: "#f8f8ff", padding: "1px 20px", borderRadius: "15px", position: "relative" }}>
                <div className='arrow-toggle_pos-box' onClick={toggleBottomSlider}>
                    {/* <div className="arrow-toggle_pos" > */}
                    {/* <span className={`arrow-pos ${isOpen ? 'up' : ''}`}>&#9660;</span> */}
                    {/* </div> */}
                    {isSliderOpen_bottomUp ? (
                        <i class="fa-solid fa-caret-down" style={{ fontSize: "24px" }}></i>
                    ) : (
                        <i class="fa-solid fa-caret-up" style={{ fontSize: "24px" }}></i>
                    )}
                </div>
                <div className="" style={{ width: "50%", fontSize: "15px" }}>
                    <div className="flex-start">
                        <label className="ques">Delivery&nbsp;Time&nbsp;:&nbsp;</label>
                        {/* <input className="smallSelectBox" style={{ border: "1px solid transparent " }} type="text" //type="time" 
                name="deliveryTimeId" onChange={handleChange} value={orderData.deliveryTimeId} /> */}
                        <select className="posSelectBox"
                            name="deliveryTimeId"
                            disabled={true}
                            value={orderData.deliveryTimeId}>
                            {(() => {
                                if (AllocatedTimeSlots && Array.isArray(AllocatedTimeSlots) && AllocatedTimeSlots.length > 0) {
                                    return AllocatedTimeSlots.map((data, index3) => (
                                        <option key={index3} value={data.id}>
                                            {/* {convertTo12HourFormat(data.availableFrom)} to {convertTo12HourFormat(data.availableTo)} */}
                                            {data.name}
                                        </option>
                                    ));
                                } else {
                                    return <option value=""></option>;
                                }
                            })()}
                        </select>
                    </div>
                    <div className="flex-start">
                        <label className="ques">Order Status : &nbsp;</label>
                        <label type="text" value="" name="overallOrderStatus">{orderData.overallOrderStatus}</label>
                        {/* <select className="smallSelectBox" value={orderData.overallOrderStatus} onChange={handleChange} name="overallOrderStatus">
                <option value="Pending">Pending</option>
                <option value="Delivered">Delivered</option>
                <option value="Confirmed">Confirmed</option>
                </select> */}
                    </div>
                    <div className="flex-start">
                        <label className="ques">Payment Status : &nbsp;</label>
                        <label value="" name="paymentStatus">{orderData.paymentStatus}</label>
                        {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                            && (
                                <i class="fa-regular fa-credit-card leftSideIcon" onClick={() => openPaymentDetailsModal()} style={{ marginLeft: "5px" }}></i>
                            )
                        }
                    </div>
                    <div className="flex-start">
                        <label className="ques">Kot Manager : &nbsp;</label>
                        <label className="ans">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                    </div>
                    <div className="flex-start">
                        <label className="ques">Confirm&nbsp;On&nbsp;:&nbsp;</label>
                        {/* <input type="text" value={currentDate} className="smallSelectBox" style={{ border: "1px solid transparent" }} /> */}
                        <input type="text" value={moment.utc(orderData.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} className="smallSelectBox" style={{ border: "1px solid transparent" }} />
                    </div>
                    <div className="flex-start">
                        <label className="ques">Confirm By : &nbsp;</label>
                        <label className="ans">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                    </div>
                    <div className="flex">
                        {/* <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "25%" }}>
                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                <label id="input1" className="labelEffect1" style={{ backgroundColor: "transparent" }}>Bill&nbsp;Split(Person) </label>
                            </div> */}
                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "40%" }}>
                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                name="noOfAdult" onFocus={(e) => handleFocus(e)} onChange={handleChange} value={orderData.noOfAdult} required />
                            <label id="input1" className="labelEffect1" style={{ backgroundColor: "transparent" }}>No&nbsp;of&nbsp;Adult</label>
                        </div>
                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "40%" }}>
                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                value={orderData.noOfChild} onFocus={(e) => handleFocus(e)} onChange={handleChange} name="noOfChild" required />
                            <label id="input1" className="labelEffect1" style={{ backgroundColor: "transparent" }} >No&nbsp;of&nbsp;Child</label>
                        </div>
                        {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                            && (
                                // <i class="fa-solid fa-floppy-disk mt-2" onClick={() => saveNoOfPerson()} style={{ marginLeft: "5px" }}></i>
                                <ToolTipMaster NoOfPerson={true} saveNoOfPerson={saveNoOfPerson} />
                            )
                        }
                    </div>
                </div>
                <div style={{ width: "45%", fontSize: "14px" }}>
                    <div className="flex-end mt-1">
                        <label className="ques">Loyality &nbsp;</label>
                        <div className="flex-end" style={{ width: "50%" }}>
                            <div className="image-input-container" style={{ width: "100%" }}>
                                <input style={{ height: "20px" }} type="text" placeholder="" className=" modify_im_input12 mode_limit mode_category" />
                                <button className=" apply-button input-image" style={{ width: "30%" }}>Use</button> {/*onClick={handleApplyCoupon}*/}
                            </div>
                        </div>
                    </div>
                    <div className="flex-end mt-1">
                        <label className="ques">Coupon &nbsp; </label>
                        <div className="flex-end" style={{ width: "50%" }}>
                            <div className="image-input-container" style={{ width: "100%" }}>
                                <input style={{ height: "20px" }} type="text" //value={couponCode} 
                                    placeholder="Coupon" value={orderData.couponCode} onChange={handleChange} name="couponCode"
                                    className=" modify_im_input12 mode_limit mode_category" />
                                <button className=" apply-button input-image" onClick={openCouponModal} style={{ width: "30%" }}>View</button> {/*onClick={handleApplyCoupon}*/}
                            </div>
                        </div>
                    </div>
                    <div className="flex-end mt-1">
                        <label className="ques">Discount &nbsp; </label>
                        <div className="flex-end" style={{ width: "50%" }}>
                            <div className="image-input-container" style={{ width: "100%" }}>
                                <input style={{ height: "20px" }} type="text" className=" modify_im_input12 mode_limit mode_category" />
                            </div>
                        </div>
                    </div>
                    {paymentDetails && paymentDetails.filter(item => item.display == true)
                        .map((data, index) => (
                            <div key={index} className="flex-end mt-1">
                                <label className="ques">{data.accountTitle} &nbsp;</label>
                                <div className="flex-end" style={{ width: "50%" }}>
                                    <div className="image-input-container" style={{ width: "100%" }}>
                                        <input
                                            style={{ height: "20px" }}
                                            type="number"
                                            className="modify_im_input12 mode_limit mode_category"
                                            onChange={(e) => handlePaymentChange(e, data.accountId)}
                                            value={inputValues1[data.accountId] || ''}
                                            ref={index === 0 ? firstInputRef : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {paymentDetails && paymentDetails.length > 0 && orderData.paymentStatus != "Done" && orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                            && (
                                <button className="pos_Small_SaveBtn mt-1" onClick={SavePaymentBtn}>Save</button>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="flex-end" style={{ marginRight: "15px" }}>
                <label className="ques" style={{ color: "darkred" }}>
                    Pay Amt : <span style={{ color: "black" }}>&nbsp;₹&nbsp;{orderData && orderData.paymentAmount || 0}</span>,
                    Due Amt : <span style={{ color: "black" }}>&nbsp;₹&nbsp;{
                        (orderData &&
                            Math.max(0, (orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount)))) || 0)
                    }, </span>
                    Cancel Amt : <span style={{ color: "black" }}>&nbsp;₹&nbsp;{orderData && orderData.o1CancelledAmt || 0}</span>
                    {/* Return Amt :- <span style={{ color: "black" }}>₹&nbsp;{
                                (orderData &&
                                    (orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount))) || 0)
                            }.</span> */}
                </label>
            </div>
        </div>
        // </div>
    )
}

export default PosSlider