import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import MyContext from "../../MyContext";
import TransactionTypeSettingApi from "../../../API/APIServices/TransactionTypeSetting";
import DataTable from "../TableMaster/DataTable";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import AccountGroup from "../../../API/APIServices/AccountGroup";
import Account from "../../../API/APIServices/Account";

function TransactionTypeSetting(props) {
  const [loading, setLoading] = useState(false);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    input1,
    input2,
    setAlertBoxDelete,
    alertBoxDelete,
    OpenAlertBoxDelete,
  } = useContext(MyContext);
  const [filteredAccount, setFilteredAccount] = useState([]);

  useEffect(() => {
    const filterAccountdata = async () => {
      const filteredData = [
        {
          fieldName: "parentGroupId",
          operatorName: "notequal",
          compareValue: 211,
        },
        {
          fieldName: "parentGroupId",
          operatorName: "notequal",
          compareValue: 212,
        },
      ];
      var data = await Account.getAccountWithFilter(filteredData);
      if (data.length > 0) {
        setFilteredAccount(data);
      }
    };
    filterAccountdata();
  }, []);
  /***********title***************/
  useState(() => {
    document.title = "Transaction Behaviour Setting";
  }, []);

  //**********{control modal box 1}*****************//

  //*****************{modal box}*****************//
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  /***********End Enter code*****************/

  // const input1Ref = useRef(null);
  // const input2Ref = useRef(null);

  // const handleKeyPress = (event, nextInputRef) => {
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         nextInputRef.current.focus();
  //     }
  // };

  /****************************************************** */

  const [errorMessages, setErrorMessages] = useState({
    transName: "",
    extraField1: "",
    extraField2: "",
    extraField3: "",
  });

  const [inputValues, setInputValues] = useState({
    transType: 0,
    transName: "string",
    transAccount: 0,
    enableStockCalculation: true,
    stockNature: true,
    transAccTaxAccAssetsAcc: true,
    transAccTaxAccAssetsAccSign: true,
    otherSettlementAcc: true,
    otherSettlementAccSign: true,
    cashFlow: true,
    cashFlowSign: true,
    settlementAmountNature: "string",
    enableLedgerCalculation: true,
    enableTax: true,
    taxType: true,
    price: 0,
    enableSellingPrice: true,
    sellingPrice: 0,
    invoiceType: 0,
  });

  useEffect(() => {
    console.log(inputValues);
  }, [inputValues]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      transName: name === "name" ? value : prevInputValues.transName,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find((item) => item.id === id);

    if (selectedItem) {
      setSelectedData((prevSelectedData) => {
        const isSelected = prevSelectedData.some((item) => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter((item) => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
  };

  const handleShare = (id) => {
    const listToSend = data1.find((item) => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      listToSend
    )}`;
    window.location.href = whatsappURL;
  };

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    console.log(inputValues);
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }
    setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    return true;
  };

  //  const fieldNames = ['transType', 'transName', 'amountNature', 'stockNature', 'addInLedger', 'extraField1', 'extraField2', 'extraField3', 'extraField4', 'extraField5'];
  const fieldNames = [
    "transType",
    "transName",
    "amountNature",
    "stockNature",
    "addInLedger",
    "extraField1",
    "extraField2",
    "extraField3",
    "extraField4",
    "extraField5",
  ];

  const SaveBtn = async () => {
    // debugger
    // let allValid = true;
    // fieldNames.forEach((fieldName) => {
    //     const isValid = validateInput(fieldName);
    //     if (!isValid) {
    //         allValid = false;
    //     }
    // });
    // if (allValid) {
    setLoading(true);
    if (Boolean(edit) === true) {
      const isSuccess =
        await TransactionTypeSettingApi.updateTransactionTypeSetting(
          inputValues
        );
      if (isSuccess) {
        fetchData();
        setEdit(false);
        OpenAlertBoxEdit();
        setLoading(false);
      } else {
        alert("error while updating");
      }
    } else {
      setLoading(true);
      const isSuccess =
        await TransactionTypeSettingApi.insertTransactionTypeSetting(
          inputValues
        );
      if (isSuccess) {
        fetchData();
        setLoading(false);
        OpenAlertBox();
      } else {
        alert("Error inserting data");
      }
    }
    fetchData();
    setInputValues({
      transType: 0,
      transName: "string",
      transAccount: 0,
      enableStockCalculation: true,
      stockNature: true,
      transAccTaxAccAssetsAcc: true,
      transAccTaxAccAssetsAccSign: true,
      otherSettlementAcc: true,
      otherSettlementAccSign: true,
      cashFlow: true,
      cashFlowSign: true,
      settlementAmountNature: "string",
      enableLedgerCalculation: true,
      enableTax: true,
      taxType: true,
      price: 0,
      enableSellingPrice: true,
      sellingPrice: 0,
      invoiceType: 0,
    });
  };
  // }

  useEffect(() => {
    const transNameMapping = {
      12: "Sale",
      13: "Sale Return",
      14: "Purchase",
      15: "Purchase Return",
      71: "Order",
    };
    setInputValues((prevValues) => ({
      ...prevValues,
      transName: transNameMapping[prevValues.transType] || "",
    }));
  }, [inputValues.transType]);

  // ********************************************List Part ******************************

  const [data1, setData1] = useState([]);

  const columns = [
    { Header: <input type="checkbox" />, accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "Transaction Name", accessor: "transName" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    {
      Header: <input type="text" placeholder="Transaction Name" ref={input1} />,
      accessor: "transName",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const val =
        await TransactionTypeSettingApi.getAllTransactionTypeSetting();
      console.log(val.length);

      const newData = val.map((item, i) => {
        return {
          input: (
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(item.id)}
            />
          ),
          sno: i + 1,
          transName: item.transName,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => editLoginType(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteLoginType(item.id)}
              ></i>
              <i
                className="fa-brands fa-square-whatsapp TableWhatsappcon"
                onClick={() => handleShare(item.id)}
              ></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setData1(newData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [edit, setEdit] = useState(false);

  console.log(edit);

  const editLoginType = async (id) => {
    setEdit(true);
    const dataById =
      TransactionTypeSettingApi.getTransactionTypeSettingById(id);
    if (dataById) {
      try {
        const editData = await dataById;
        console.log("dataaaa" + editData);
        setInputValues(editData[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteLoginType = (id) => {
    setAlertBoxDelete(true);
    setDeleteId(id);
  };
  const DeleteLoginTypeConfirm = async () => {
    setLoading(true);
    try {
      const val = await TransactionTypeSettingApi.deleteTransactionTypeSetting(
        getdeleteId
      );
      if (Boolean(val) === true) {
        fetchData();
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  return (
    <div>
      <PageTabs />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Transaction Type Setting</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          SaveButton={SaveBtn}
          isBtnSetting={false}
          showModal={showModal}
        />
      </div>

      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1 item_scroll">
                    <div className="control_radius">
                      <div className="company_info-text-im depart_top">
                        Transaction Type Setting
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="transType"
                                value={inputValues.transType}
                                required
                              >
                                <option value="12">Sale</option>
                                <option value="13">Sale Return</option>
                                <option value="14">Purchase</option>
                                <option value="15">Purchase Return</option>
                                <option value="71">Order</option>
                              </select>
                              <label class="modify_p_text2">
                                Transaction Type
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="transAccount"
                                value={inputValues.transAccount}
                                required
                              >
                                {filteredAccount.map((Acc, index) => (
                                  <option value={Acc.id}>{Acc.name}</option>
                                ))}
                              </select>
                              <label class="modify_p_text2">
                                Transaction Account
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableStockCalculation"
                                value={inputValues.enableStockCalculation}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Enable Stock Calcultation
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="stockNature"
                                value={inputValues.stockNature}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">Stock Nature</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="transAccTaxAccAssetsAcc"
                                value={inputValues.transAccTaxAccAssetsAcc}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                TransAcc TaxAcc AssetsAcc
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="transAccTaxAccAssetsAccSign"
                                value={inputValues.transAccTaxAccAssetsAccSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Trans Acc Tax Acc Assets Acc Sign
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="otherSettlementAcc"
                                value={inputValues.otherSettlementAcc}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Other Settlement Acc
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="otherSettlementAccSign"
                                value={inputValues.otherSettlementAccSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Other Settlement Acc Sign
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="cashFlow"
                                value={inputValues.cashFlow}
                                onChange={handleInputChange}
                                required
                              >
                                {/* <option value="">Select Amount Nature</option> */}
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              {/* <label class="modify_lbl_text3">Shop</label> */}
                              <label class="modify_p_text2">Cash Flow</label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="cashFlowSign"
                                value={inputValues.cashFlowSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Cash Flow Nautre
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="settlementAmountNature"
                                value={inputValues.settlementAmountNature}
                                onChange={handleInputChange}
                                required
                              >
                                {/* <option value="">Select Amount Nature</option> */}
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              {/* <label class="modify_lbl_text3">Shop</label> */}
                              <label class="modify_p_text2">
                                Settlement Amount Nature
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableLedgerCalculation"
                                value={inputValues.enableLedgerCalculation}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Add In Ledger
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableTax"
                                value={inputValues.enableTax}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">Enable Tax</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="taxType"
                                value={inputValues.taxType}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">Tax Type</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableSellingPrice"
                                value={inputValues.enableSellingPrice}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Enable Selling Price
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="sellingPrice"
                                value={inputValues.sellingPrice}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">Purchase Price</option>
                                <option value="2">Sale Price</option>
                                <option value="3">MRP</option>
                              </select>
                              <label class="modify_p_text2">
                                Selling Price
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="price"
                                value={inputValues.price}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">Purchase Price</option>
                                <option value="2">Sale Price</option>
                                <option value="3">MRP</option>
                              </select>
                              <label class="modify_p_text2">Price</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="invoiceType"
                                value={inputValues.invoiceType}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">Stock Out</option>
                                <option value="2">Stock In</option>
                                <option value="3">Consume</option>
                                <option value="4">Production</option>
                              </select>
                              <label class="modify_p_text2">
                                Invoice Type Nature
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="company_control">
                        

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="extraField4"
                                value={inputValues.extraField4}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Transaction Account Nature
                              </label>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* ************************************* List ************************************** */}
                    <div className="control_radius">
                      <div className="company_info-text-im depart_top">
                        Login Type List
                      </div>
                      <div className="col-sm-12">
                        <div className="table-resposive">
                          <DataTable
                            filterData={filterData}
                            columns={columns}
                            data={data1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3">
                <i class="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteLoginTypeConfirm}
        />
      )}
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Transaction Type Setting is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Transaction Type Setting is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {/* {loading && <Loader />} */}
    </div>
  );
}

export default TransactionTypeSetting;
