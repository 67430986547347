import React, { useRef, useContext, useEffect, useState } from "react";
import PopforSelectingLens from "./Dropdown/PopforSelectingLens.jsx";
import Transaction from "../../API/APIServices/Transaction.js";
import AccountAPI from "../../API/APIServices/Account";

/************Lens Info box (shivendra 28062024)****************/

function LensInfoForInvoice(props) {
  const inputRefs = useRef([]);

  const {
    inputValues,
    activeTab1,
    setActiveTab1,
    handleTabClick1,
    mainPageInputRef,
    lensPageInputRef,
    ReferenceNumber,
    // filteredAccount,
    suggestionBoxRef,
    openAccountMasterModal,
    highlightedIndex,
    itemRefs,
    itemMasterRows,
  } = props;

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0); // To store selected value for custom alert
  const [selectedPopup, setSelectedPopup] = useState(false);
  const [nextIndexSaved, setNextIndexSaved] = useState(3);
  const [FocusedName, setFocusedName] = useState("");
  const [lensInfoInList, setLensInfoInList] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [PopUpSelectingLens, setPopUpSelectingLens] = useState(false);

  const [LensTypeNumber, setLensTypeNumber] = useState(1);

  let TransType = inputValues.TransType;
  let TransName = inputValues.TransName;
  /********************Tab*******************/

  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);
  const [tabcontent4, setTabContent4] = useState(false);
  const [tabcontent5, setTabContent5] = useState(false);
  const [tabcontent6, setTabContent6] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs3 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(true);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs4 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(true);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs5 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(true);
    setTabContent6(false);
  };
  const showtabs6 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(true);
  };

  const [EyeModal, setEyeModal] = useState(false);

  const OpenEyeModal = () => {
    setEyeModal(true);
  };
  const CloseEyeModal = () => {
    setEyeModal(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
    if (event.target.value === "Format1") {
      document.getElementById("LensFormat1").style.display = "block";
      document.getElementById("LensFormat2").style.display = "none";
    } else if (event.target.value === "Format2") {
      document.getElementById("LensFormat1").style.display = "none";
      document.getElementById("LensFormat2").style.display = "block";
    }
  };

  const [regularLens, setRegularLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    RItemId_d2: "",

    LItemId_d2: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",
    PRIZM_R: "",
    BASE_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
    PRIZM_L: "",
    BASE_L: "",
  });

  const [contactLens, setContactLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",
    BASE_R: "",
    DAI_R: "",
    REM_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
    BASE_L: "",
    DIA_L: "",
    REM_L: "",
  });

  const [trnsposeLens, setTrnsposeLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [ARLens, setARLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [IMLens, setIMLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [lensOtherInfo, setLensOtherInfo] = useState({
    PrintPwrType: "",
    PrintPwrEye: "",

    LensPrice: "",
    LensType: "",
    PrescribedBy: "",
    PartyId: "",
    PartyName: "",
    FrameName: "",
    LenseStatus: "",
    PrintLens: "",

    NvVal: "",
    NvDt: "",
    CleVal: "",
    CleDt: "",
    PdRL: "",
    PdR: "",
    PdL: "",
    DelivDay: "",
    DelivTime: "",
  });

  useEffect(() => {
    console.log(lensOtherInfo);
  }, [lensOtherInfo]);

  //---------------For Lens Power And Other Info Inpute Change-----------------------

  const handleFocus = (e, lensTypeNo) => {
    debugger;
    setLensTypeNumber(lensTypeNo);
    let nextIndex = inputRefs.current.indexOf(e.target);
    setNextIndexSaved(nextIndex);
    console.log(e.target.name);
    setFocusedName(e.target.name);
    e.preventDefault();

    // Optionally, you can also show a custom behavior here
  };

  useEffect(() => {
    console.log("selectedValue", selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    console.log("nextIndexSaved", nextIndexSaved);
  }, [nextIndexSaved]);

  useEffect(() => {
    console.log("FocusedName", FocusedName);
  }, [FocusedName]);

  // Handle click on a value
  const handleClick = (value, lensNo) => {
    // alert(lensType);
    // if (lensType == 1) {
    //   setLensTypeNumber(2);
    // } else if (lensType == 2) {
    //   setLensTypeNumber(3);
    // } else if (lensType == 3) {
    //   setLensTypeNumber(4);
    // } else {
    //   setLensTypeNumber(1);
    // }

    debugger;
    if (value) {
      setSelectedValue(value);
    } else {
      setSelectedValue("0");
      setFocusedName("");
    }
    if (value) {
      var index = 1;
      switch (index) {
        case 1:
          setRegularLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 2:
          setContactLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 3:
          setTrnsposeLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 4:
          setARLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 5:
          setIMLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        default:
          console.warn("Invalid lens index:", index);
          break;
      }
    }
    if (lensNo == 3) {
      const NxtIndex = nextIndexSaved + 2;
      setNextIndexSaved(NxtIndex);

      if (NxtIndex === 0) {
        NxtIndex = 2;
      }
      if (NxtIndex < inputRefs.current.length) {
        inputRefs.current[NxtIndex].focus();
      } else if (NxtIndex > 37) {
        mainPageInputRef.current.focus();
      }
    } else {
      const NxtIndex = nextIndexSaved + 1;
      setNextIndexSaved(NxtIndex);

      if (NxtIndex === 0) {
        NxtIndex = 2;
      }
      if (NxtIndex < inputRefs.current.length) {
        inputRefs.current[NxtIndex].focus();
      } else if (NxtIndex > 37) {
        mainPageInputRef.current.focus();
      }
    }

    setSelectedPopup(true);
    setTimeout(() => {
      setSelectedPopup(false);
    }, 1000);
  };

  const handleLensPwrChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "ITEM_RIGHT") {
      setIsDropdownOpen2(true);
    }
    if (name == "ITEM_LEFT") {
      setIsDropdownOpen3(true);
    }

    // Handle lens power change logic
    switch (index) {
      case 1:
        setRegularLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 2:
        setContactLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 3:
        setTrnsposeLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 4:
        setARLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 5:
        setIMLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      default:
        console.warn("Invalid lens index:", index);
        break;
    }
  };

  const handleLensOtherInfoChange = async (e) => {
    debugger;
    const { name, value, checked } = e.target;
    if (name == "PrintLens") {
      if (checked == true && lensOtherInfo.PrintLens == "") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      } else if (checked == true && value == lensOtherInfo.PrintLens) {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          [name]: "RL",
        }));
      } else {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          [name]: "RL",
        }));
      }
    }

    setLensOtherInfo((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name == "PartyName") {
      const filteredData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: value || "",
        },
        { fieldName: "MasterType", operatorName: "equal", compareValue: "2" },
      ];
      const SearchItem = await AccountAPI.getAccountWithFilter(filteredData);
      if (SearchItem) {
        setFilteredAccount(SearchItem);
      }
      setIsDropdownOpen1(true);
    }
  };

  const handleAccountSelection = (Id, AccData, name) => {
    setLensOtherInfo((prevValues) => ({
      ...prevValues, // Spread the previous state to retain other properties
      [name]: AccData.name || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
      PartyId: Id || "", // Set PartyId to `Id` or empty string if `Id` is falsy
    }));
    setIsDropdownOpen1(false);
  };

  const handleRightSelection = (Data, name) => {
    debugger;
    setRegularLens((prevValues) => ({
      ...prevValues, // Spread the previous state to retain other properties
      [name]: Data.productName || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
      RItemId_d2: Data.d2_Id || "", // Set PartyId to `Id` or empty string if `Id` is falsy
    }));
    setIsDropdownOpen2(false);
  };

  const handleLeftSelection = (Data, name) => {
    setRegularLens((prevValues) => ({
      ...prevValues, // Spread the previous state to retain other properties
      [name]: Data.productName || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
      LItemId_d2: Data.d2_Id || "", // Set PartyId to `Id` or empty string if `Id` is falsy
    }));
    setIsDropdownOpen3(false);
  };

  const openPopUpForLensSelection = (LensType) => {
    setPopUpSelectingLens(true);
    setLensTypeNumber(LensType);

    // Scroll the element with ID 'tabs-main-lens-selection' to the top of the screen
    const lensSelectionElement = document.getElementById(
      "tabs-main-lens-selection"
    );
    if (lensSelectionElement) {
      // Scroll the element into view
      lensSelectionElement.scrollIntoView({
        behavior: "smooth",
        block: "start", // Align the element at the top of the viewport
        inline: "nearest", // Horizontal alignment (default)
      });

      // After the element scrolls into view, adjust the scroll position by 100px
      window.setTimeout(() => {
        window.scrollTo({
          top:
            lensSelectionElement.getBoundingClientRect().top +
            window.scrollY -
            52,
          behavior: "smooth", // Optional: You can use smooth scrolling for this too
        });
      }, 300); // Delay to ensure scrollIntoView has been applied before adjusting
    }
  };

  // const changeLensType = (LensType) => {
  //   // setTimeout(() => {
  //   setLensTypeNumber(LensType);
  //   setPopUpSelectingLens(true);
  //   // }, 100);
  // };
  // useEffect(() => {
  //   changeLensType(LensTypeNumber);
  // }, [selectedValue, LensTypeNumber]);

  const closePopUpForLensSelection = () => {
    setPopUpSelectingLens(false);
  };

  const saveLensInfoInList = async (e) => {
    var LensSave = await Transaction.SetTransLensData(
      regularLens,
      contactLens,
      trnsposeLens,
      IMLens,
      ARLens,
      lensOtherInfo,
      ReferenceNumber
    );
    if (LensSave.isSuccess == true) {
      alert("lens information saved sucessfully");
      GetLensData();
    }

    // Update lensList with the new lens information
    // setLensInfoInList([...lensInfoInList, newLensInfo]);
  };

  const [LensCount, setLensCount] = useState([]);

  const GetLensData = async () => {
    var LensData = await Transaction.GetTransLensData(ReferenceNumber);
    debugger;
    if (LensData) {
      setLensCount(LensData.result);
    }
  };

  useEffect(() => {
    if (ReferenceNumber) {
      GetLensData();
    }
  }, [ReferenceNumber]);

  useEffect(() => {
    console.log(lensInfoInList);
  }, [lensInfoInList]);

  //---------------Focus on the next input field if Enter is pressed-----------------------

  const handleKeyDownLens = (e) => {
    if (e.key === "Enter") {
      let nextIndex = inputRefs.current.indexOf(e.target) + 1;
      setNextIndexSaved(nextIndex);
      if (nextIndex === 0) {
        nextIndex = 2;
      }
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      } else if (nextIndex > 37) {
        mainPageInputRef.current.focus();
      }
    }
  };

  const removeModalSuggestion = () => {
    setTimeout(() => {
      setIsDropdownOpen1(false);
      setIsDropdownOpen2(false);
      setIsDropdownOpen3(false);
    }, 100);
  };
  return (
    <div
      className="table-right-padding"
      style={{ backgroundColor: "white", borderLeft: "1px solid lightgrey" }}
    >
      <div className="tabs-main" id="tabs-main-lens-selection">
        <div id="tabsLensInfo" className="si-size-1">
          <div className="tab-btn-box1 tab-b2">
            <div
              className={`tab-b1 tab-1  ${activeTab1 === 1 ? "active1" : ""}`}
              id="firstTab"
              onClick={() => {
                handleTabClick1(1);
                showtabs1();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Lens
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 2 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(2);
                showtabs2();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Cont
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 3 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(3);
                showtabs3();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Trans
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 4 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(4);
                showtabs4();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                AR
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 5 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(5);
                showtabs5();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                IM
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 6 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(6);
                showtabs6();
              }}
            >
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Prevs
              </h6>
            </div>
          </div>

          <div className="tab-content-box1">
            {tabcontent1 && (
              <div className="tab-content-1">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div
                        style={{ alignItems: "center" }}
                        className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                      >
                        <div className="input-containerEffect mt-1 width-righteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_RIGHT"
                            value={regularLens.ITEM_RIGHT}
                            onChange={(e) => handleLensPwrChange(e, 1)}
                            onKeyDown={handleKeyDownLens}
                            ref={lensPageInputRef}
                            required
                            autoComplete="off"
                            onBlur={() => removeModalSuggestion()}
                          />
                          <label id="input1" className="labelEffect1">
                            Right
                          </label>

                          {itemMasterRows.length > 1 && isDropdownOpen2 && (
                            <ul
                              className="ItemmasterDuggestionsaleinvoice"
                              ref={suggestionBoxRef}
                              style={{ position: "absolute" }}
                            >
                              {itemMasterRows.map((Data, index) => (
                                <>
                                  {Data.productName && (
                                    <li
                                      key={index}
                                      ref={(el) =>
                                        (itemRefs.current[index] = el)
                                      }
                                      onClick={() =>
                                        handleRightSelection(Data, "ITEM_RIGHT")
                                      }
                                      style={{
                                        // padding: "2px 5px",
                                        // cursor: "pointer",
                                        // borderBottom: "1px solid #ccc",
                                        backgroundColor:
                                          highlightedIndex === index
                                            ? "#e6f7ff"
                                            : "transparent",
                                      }}
                                    >
                                      {Data.productName}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="input-containerEffect mt-1 width-lefteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_LEFT"
                            value={regularLens.ITEM_LEFT}
                            onChange={(e) => handleLensPwrChange(e, 1)}
                            onKeyDown={handleKeyDownLens}
                            ref={(el) => (inputRefs.current[2] = el)}
                            required
                            autoComplete="off"
                            onBlur={() => removeModalSuggestion()}
                          />
                          <label id="input1" className="labelEffect1">
                            Left
                          </label>
                          {isDropdownOpen3 && itemMasterRows.length > 1 && (
                            <ul
                              className="ItemmasterDuggestionsaleinvoice"
                              ref={suggestionBoxRef}
                              style={{ position: "absolute" }}
                            >
                              {itemMasterRows.map((Data, index) => (
                                <>
                                  {Data.productName && (
                                    <li
                                      key={index}
                                      ref={(el) =>
                                        (itemRefs.current[index] = el)
                                      }
                                      onClick={() =>
                                        handleLeftSelection(Data, "ITEM_LEFT")
                                      }
                                      style={{
                                        // padding: "2px 5px",
                                        // cursor: "pointer",
                                        // borderBottom: "1px solid #ccc",
                                        backgroundColor:
                                          highlightedIndex === index
                                            ? "#e6f7ff"
                                            : "transparent",
                                      }}
                                    >
                                      {Data.productName}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          )}
                        </div>
                        <i
                          onClick={OpenEyeModal}
                          id="step-2"
                          className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                        ></i>
                      </div>
                      {/******************* First Format ********************/}
                      <div id="LensFormat1" style={{ display: "block" }}>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                style={{ marginRight: "12px" }}
                                className="col-sm-4 "
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                value={regularLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[3] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_DV_R"
                                value={regularLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[4] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={regularLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[5] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[6] = el)}
                                required
                                // onBlur={() => changeLensType(2)}
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_DV_L"
                                value={regularLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[14] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_DV_L"
                                value={regularLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[15] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_DV_L"
                                value={regularLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[16] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="VN_DV_L"
                                value={regularLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[17] = el)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_NV_R"
                                value={regularLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[7] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_NV_R"
                                value={regularLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[8] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_NV_R"
                                value={regularLens.AXS_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[9] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="VN_NV_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[10] = el)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_NV_L"
                                value={regularLens.SPH_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[18] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_NV_L"
                                value={regularLens.CYL_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[19] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_NV_L"
                                value={regularLens.AXS_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[20] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="VN_NV_L"
                                value={regularLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[21] = el)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" ">
                              <label className="col-sm-4 ">Add</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 4)}
                                inputMode="none"
                                name="ADD_R"
                                value={regularLens.ADD_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[11] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(4)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                P.B
                              </label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="PRIZM_R"
                                value={regularLens.PRIZM_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[12] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="BASE_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.BASE_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[13] = el)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 4)}
                                inputMode="none"
                                name="ADD_L"
                                value={regularLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[22] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(4)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                P.B
                              </label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="PRIZM_L"
                                value={regularLens.PRIZM_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[23] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                // onBlur={() => closePopUpForLensSelection()}
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="BASE_L"
                                value={regularLens.BASE_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyDown={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[24] = el)}
                                required
                                onBlur={() => closePopUpForLensSelection()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/******************* Second Format ********************/}
                      <div id="LensFormat2" style={{ display: "none" }}>
                        <div className="input-box-SI-2">
                          <div className="input-box-m6">
                            <div
                              className=" space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              {/* <label className="col-sm-1"></label> */}
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                SPH
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                CYL
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                AXIS
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                V/N
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                Add
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="input-box-SI-2"
                          style={{ width: "100%" }}
                        >
                          <div className="input-box-m6">
                            <div
                              className="space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              <label className="col-sm-4 "> R</label>
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2">
                          <div className="input-box-m6">
                            <div
                              className="space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              <label className="col-sm-4 "> L</label>
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/********************/}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/********************************* For Cont *************************/}
            {tabcontent2 && (
              <div className="tab-content-2">
                <div className="LensOverflow">
                  <div className="input-box-top-head">
                    <div className="input-box-head-left1">
                      <div className="input-box-head-1">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={contactLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 2)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={contactLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 2)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={contactLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={contactLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={contactLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={contactLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={contactLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={contactLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_L"
                                value={contactLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={contactLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={contactLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                value={contactLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={contactLens.AXS_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_R"
                                style={{ marginRight: "12px" }}
                                value={contactLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={contactLens.SPH_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={contactLens.CYL_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={contactLens.AXS_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={contactLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                value={contactLens.ADD_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                B&D
                              </label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="BASE_R"
                                value={contactLens.BASE_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="DAI_R"
                                value={contactLens.DAI_R}
                                style={{ marginRight: "12px" }}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                value={contactLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                B&D
                              </label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="BASE_L"
                                value={contactLens.BASE_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="DIA_L"
                                value={contactLens.DIA_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Rem.</label>
                              <input
                                className="col-sm-5 table-input  BorderBottomsale"
                                type="text"
                                name="REM_R"
                                value={contactLens.REM_R}
                                style={{ marginRight: "12px" }}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                className="col-sm-5 table-input  BorderBottomsale"
                                type="text"
                                name="REM_L"
                                value={contactLens.REM_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/************************************ For Trans *********************************/}
            {tabcontent3 && (
              <div className="tab-content-3">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div className="">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={trnsposeLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={trnsposeLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                      </div>
                      <div
                        className="input-box-SI-2 padding-1 "
                        style={{ height: "22px", marginTop: "3px" }}
                      >
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">PWR</label>
                            <label className="col-sm-4 ">SPH</label>
                            <label className="col-sm-4 ">CYL</label>
                            <label className="col-sm-4 ">AXIS</label>
                            <label
                              className="col-sm-4 "
                              style={{ marginRight: "12px" }}
                            >
                              V/N
                            </label>
                            <label className="col-sm-4 ">SPH</label>
                            <label className="col-sm-4 ">CYL</label>
                            <label className="col-sm-4 ">AXIS</label>
                            <label className="col-sm-4 ">V/N</label>
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">D.V.</label>
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_DV_R"
                              value={trnsposeLens.SPH_DV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_DV_R"
                              value={trnsposeLens.CYL_DV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_DV_R"
                              value={trnsposeLens.AXS_DV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_DV_R"
                              style={{ marginRight: "12px" }}
                              value={trnsposeLens.VN_DV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />

                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_DV_L"
                              value={trnsposeLens.SPH_DV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_DV_L"
                              value={trnsposeLens.CYL_DV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_DV_L"
                              value={trnsposeLens.AXS_DV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_DV_L"
                              value={trnsposeLens.VN_DV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">N.V.</label>
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_NV_R"
                              value={trnsposeLens.SPH_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_NV_R"
                              value={trnsposeLens.CYL_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_NV_R"
                              value={trnsposeLens.AXS_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              style={{ marginRight: "12px" }}
                              name="VN_NV_R"
                              value={trnsposeLens.VN_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />

                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_NV_L"
                              value={trnsposeLens.SPH_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_NV_L"
                              value={trnsposeLens.CYL_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_NV_L"
                              value={trnsposeLens.AXS_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_NV_L"
                              value={trnsposeLens.VN_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className=" row-m1">
                            <label className="col-sm-4">Add</label>
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="ADD_R"
                              value={trnsposeLens.ADD_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                            <div
                              className="col-sm-4 "
                              style={{ marginRight: "12px" }}
                            ></div>
                            <input
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="ADD_L"
                              value={trnsposeLens.ADD_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*************************************** AR *******************************************/}
            {tabcontent4 && (
              <div className="tab-content-4">
                <div className="">
                  <div className="input-box-top-head">
                    <div className="input-box-head-left1">
                      <div className="input-box-head-1">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={ARLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 4)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={ARLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 4)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={ARLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={ARLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={ARLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={ARLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={ARLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={ARLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_L"
                                value={ARLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={ARLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={ARLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                value={ARLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={ARLens.AXS_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                style={{ marginRight: "12px" }}
                                name="VN_NV_R"
                                value={ARLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={ARLens.SPH_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={ARLens.CYL_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={ARLens.AXS_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={ARLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                value={ARLens.ADD_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              ></div>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                value={ARLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/***************************************** Im **********************************/}
            {tabcontent5 && (
              <div className="tab-content-1">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div
                        style={{ alignItems: "center" }}
                        className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                      >
                        <div className="input-containerEffect mt-1 width-righteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_RIGHT"
                            value={IMLens.ITEM_RIGHT}
                            onChange={(e) => handleLensPwrChange(e, 5)}
                            onClick={() => setIsDropdownOpen2(true)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Right
                          </label>
                        </div>
                        <div className="input-containerEffect mt-1 width-lefteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_LEFT"
                            value={IMLens.ITEM_LEFT}
                            onChange={(e) => handleLensPwrChange(e, 5)}
                            required
                            onClick={() => {
                              console.log("onClick triggered");
                              setIsDropdownOpen3(true);
                            }}
                          />
                          <label id="input1" className="labelEffect1">
                            Left
                          </label>
                        </div>
                        <i
                          onClick={OpenEyeModal}
                          id="step-2"
                          className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                        ></i>
                      </div>
                      {/**************************************/}
                      <div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={IMLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={IMLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={IMLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={IMLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={IMLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={IMLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_L"
                                value={IMLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={IMLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={IMLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                value={IMLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={IMLens.AXS_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                style={{ marginRight: "12px" }}
                                name="VN_NV_R"
                                value={IMLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />

                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={IMLens.SPH_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={IMLens.CYL_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={IMLens.AXS_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={IMLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                value={IMLens.ADD_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              ></div>
                              <input
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                value={IMLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/***************************************** Prevs **********************************/}
            {tabcontent6 && (
              <div className="tab-content-5">
                <div className="">
                  <div className="input-box-m8">
                    <table className="table-scrol1" style={{ width: "95%" }}>
                      <thead className="table-tbody">
                        <tr>
                          <th className="lensTableHeader" rowSpan="2">
                            SN.
                          </th>
                          <th className="lensTableHeader" rowSpan="2">
                            Vch Dtl
                          </th>
                          <th className="lensTableHeader" colSpan="4">
                            Right Lens
                          </th>
                          <th className="lensTableHeader" colSpan="4">
                            Left Lens
                          </th>
                        </tr>
                        <tr>
                          <th className="lensTableHeader">SPH</th>
                          <th className="lensTableHeader">CYL</th>
                          <th className="lensTableHeader">AXIS</th>
                          <th className="lensTableHeader">ADD</th>
                          <th className="lensTableHeader">SPH</th>
                          <th className="lensTableHeader">CYL</th>
                          <th className="lensTableHeader">AXIS</th>
                          <th className="lensTableHeader">ADD</th>
                        </tr>
                      </thead>
                      <tbody className="table-container-tbody"></tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/************************************************************************/}
        <div id="tabs si-size-2" className="si-size-2">
          <div
            className="tab-btn-box2 tab-b2"
            style={{ backgroundColor: "#5e709e" }}
          >
            <div
              className="radio-btn-1"
              style={{
                width: "100%",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {/* <div className="flex">
                <input
                  type="radio"
                  id="All"
                  className="radio-box-1 box-size1"
                  name="options"
                  value="All"
                />
                <span
                  For="All"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; All
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  id="DV"
                  className="radio-box-1 box-size1"
                  name="options"
                  value="DV"
                />
                <span
                  For="DV"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; DV
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="NV"
                  name="options"
                  value="NV"
                />
                <span
                  For="NV"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; NV
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="CL"
                  name="options"
                  value="CL"
                />
                <span
                  For="CL"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; CL
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="Trans"
                  name="options"
                  value="Trans"
                />
                <span
                  For="Trans"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; Trans
                </span>
              </div> */}

              {/* <label htmlFor="" style={{ color: "white" }}>
                Status for print :
              </label> */}

              <select
                name="LenseStatus"
                id=""
                className="LensSelectioninvoice"
                onChange={(e) => handleLensOtherInfoChange(e)}
              >
                <option value="">-- Status for print--</option>
                <option value="All">All</option>
                <option value="NV">NV</option>
                <option value="DV">DV</option>
                <option value="CL">CL</option>
                <option value="Trans">Trans</option>
              </select>

              <div className="flex">
                <input
                  type="checkbox"
                  className="radio-box-1 box-size1"
                  name="PrintLens"
                  value="R"
                  style={{ width: "16px" }}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                />
                <span
                  htmlFor="R" // Corrected `For` to `htmlFor`
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; R
                </span>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  className="radio-box-1 box-size1"
                  name="PrintLens"
                  value="L"
                  style={{ width: "16px" }}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                />
                <span
                  htmlFor="L" // Corrected `For` to `htmlFor`
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; L
                </span>
              </div>

              <select name="LenseStatus" id="" className="LensSelectioninvoice">
                <option value="">-- Saved Lenses --</option>
                {LensCount.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="space-evenly mobileFlex-direction">
            <div className="lensInfoLeft-box">
              <div
                className="input-containerEffect w-100"
                style={{ marginTop: "8px" }}
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="LensType"
                  value={lensOtherInfo.LensType}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[25] = el)}
                  required
                  onFocus={closePopUpForLensSelection}
                />
                <label id="input1" className="labelEffect1">
                  Lens Type
                </label>
              </div>
              <div
                className="input-containerEffect mt-2 w-100"
                // style={{ margin: "1%" }}
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="PrescribedBy"
                  value={lensOtherInfo.PrescribedBy}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[26] = el)}
                  required
                />
                <label id="input1" className="labelEffect1">
                  Prescribed By
                </label>
              </div>
              <div
                className="input-containerEffect mt-2 w-100"
                // style={{ margin: "1%" }}//
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="PartyName"
                  value={lensOtherInfo.PartyName}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[27] = el)}
                  required
                  autoComplete="off"
                  onBlur={() => removeModalSuggestion()}
                />
                <label id="input1" className="labelEffect1">
                  Party Name
                </label>

                {isDropdownOpen1 && (
                  <ul
                    className="ItemmasterDuggestionsaleinvoice"
                    ref={suggestionBoxRef}
                    style={{ position: "absolute" }}
                  >
                    {filteredAccount.map((Acc, index) => (
                      <li
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)}
                        onClick={() =>
                          handleAccountSelection(Acc.d2Id, Acc, "PartyName")
                        }
                        style={{
                          // padding: "2px 5px",
                          // cursor: "pointer",
                          // borderBottom: "1px solid #ccc",
                          backgroundColor:
                            highlightedIndex === index
                              ? "#e6f7ff"
                              : "transparent",
                        }}
                      >
                        {Acc.name}
                      </li>
                    ))}
                    <li
                      key={filteredAccount.length}
                      ref={(el) =>
                        (itemRefs.current[filteredAccount.length] = el)
                      }
                      className="addNew_btn_saleInvoice"
                      onClick={() => openAccountMasterModal("Add")}
                      style={{
                        borderBottom: "1px solid #ccc",
                        backgroundColor:
                          highlightedIndex === filteredAccount.length
                            ? "#e6f7ff"
                            : "#5e709e",
                      }}
                    >
                      Add New <i class="fa-solid fa-plus"></i>
                    </li>
                  </ul>
                )}
              </div>
              <div
                className="input-containerEffect mt-2 w-100"
                // style={{ margin: "1%" }}
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="FrameName"
                  value={lensOtherInfo.FrameName}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[28] = el)}
                  required
                />
                <label id="input1" className="labelEffect1">
                  Frame Name
                </label>
              </div>
            </div>

            <div className="lensInfoRight-box">
              <div className="input-box-m9 dis-flex mt-2">
                <span
                  className="label-box-si-12 sol1"
                  style={{ textAlign: "right" }}
                >
                  NV &nbsp; &nbsp;
                </span>

                <div className="input-box-m1" style={{ width: "30%" }}>
                  <select
                    className="modify_im_input mode_limit mode_category input-s1 box-size1 BorderBottomsale"
                    type="text"
                    name="NvVal"
                    value={lensOtherInfo.NvVal}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyDown={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[29] = el)}
                    required
                  >
                    <option Classname="form-control s-1" value="">
                      NA
                    </option>
                    <option Classname="form-control s-1" value="1w">
                      1W
                    </option>
                    <option Classname="form-control s-1" value="1m">
                      1M
                    </option>
                    <option Classname="form-control s-1" value="3m">
                      3M
                    </option>
                    <option Classname="form-control s-1" value="6m">
                      6M
                    </option>
                    <option Classname="form-control s-1" value="1y">
                      1Y
                    </option>
                  </select>
                </div>
                <input
                  className="modify_im_input mode_category input-s1 BorderBottomsale"
                  type="DATE"
                  style={{ width: "52%" }}
                  name="NvDt"
                  value={lensOtherInfo.NvDt}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[30] = el)}
                  required
                />
              </div>
              <div className="input-box-m9 dis-flex mt-2">
                <span
                  className="label-box-si-12 sol1"
                  style={{ textAlign: "right" }}
                >
                  CLE &nbsp; &nbsp;
                </span>
                <div className="input-box-m1" style={{ width: "30%" }}>
                  <select
                    className="modify_im_input mode_limit mode_category input-s1 box-size1 BorderBottomsale"
                    type="text"
                    name="CleVal"
                    value={lensOtherInfo.CleVal}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyDown={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[31] = el)}
                    required
                  >
                    <option Classname="form-control s-1" value="">
                      NA
                    </option>
                    <option Classname="form-control s-1" value="1w">
                      1W
                    </option>
                    <option Classname="form-control s-1" value="1m">
                      1M
                    </option>
                    <option Classname="form-control s-1" value="3m">
                      3M
                    </option>
                    <option Classname="form-control s-1" value="6m">
                      6M
                    </option>
                    <option Classname="form-control s-1" value="1y">
                      1Y
                    </option>
                  </select>
                </div>
                <input
                  className="modify_im_input mode_category input-s1 BorderBottomsale"
                  type="DATE"
                  style={{ width: "52%" }}
                  name="CleDt"
                  value={lensOtherInfo.CleDt}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyDown={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[32] = el)}
                  required
                />
              </div>

              <div className="input-box-m9 dis-flex mt-2">
                <div
                  className="flex-end sale-in-lens-mobile"
                  style={{ width: "100%" }}
                >
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdRL"
                      value={lensOtherInfo.PdRL}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyDown={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[33] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className=" labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD RL
                    </label>
                  </div>
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdR"
                      value={lensOtherInfo.PdR}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyDown={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[34] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className="  labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD R
                    </label>
                  </div>
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdL"
                      value={lensOtherInfo.PdL}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyDown={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[35] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className=" labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD L
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="flex-start"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  className="input-containerEffect w-30"
                  style={{ margin: "1%" }}
                >
                  <input
                    className="inputEffect modify_im_input input-s1 BorderBottomsale inDays"
                    type="number"
                    name="DelivDay"
                    value={lensOtherInfo.DelivDay}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyDown={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[36] = el)}
                    required
                  />
                  <label id="input1" className=" labelEffect1">
                    Deli.Days{" "}
                  </label>
                </div>
                <div
                  className="input-containerEffect w-30"
                  style={{ margin: "1%" }}
                >
                  <input
                    className="inputEffect modify_im_input input-s1 BorderBottomsale inTime"
                    type="time"
                    name="DelivTime"
                    value={lensOtherInfo.DelivTime}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyDown={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[37] = el)}
                    required
                  />
                  <label
                    id="input1"
                    className="labelEffect1"
                    style={{ fontSize: "12px", padding: "0px" }}
                  >
                    Time{" "}
                  </label>
                </div>
                <div
                  className="input-box-s11 input-height2"
                  style={{ width: "74px", justifyContent: "center" }}
                >
                  <div
                    className="flex-end mt-1"
                    style={{ position: "relative" }}
                  >
                    <i
                      className="fa-solid fa-square-plus"
                      onMouseEnter={() => setIsHovered9(true)}
                      onMouseLeave={() => setIsHovered9(false)}
                      onClick={saveLensInfoInList}
                      style={{ color: "darkgreen" }}
                    ></i>{" "}
                    &nbsp; &nbsp;
                    {isHovered9 && <div className="hover-text1">Add Lens</div>}
                    <br />
                    <i
                      className="fa-solid fa-rotate-left"
                      onMouseEnter={() => setIsHovered10(true)}
                      onMouseLeave={() => setIsHovered10(false)}
                      style={{ color: "#ff4163" }}
                    ></i>
                    {isHovered10 && (
                      <div
                        className="hover-text1"
                        // style={{ top: "25%", right: "5%" }}
                      >
                        Reset
                      </div>
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*********************************/}
        </div>
      </div>
      <div className="">
        <div className="">
          <div></div>
        </div>
      </div>

      {PopUpSelectingLens && (
        <PopforSelectingLens
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
          lensType={LensTypeNumber}
          // changeLensType={changeLensType}
          closePopUpForLensSelection={closePopUpForLensSelection}
          handleClick={handleClick}
          selectedPopup={selectedPopup}
          setSelectedPopup={setSelectedPopup}
        />
      )}

      {/**************************** Eye/Lens Modal ********************/}
      {EyeModal && (
        <div className="modal-back">
          <div
            className="partyDetail-modal"
            style={{ height: "auto", width: "22%" }}
          >
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label style={{ color: "white" }}>Select the format</label>
                <label
                  className="closeBtnhover "
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={CloseEyeModal}
                >
                  x
                </label>
              </div>
              <div className="radiolabel_box">
                <label className="radio-label">
                  <input
                    type="radio"
                    value="Format1"
                    checked={selectedOption === "Format1"}
                    onChange={handleOptionChange}
                    className="large-radio"
                    style={{ boxShadow: "none" }}
                  />
                  &nbsp; With NV
                </label>

                <label className="radio-label">
                  <input
                    type="radio"
                    value="Format2"
                    checked={selectedOption === "Format2"}
                    onChange={handleOptionChange}
                    className="large-radio"
                    style={{ boxShadow: "none" }}
                  />
                  &nbsp; Without NV
                </label>
              </div>
              {/* <div className="flex-end">
                <button className="close-btn1" onClick={CloseEyeModal}>
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LensInfoForInvoice;
