
import React, { useEffect, useState, useRef, useContext } from "react";
import MyContext from "../../../Inventory/MyContext";
import throttle from "lodash.throttle";
// import { useMyContext } from "../Components/MyContext";

const TextBox = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [openEditIcon, setOpenEditIcon] = useState(false);
  const [getInputTxtValue, setInputTxtValue] = useState([]);
  const { setDroppedItems, setTemplateData, handleContentChange, droppedItems, stylesArray, setStylesArray } = useContext(MyContext);
  const displayRef = useRef(null);

  console.log("getInputTxtValue", getInputTxtValue);

  let id = props.keyValueId;

  let dbname = props.dbName;
  const [position, setPosition] = useState(
    { x: 250, y: 50 }
  );

  console.log("position", position);
  const [size, setSize] = useState(
    {
      width: 350,
      height: "min-content",
    }
  );
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });

  const [clickVtLn, setClickVtLn] = useState(false);

  const inputChangeValue = (val) => {
    const newValue = val;
    setInputTxtValue(newValue);
  };

  const handleTextClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    const matchingStyle = stylesArray.find((item) => item.id === id);
    if (matchingStyle) {
      setInputTxtValue(matchingStyle.content || "");
      const savedPosition = {
        x: matchingStyle.style?.positionX ?? 250,
        y: matchingStyle.style?.positionY ?? 50,
      };
      setPosition(savedPosition);
    } else {
      // setInputTxtValue("");
    }
  }, [id, stylesArray]);

  const handleBlur = () => {
    setIsEditing(false);
    try {
      setTimeout(() => {
        if (clickRef.current) {
          const updatedOuterHTML = clickRef.current.outerHTML;
          console.log('Updated outerHTML:', updatedOuterHTML);

          if (displayRef.current) {
            const textData = displayRef.current.innerHTML;
            console.log("Text Data:", textData);
            handleContentChange(textData, props.keyValueId);
          }

          const { x: updatedX, y: updatedY } = position;
          console.log('Position - X:', updatedX, 'Y:', updatedY);

          const prevItem = droppedItems.find(item => item.id === id);
          const prevX = prevItem ? prevItem.positionX : null;
          const prevY = prevItem ? prevItem.positionY : null;

          if (updatedX !== prevX || updatedY !== prevY) {
            console.log('Position changed, updating...');

            setDroppedItems((prevItems) => {
              const updatedItems = prevItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    textContents: updatedOuterHTML,
                    positionX: updatedX,
                    positionY: updatedY,
                  };
                }
                return item;
              });

              setTemplateData((prevState) => ({
                ...prevState,
                jsonStructure: updatedItems,
              }));

              return updatedItems;
            });

            setStylesArray((prevStyles) => {
              const updatedStyles = prevStyles.map((styleObj) =>
                styleObj.id === id
                  ? {
                    ...styleObj,
                    style: {
                      ...styleObj.style,
                      positionX: updatedX,
                      positionY: updatedY,
                    },
                  }
                  : styleObj
              );

              return updatedStyles;
            });
          } else {
            console.log('Position not changed, no update needed.');
          }
        }
      }, 1000);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (clickVtLn) {
        if (event.key === "ArrowUp") {
          // Prevent the default behavior of arrow keys
          event.preventDefault();

          // Move up by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - moveAmount,
          }));
        } else if (event.key === "ArrowDown") {
          event.preventDefault();
          // Move down by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + moveAmount,
          }));
        } else if (event.key === "ArrowLeft") {
          event.preventDefault();

          // Move left by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x - moveAmount,
          }));
        } else if (event.key === "ArrowRight") {
          event.preventDefault();

          // Move right by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + moveAmount,
          }));
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [clickVtLn]); // Listen to changes in clickVtLn

  const clickRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        setClickVtLn(false);
        setOpenEditIcon(false);
      }
    };

    const handleEnterKey = (event) => {
      if (clickVtLn && event.key === "Enter") {
        event.preventDefault();
        setClickVtLn(false);
        setOpenEditIcon(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [clickRef, clickVtLn]);

  // useEffect(() => {
  //   // Get styles for the desired ID
  //   localStorage.setItem("inputxtValue",JSON.stringify(getInputTxtValue))
  // }, [getInputTxtValue]);


  // Get styles for the desired ID
  const handleMouseDown = (e) => {
    if (e.button === 0) {
      e.preventDefault()
      // Left mouse button is pressed, start dragging
      const { clientX, clientY } = e;
      setIsDragging(true);
      setDragOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });
    } else if (e.button === 2) {
      e.preventDefault()
      // Right mouse button is clickVtLn, start resizing
      const { clientX, clientY } = e;
      setIsResizing(true);
      setResizeOffset({
        x: clientX - position.x - size.width,
        y: clientY - position.y - size.height,
      });
    }
  };

  const handleMouseMove = throttle((e) => {
    if (isDragging) {
      const { clientX, clientY } = e;
      const newPositionX = clientX - dragOffset.x;
      const newPositionY = clientY - dragOffset.y;

      setPosition({
        x: newPositionX,
        y: newPositionY,
      });
    } else if (isResizing) {
      const { clientX, clientY } = e;
      setSize({
        width: clientX - position.x - resizeOffset.x,
        height: clientY - position.y - resizeOffset.y,
      });
    }
  }, 16);

  const deletebuttonshow = () => {
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  const toggleBar = () => {
    deletebuttonshow()
    setClickVtLn(!clickVtLn);
    setOpenEditIcon(!openEditIcon);
    props.onComponentClick(props.keyValueId);
  };

  const handlePaste = async (event) => {
    // event.preventDefault();
    const value = localStorage.getItem("VariableName")

    if (value?.includes('@')) {
      inputChangeValue(value);
      localStorage.setItem("VariableName", "");
    } else {
      console.log("Not found copied data");
    }
  };

  return (
    <div id={props.keyValueId}
      className={`draggable-resizable-component-web-template fieldbox-toolpanel-web-template ${clickVtLn ? "bordered-web-template" : ""
        }`}
      style={{
        position: "relative",
        // minWidth: "max-content",
        // minHeight: "max-content",
        left: `${position.x / 14.5}%`,
        // top: `${position.y / 2}%`,
        // left: position.x,
        top: position.y,
        // width: size.width,
        // height: "30px",
        // cursor: isDragging ? "grabbing" : isResizing ? "nwse-resize" : "grab",
        padding: "1px",
        display: "flex",
        alignItems: "center",
        // transform: "translate(-25%, 0%)",
        width: "50%",
        // minHeight: "30px", 
        cursor: "text"
      }}
      ref={clickRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      onClick={toggleBar}
    >
      {/* <input
        placeholder="Write Your Text"
        className="textbox-editable"
        type="text"
        // value={getInputTxtValue.find((item) => item.id === id)?.value || ""}
        value={getInputTxtValue}
        onChange={inputChangeValue}
        onMouseDown={(e) => e.stopPropagation()}
        autoFocus={clickVtLn}
      /> */}

      {isEditing ? (
        <input
          placeholder="Write Your Text"
          className="textbox-editable"
          type="text"
          value={getInputTxtValue}
          onChange={(e) => inputChangeValue(e.target.value)}
          onBlur={handleBlur}
          style={{ width: '100%' }}
          onClick={handlePaste}
          autoFocus
        />
      ) : (
        <div
          ref={displayRef}
          className="component-web-template"
          onClick={handleTextClick}
          style={{ width: "100%", minHeight: "30px", cursor: "text" }}
        >
          {(getInputTxtValue && getInputTxtValue != "") ? getInputTxtValue : "Click to edit text..."}
        </div>
      )}

      <button
        className="component-delete-web-template"
        id={props.keyValueId + "delete"}
        onClick={() => props.handleRemoveItem(props.keyValueId)}
      >
        <i className="fa-solid fa-trash"></i>
      </button>

    </div>


  );
};
export default TextBox;





















// *****************************************************************************
// import React, { useEffect, useState, useRef, useContext } from "react";
// import MyContext from "../../../Inventory/MyContext";
// import throttle from "lodash.throttle";
// // import { useMyContext } from "../Components/MyContext";

// const TextBox = (props) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [isDragging, setIsDragging] = useState(false);
//   const [isResizing, setIsResizing] = useState(false);
//   const [openEditIcon, setOpenEditIcon] = useState(false);
//   const [getInputTxtValue, setInputTxtValue] = useState([]);
//   const { setDroppedItems, setTemplateData, handleContentChange, droppedItems, stylesArray, setStylesArray } = useContext(MyContext);
//   const displayRef = useRef(null);
//   console.log("getInputTxtValue", getInputTxtValue);
//   let id = props.keyValueId;

//   let dbname = props.dbName;
//   const [position, setPosition] = useState(
//     { x: 250, y: 50 }
//   );

//   console.log("position", position);
//   const [size, setSize] = useState(
//     {
//       width: 350,
//       height: "min-content",
//     }
//   );
//   const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
//   const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });


//   // const [clickVtLn, setClickVtLn] = useState(false);
//   const [clickVtLn, setClickVtLn] = useState(false);

//   // const inputChangeValue = (e) => {
//   //   const newValue = e.target.value;


//   //   const updatedInputTxtValue = [...getInputTxtValue];

//   //   // Find the index of the item with the matching ID in the array
//   //   const index = updatedInputTxtValue.findIndex((item) => item.id === id);

//   //   // If the item exists in the array, update its value
//   //   if (index !== -1) {
//   //     updatedInputTxtValue[index].value = newValue;
//   //   } else {
//   //     // If the item does not exist, create a new item and add it to the array
//   //     updatedInputTxtValue.push({ id: id, value: newValue });
//   //   }

//   //   // Update the state with the modified array
//   //   setInputTxtValue(updatedInputTxtValue);
//   // };

//   const inputChangeValue = (val) => {
//     const newValue = val;
//     setInputTxtValue(newValue);
//   };

//   const handleTextClick = () => {
//     setIsEditing(true);
//   };

//   useEffect(() => {
//     const matchingStyle = stylesArray.find((item) => item.id === id);

//     if (matchingStyle) {
//       debugger
//       setInputTxtValue(matchingStyle.content);
//       const savedPosition = {
//         x: matchingStyle.style?.positionX ?? 250,
//         y: matchingStyle.style?.positionY ?? 50,
//       };
//       setPosition(savedPosition);
//     } else {
//       // setInputTxtValue("");
//     }
//   }, [id, stylesArray]);

//   // const handleBlur = () => {
//   //   debugger
//   //   setIsEditing(false);
//   //   setTimeout(() => {
//   //     if (clickRef.current) {
//   //       // const updatedInputText = clickRef.current.value;


//   //       // handleContentChange(updatedInputText, id);

//   //       console.log(clickRef.current);
//   //       const updatedOuterHTML = clickRef.current.outerHTML;
//   //       console.log('Updated innerHTML:', updatedOuterHTML);
//   //       if (displayRef.current) {
//   //         const textData = displayRef.current.innerHTML;
//   //         console.log("Text Data:", textData);
//   //         handleContentChange(textData, props.keyValueId);
//   //       }
//   //       setDroppedItems((prevItems) => {
//   //         const updatedItems = prevItems.map((item) => {
//   //           if (item.id === id) {
//   //             return { ...item, textContents: updatedOuterHTML };
//   //           }
//   //           return item;
//   //         });


//   //         setTemplateData((prevState) => ({
//   //           ...prevState,
//   //           jsonStructure: updatedItems,
//   //         }));

//   //         return updatedItems;
//   //       });
//   //     }
//   //   }, 1000);
//   // };

//   // const handleBlur = () => {
//   //   debugger;
//   //   setIsEditing(false);

//   //   setTimeout(() => {
//   //     if (clickRef.current) {
//   //       const updatedOuterHTML = clickRef.current.outerHTML;
//   //       console.log('Updated outerHTML:', updatedOuterHTML);

//   //       // Save content (HTML)
//   //       if (displayRef.current) {
//   //         const textData = displayRef.current.innerHTML;
//   //         console.log("Text Data:", textData);
//   //         handleContentChange(textData, props.keyValueId);
//   //       }


//   //       const updatedX = clickRef.current.getBoundingClientRect().left;
//   //       const updatedY = clickRef.current.getBoundingClientRect().top;

//   //       console.log('Position - X:', updatedX, 'Y:', updatedY);


//   //       setDroppedItems((prevItems) => {
//   //         const updatedItems = prevItems.map((item) => {
//   //           if (item.id === id) {
//   //             return {
//   //               ...item,
//   //               textContents: updatedOuterHTML,
//   //               positionX: updatedX,
//   //               positionY: updatedY,
//   //             };
//   //           }
//   //           return item;
//   //         });

//   //         // Update the templateData state with new dropped items
//   //         setTemplateData((prevState) => ({
//   //           ...prevState,
//   //           jsonStructure: updatedItems,
//   //         }));

//   //         return updatedItems;
//   //       });


//   //       setStylesArray((prevStyles) => {
//   //         const updatedStyles = prevStyles.map((styleObj) =>
//   //           styleObj.id === id
//   //             ? {
//   //               ...styleObj,
//   //               style: {
//   //                 ...styleObj.style,
//   //                 positionX: updatedX,
//   //                 positionY: updatedY,
//   //               },
//   //             }
//   //             : styleObj
//   //         );

//   //         return updatedStyles;
//   //       });
//   //     }
//   //   }, 1000);
//   // };

//   const handleBlur = () => {
//     try {
//       setTimeout(() => {
//         setIsEditing(false);
//         if (clickRef.current) {
//           const updatedOuterHTML = clickRef.current.outerHTML;
//           console.log('Updated outerHTML:', updatedOuterHTML);

//           if (displayRef.current) {
//             const textData = displayRef.current.innerHTML;
//             console.log("Text Data:", textData);
//             handleContentChange(textData, props.keyValueId);
//           }

//           const { x: updatedX, y: updatedY } = position;
//           console.log('Position - X:', updatedX, 'Y:', updatedY);

//           const prevItem = droppedItems.find(item => item.id === id);
//           const prevX = prevItem ? prevItem.positionX : null;
//           const prevY = prevItem ? prevItem.positionY : null;

//           if (updatedX !== prevX || updatedY !== prevY) {
//             console.log('Position changed, updating...');

//             setDroppedItems((prevItems) => {
//               const updatedItems = prevItems.map((item) => {
//                 if (item.id === id) {
//                   return {
//                     ...item,
//                     textContents: updatedOuterHTML,
//                     positionX: updatedX,
//                     positionY: updatedY,
//                   };
//                 }
//                 return item;
//               });

//               setTemplateData((prevState) => ({
//                 ...prevState,
//                 jsonStructure: updatedItems,
//               }));

//               return updatedItems;
//             });

//             setStylesArray((prevStyles) => {
//               const updatedStyles = prevStyles.map((styleObj) =>
//                 styleObj.id === id
//                   ? {
//                     ...styleObj,
//                     style: {
//                       ...styleObj.style,
//                       positionX: updatedX,
//                       positionY: updatedY,
//                     },
//                   }
//                   : styleObj
//               );
//               return updatedStyles;
//             });
//           } else {
//             console.log('Position not changed, no update needed.');
//           }
//         }
//       }, 1000);
//     }
//     catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (clickVtLn) {
//         if (event.key === "ArrowUp") {
//           debugger
//           // Prevent the default behavior of arrow keys
//           event.preventDefault();

//           // Move up by 1 pixel or 10 pixels if the Control key is pressed
//           const moveAmount = event.ctrlKey ? 10 : 1;
//           setPosition((prevPosition) => ({
//             ...prevPosition,
//             y: prevPosition.y - moveAmount,
//           }));
//         } else if (event.key === "ArrowDown") {
//           event.preventDefault();
//           debugger

//           // Move down by 1 pixel or 10 pixels if the Control key is pressed
//           const moveAmount = event.ctrlKey ? 10 : 1;
//           setPosition((prevPosition) => ({
//             ...prevPosition,
//             y: prevPosition.y + moveAmount,
//           }));
//         } else if (event.key === "ArrowLeft") {
//           event.preventDefault();

//           // Move left by 1 pixel or 10 pixels if the Control key is pressed
//           const moveAmount = event.ctrlKey ? 10 : 1;
//           setPosition((prevPosition) => ({
//             ...prevPosition,
//             x: prevPosition.x - moveAmount,
//           }));
//         } else if (event.key === "ArrowRight") {
//           event.preventDefault();

//           // Move right by 1 pixel or 10 pixels if the Control key is pressed
//           const moveAmount = event.ctrlKey ? 10 : 1;
//           setPosition((prevPosition) => ({
//             ...prevPosition,
//             x: prevPosition.x + moveAmount,
//           }));
//         }
//       }
//     };

//     // Add the event listener when the component mounts
//     window.addEventListener("keydown", handleKeyDown);

//     return () => {
//       // Remove the event listener when the component unmounts
//       window.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [clickVtLn]); // Listen to changes in clickVtLn

//   const clickRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (clickRef.current && !clickRef.current.contains(event.target)) {
//         setClickVtLn(false);
//         setOpenEditIcon(false);
//       }
//     };

//     const handleEnterKey = (event) => {
//       if (clickVtLn && event.key === "Enter") {
//         event.preventDefault();
//         setClickVtLn(false);
//         setOpenEditIcon(false);
//       }
//     };
//     window.addEventListener("click", handleClickOutside);
//     window.addEventListener("keydown", handleEnterKey);

//     return () => {
//       window.removeEventListener("click", handleClickOutside);
//       window.removeEventListener("keydown", handleEnterKey);
//     };
//   }, [clickRef, clickVtLn]);

//   // useEffect(() => {
//   //   // Get styles for the desired ID
//   //   localStorage.setItem("inputxtValue",JSON.stringify(getInputTxtValue))
//   // }, [getInputTxtValue]);


//   // Get styles for the desired ID
//   const handleMouseDown = (e) => {
//     if (e.button === 0) {
//       e.preventDefault()
//       // Left mouse button is pressed, start dragging
//       const { clientX, clientY } = e;
//       setIsDragging(true);
//       setDragOffset({
//         x: clientX - position.x,
//         y: clientY - position.y,
//       });
//     } else if (e.button === 2) {
//       e.preventDefault()
//       // Right mouse button is clickVtLn, start resizing
//       const { clientX, clientY } = e;
//       setIsResizing(true);
//       setResizeOffset({
//         x: clientX - position.x - size.width,
//         y: clientY - position.y - size.height,
//       });
//     }
//   };

//   const handleMouseMove = throttle((e) => {
//     if (isDragging) {
//       const { clientX, clientY } = e;
//       const newPositionX = clientX - dragOffset.x;
//       const newPositionY = clientY - dragOffset.y;

//       setPosition({
//         x: newPositionX,
//         y: newPositionY,
//       });
//     } else if (isResizing) {
//       const { clientX, clientY } = e;
//       setSize({
//         width: clientX - position.x - resizeOffset.x,
//         height: clientY - position.y - resizeOffset.y,
//       });
//     }
//   }, 16);

//   const deletebuttonshow = () => {
//     const deleteButton = document.getElementById(props.keyValueId + "delete");
//     if (deleteButton) {
//       deleteButton.classList.toggle("column-one-delete-web-template");
//     }
//     // document.getElementById("column-one").classList.toggle("element-border");
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//     setIsResizing(false);
//   };

//   const toggleBar = () => {
//     deletebuttonshow()
//     setClickVtLn(!clickVtLn);
//     setOpenEditIcon(!openEditIcon);
//     props.onComponentClick(props.keyValueId);
//   };

//   const handlePaste = async (event) => {
//     // event.preventDefault();
//     const value = localStorage.getItem("VariableName")

//     if (value?.includes('@')) {
//       inputChangeValue(value);
//       localStorage.setItem("VariableName", "");
//     } else {
//       console.log("Not found copied data");
//     }
//   };

//   return (
//     <div
//       className={`draggable-resizable-component-web-template fieldbox-toolpanel-web-template ${clickVtLn ? "bordered-web-template" : ""
//         }`}
//       style={{
//         position: "absolute",
//         // minWidth: "max-content",
//         // minHeight: "max-content",
//         left: `${position.x / 14.5}%`,
//         // top: `${position.y / 2}%`,
//         // left: position.x,
//         top: position.y,
//         // width: size.width,
//         // height: "30px",
//         cursor: isDragging ? "grabbing" : isResizing ? "nwse-resize" : "grab",
//         padding: "10px",
//         display: "flex",
//         alignItems: "center",
//         // transform: "translate(-25%, 0%)",
//         width: "50%",
//         // minHeight: "30px",
//         // cursor: "text"
//       }}
//       ref={clickRef}
//       onMouseDown={handleMouseDown}
//       onMouseMove={handleMouseMove}
//       onMouseUp={handleMouseUp}
//       onDragStart={props.handleDrag}
//       onDrop={props.handleDrop}
//       onDragOver={props.dragover}
//       id={props.keyValueId}
//       onClick={toggleBar}
//     >
//       {/* <input
//         placeholder="Write Your Text"
//         className="textbox-editable"
//         type="text"
//         // value={getInputTxtValue.find((item) => item.id === id)?.value || ""}
//         value={getInputTxtValue}
//         onChange={inputChangeValue}
//         onMouseDown={(e) => e.stopPropagation()}
//         autoFocus={clickVtLn}
//       /> */}

//       {isEditing ? (
//         <input
//           placeholder={getInputTxtValue == "" ? "Write Your Text" : "null"}
//           className="textbox-editable"
//           type="text"
//           value={getInputTxtValue}
//           onChange={(e) => inputChangeValue(e.target.value)}
//           onBlur={handleBlur}
//           style={{ width: '100%' }}
//           onClick={handlePaste}
//           autoFocus
//         />
//       ) : (
//         <div
//           ref={displayRef}
//           className="component-web-template"
//           onClick={handleTextClick}
//           style={{ width: "100%", minHeight: "30px", cursor: "text" }}
//         >
//           {getInputTxtValue || "Click to edit text..."}
//         </div>
//       )}

//       <button
//         className="component-delete-web-template"
//         id={props.keyValueId + "delete"}
//         onClick={() => props.handleRemoveItem(props.keyValueId)}
//       >
//         <i className="fa-solid fa-trash"></i>
//       </button>

//     </div>


//   );
// };
// export default TextBox;



