// import React from "react";
// import "../Css/HomePage.css"; 

// const ShowStructure = () => {
//   const rotateicon = () => {
//     document.getElementById("arrow1").classList.toggle("rotateicon");
//     document.getElementById("structure-1-1").classList.toggle("displayblock");
//   };

//   const rotateicon1 = () => {
//     document.getElementById("arrow2").classList.toggle("rotateicon");
//     document.getElementById("structure-1-2").classList.toggle("displayblock");
//   };
//   const rotateicon2 = () => {
//     document.getElementById("arrow3").classList.toggle("rotateicon");
//     document.getElementById("structure-1-3").classList.toggle("displayblock");
//   };
//   const rotateicon3 = () => {
//     document.getElementById("arrow4").classList.toggle("rotateicon");
//     document.getElementById("structure-1-4").classList.toggle("displayblock");
//   };

//   return (
//     <div>
//       <div>
//         <div onClick={rotateicon} className="structure-1">
//           <i
//             id="arrow1"
//             className="fa-solid fa-caret-right add-comopnent-icon"
//           ></i>
//           Body
//         </div>

//         <div id="structure-1-1" className="structure-1-1">
//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>
//         </div>

//         <div onClick={rotateicon1} className="structure-1">
//           <i
//             id="arrow2"
//             className="fa-solid fa-caret-right add-comopnent-icon"
//           ></i>
//           div
//         </div>
//         <div id="structure-1-2" className="structure-1-1">
//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>
//         </div>

//         <div onClick={rotateicon2} className="structure-1">
//           <i
//             id="arrow3"
//             className="fa-solid fa-caret-right add-comopnent-icon"
//           ></i>
//           div
//         </div>
//         <div id="structure-1-3" className="structure-1-1">
//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>
//         </div>

//         <div onClick={rotateicon3} className="structure-1">
//           <i
//             id="arrow4"
//             className="fa-solid fa-caret-right add-comopnent-icon"
//           ></i>
//           div
//         </div>
//         <div id="structure-1-4" className="structure-1-1">
//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>

//           <div className="structure-small">
//             <i
//               id="arrow1"
//               className="fa-solid fa-caret-right add-comopnent-icon"
//             ></i>
//             div
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


import React, { useState, useEffect } from "react";
import "../Css/HomePage.css";

const ShowStructure = ({ droppedItems }) => {
  const [hoveredComponentId, setHoveredComponentId] = useState(null);
  const componentNames = {
    "1": "ColumnOne",
    "2": "TwoColumn",
    "3": "ThreeColumn",
    "4": "ThreeAndSevenColumn",
    "5": "Text",
    "6": "Link",
    "7": "Image",
    "8": "Video",
    "9": "Map",
    "10": "LinkBlock",
    "11": "Quote",
    "12": "TextSection",
    "13": "Form",
    "14": "Input",
    "15": "TextArea",
    "16": "Select",
    "17": "Button",
    "18": "Label",
    "19": "CheckBox",
    "20": "Radio",
    "21": "CountDown",
    "22": "Tabs",
    "23": "CustonCode",
    "24": "ToolTip",
    "25": "Typed",
    "26": "ImageSlider",
    "27": "ToggleBox",
    "28": "Calendar",
    "29": "AlertBox",
    "30": "IconBox",
    "31": "SearchBox",
    "32": "IconLibrary",
    "33": "NavBarTypes",
    "34": "LogoBox",
    "35": "Footer",
    "36": "Box",
    "37": "Paragraph",
    "38": "Heading", 
    "39":"TwoColumn_Testing",
  };

  const handleClick = (id) => {
    console.log("Click detected on component with ID:", id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (hoveredComponentId) {
      const currentElement = document.getElementById(hoveredComponentId);
      if (currentElement) {
        currentElement.classList.add('hover-border-web-template');
      }
    }

    return () => {
      const previousElement = document.getElementById(hoveredComponentId);
      if (previousElement) {
        previousElement.classList.remove('hover-border-web-template');
      }
    };
  }, [hoveredComponentId]);

  const toggleVisibility = (id, arrowId) => {
    document.getElementById(arrowId).classList.toggle("rotateicon");
    document.getElementById(id).classList.toggle("displayblock");
  };

  const handleMouseEnter = (id) => {
    setHoveredComponentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredComponentId(null);
  };

  const renderStructure = (items, depth = 0) => {
    return items?.map((item, index) => {
      const arrowId = `arrow-${depth}-${index}`;
      const structureId = `structure-${depth}-${index}`;
      const hasChildren =
        item.JSONChildComponents && item.JSONChildComponents.length > 0;


      const componentName = componentNames[item.id] || "Unknown Component";
      const isHovered = hoveredComponentId === item.id;

      return (
        <div
          key={item.id}
          style={{ marginLeft: depth * 20 }}
          onMouseEnter={() => handleMouseEnter(item.id)}
          onMouseLeave={handleMouseLeave}
        // onClick={() => handleClick(item.id)}
        >
          <div

            id={`component-${item.id}`}
            className={`structure-1 ${isHovered ? 'hover-border-web-template' : ''}`}
            onClick={() => {
              handleClick(item.id)
              hasChildren && toggleVisibility(structureId, arrowId)
            }}
          >
            {hasChildren && (
              <i
                id={arrowId}
                className="fa-solid fa-caret-right add-comopnent-icon"
              ></i>
            )}
            {componentName != "Unknown Component" ? componentName : item.name} {item.id}
          </div>
          {hasChildren && (
            <div id={structureId} className="structure-1-1">
              {renderStructure(item.JSONChildComponents, depth + 1)}
            </div>
          )}
        </div>
      );
    });
  };

  return <div>{renderStructure(droppedItems)}</div>;
};

export default ShowStructure;
