import React from "react";

const MobileBottomButton = (props) => {
  const { bottomSliderOpen } = props;
  return (
    <div className="buttom-box">
      <div className="flex" style={{ display: "flex", width: "100%" }}>
        {/* <button style={{ width: "15%" }}>
          <i className="fa-solid fa-gear" onClick={bottomSliderOpen}></i>
        </button> */}
        <button
          className="greenBtn"
          style={{ width: "50%", background: "rgb(94 112 158)" }}
        >
          Save
        </button>
        <button className="redBtn" style={{ width: "50%" }}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default MobileBottomButton;
