import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import SundryMasterApi from "../../../API/APIServices/BillSundry";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function SundryMaster(props) {
  //*************************title Start***********************//
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } =
    useContext(MyContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useState(() => {
    document.title = "Tax Category";
  }, []);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    printName: "",
  });

  const [inputValues, setInputValues] = useState({
    id: "",
    name: "",
    printName: "",
    sundryType: "",
    defaultValue: "",
    methodApplied: "",
    absolutePercentAmount: "",
    percentOfAmount: "",
    applyOnBill: "",
    roundOffSundryAmount: "",
    amtRoundOffConfig: "",
    amtAdjustSaleAccount: "",
    selectedAccountSale: "",
    amtAdjustPurchaseAccount: "",
    selectedAccountPurchase: "",
    roundOffLimit: "",
    isActive: true,
  });

  useEffect(() => {
    // console.log(props.data);
    const fetchData = async () => {
      try {
        if (props.data) {
          try {
            const data = await props.data; // Assuming props.editData is a Promise
            setInputValues({
              id: data.id,
              name: data.name || "",
              printName: data.printName || "",
              sundryType: data.sundryType || "",
              defaultValue: data.defaultValue || "",
              methodApplied: data.methodApplied || "",
              absolutePercentAmount: data.absolutePercentAmount || "",
              percentOfAmount: data.percentOfAmount || "",
              applyOnBill: data.applyOnBill || "",
              roundOffSundryAmount: data.roundOffSundryAmount || "",
              amtRoundOffConfig: data.amtRoundOffConfig || "",
              amtAdjustSaleAccount: data.amtAdjustSaleAccount || "",
              selectedAccountSale: data.selectedAccountSale || "",
              amtAdjustPurchaseAccount: data.amtAdjustPurchaseAccount || "",
              selectedAccountPurchase: data.selectedAccountPurchase || "",
              roundOffLimit: data.roundOffLimit || "",
              isActive: data.isActive,
            });
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      printName: name === 'name' ? value : prevInputValues.printName,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name", "printName"];

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    // console.log(inputValues);
  };

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      if (props.modelOpen) {
        setLoading(true);
        const isSuccess = await SundryMasterApi.updateBillSundry(inputValues);
        console.log(isSuccess);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBoxEdit();
          props.fetchData();
          props.setSliderCallBack(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = await SundryMasterApi.InsertBillSundry(inputValues);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBox();
          // props.fetchData()
          setInputValues({
            id: "",
            name: "",
            printName: "",
            sundryType: "",
            defaultValue: "",
            methodApplied: "",
            absolutePercentAmount: "",
            percentOfAmount: "",
            applyOnBill: "",
            roundOffSundryAmount: "",
            amtRoundOffConfig: "",
            amtAdjustSaleAccount: "",
            selectedAccountSale: "",
            amtAdjustPurchaseAccount: "",
            selectedAccountPurchase: "",
            roundOffLimit: "",
            isActive: true,
          });
        } else {
          alert("Error inserting data");
        }
      }
    }
  };

  useEffect(() => {
    try {
      if (inputValues.amtAdjustSaleAccount === false) {
        setShowAdjust1(false);
      } else if (inputValues.amtAdjustSaleAccount === true) {
        setShowAdjust1(true);
      }

      if (inputValues.amtAdjustPurchaseAccount === false) {
        setShowAdjust2(false);
      } else if (inputValues.amtAdjustPurchaseAccount === true) {
        setShowAdjust2(true);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    input7: "",
    input8: "",
    input9: "",
    input10: "",
    input11: "",
    input12: "",
    input13: "",
    input14: "",
  });

  const inputRefs = {
    input1: useRef(null),
    input2: useRef(null),
    input3: useRef(null),
    input4: useRef(null),
    input5: useRef(null),
    input6: useRef(null),
    input7: useRef(null),
    input8: useRef(null),
    input9: useRef(null),
    input10: useRef(null),
    input11: useRef(null),
    input12: useRef(null),
    input13: useRef(null),
    input14: useRef(null),
  };

  const inputOrder = [
    "input1",
    "input2",
    "input3",
    "input4",
    "input5",
    "input6",
    "input7",
    "input8",
    "input9",
    "input10",
    "input11",
    "input12",
    "input13",
    "input14",
  ];
  let currentInputIndex = 0;

  //   const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setInputs({ ...inputs, [name]: value });
  //   };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     //   const currentInputName = inputOrder[currentInputIndex];
  //     const nextInputIndex = (currentInputIndex + 1) % inputOrder.length;
  //     const nextInputName = inputOrder[nextInputIndex];
  //     inputRefs[nextInputName].current.focus();
  //     currentInputIndex = nextInputIndex;
  //   }
  // };

  //************************Show Hide Start***********************//

  const [isInputVisiblam1, setInputVisiblam1] = useState(false);
  const handleToggleChangam1 = () => {
    setInputVisiblam1(!isInputVisiblam1);
  };
  //*************************Show Hide End***********************//

  //**************************modal box Start**********************//

  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const [showAdjust1, setShowAdjust1] = useState(false);
  const [showAdjust2, setShowAdjust2] = useState(false);

  const handleInputChangeadjust = (e) => {
    const val = e.target.value;
    if (val === "true") {
      setShowAdjust2(true);
    } else {
      setShowAdjust2(false);
    }
    handleInputChange(e);
  };

  const handleInputChangeadjust1 = (e) => {
    const val = e.target.value;
    if (val === "true") {
      setShowAdjust1(true);
    } else {
      setShowAdjust1(false);
    }
    handleInputChange(e);
  };

  //****************************modal box End******************************//

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  return (
    <div>
      <PageTabs
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
        locationTab={"/SundryMaster"}
      />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Bill Sundry Master</h2>
        </div>
        {/* *******************Save Button Start****************** */}

        {/* *******************Top Buttons****************** */}
        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          PageName="Masters"
          setSliderCallBack={props.setSliderCallBack}
        />

        {/* *******************Modal Box Open****************** */}
        <div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Bill Sundry Master</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleInputChangeActive}
                            checked={isInputVisiblam1}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Address Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <PageTabs /> */}
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im td-1">
                      General Details
                      <div className="input-box-checkbox1 ">
                        <div className=" td-2">
                          <div className="cl-toggle-switch td-3">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                onChange={handleInputChangeActive}
                                name="isActive"
                                checked={inputValues.isActive}
                                value={inputValues.isActive}
                              />
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="HeadContentInputBpx">
                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="name"
                          value={inputValues.name}
                          ref={input1Ref}
                          onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                        />
                        <label className="modifyTextLabel">
                          Name
                          <span className="StarClr">
                            *
                            {errorMessages.name && (
                              <label className="errorMessage">
                                {errorMessages.name}
                              </label>
                            )}
                          </span>
                        </label>
                      </div>

                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="printName"
                          value={inputValues.printName}
                          ref={input2Ref}
                          onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                        />
                        <label className="modifyTextLabel">Print Name
                          <span className="StarClr">
                            *
                            {errorMessages.printName && (
                              <label className="errorMessage">
                                {errorMessages.printName}
                              </label>
                            )}
                          </span></label>
                      </div>

                      <div className="input-box-m11">
                        <select
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="sundryType"
                          value={inputValues.sundryType}
                          ref={input3Ref}
                          onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                        >
                          <option>--Select--</option>
                          <option Classname="form-control s-1" value="1">
                            Addictive
                          </option>
                          <option Classname="form-control s-1" value="2">
                            Substactive
                          </option>
                        </select>
                        <label className="ItemMasterLabelFix">Sundry Type</label>
                      </div>

                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="number"
                          required
                          onChange={handleInputChange}
                          name="defaultValue"
                          value={inputValues.defaultValue}
                          ref={input4Ref}
                          onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                        />
                        <label className="modifyTextLabel">Default Value</label>
                      </div>
                      <div className="input-box-m11">
                        <select
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="methodApplied"
                          value={inputValues.methodApplied}
                        >
                          <option>--Select--</option>
                          <option Classname="form-control s-1" value="Other">
                            Other
                          </option>
                          <option Classname="form-control s-1" value="CGST">
                            CGST
                          </option>
                          <option Classname="form-control s-1" value="SGST">
                            SGST
                          </option>
                          <option Classname="form-control s-1" value="IGST">
                            IGST
                          </option>
                          <option Classname="form-control s-1" value="CESS">
                            CESS
                          </option>
                          <option Classname="form-control s-1" value="Discount">
                            Discount
                          </option>
                          <option Classname="form-control s-1" value="Loyalty">
                            Loyalty
                          </option>
                          <option Classname="form-control s-1" value="Freight">
                            Freight
                          </option>
                        </select>
                        <label className="ItemMasterLabelFix">Method Applied</label>
                      </div>
                    </div>

                    <div className="company_info-text">
                      Applied Sundry Amount On
                    </div>
                    <div className="HeadContentInputBpx">
                      <div className="input-box-m11">
                        <select
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="absolutePercentAmount"
                          value={inputValues.absolutePercentAmount}
                          ref={input6Ref}
                          onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                        >
                          
                          <option Classname="form-control s-1" value="false">
                            No
                          </option>
                          <option Classname="form-control s-1" value="true">
                            Yes
                          </option>
                        </select>
                        <label className="ItemMasterLabelFix">
                          Absolute Amt(Yes)/Percentage(No)
                        </label>
                      </div>
                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="number"
                          required
                          onChange={handleInputChange}
                          name="defaultValue"
                          value={inputValues.defaultValue}
                          ref={input4Ref}
                          onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                        />
                        <label className="modifyTextLabel">Default Value</label>
                      </div>
                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="number"
                          required
                          onChange={handleInputChange}
                          name="percentOfAmount"
                          value={inputValues.percentOfAmount}
                          ref={input7Ref}
                          onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                        />
                        <label className="modifyTextLabel">% Of Bill Amt</label>
                      </div>
                      <div className="input-box-m11">
                        <select
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChange}
                          name="applyOnBill"
                          value={inputValues.applyOnBill}
                        >
                          <option>--Select--</option>
                          <option Classname="form-control s-1" value="1">
                            Net Bill Amount
                          </option>
                          <option Classname="form-control s-1" value="2">
                            Flexible Amount
                          </option>
                          <option Classname="form-control s-1" value="3">
                            Total MRP Amt{" "}
                          </option>
                        </select>
                        <label className="ItemMasterLabelFix">Amount To Apply</label>
                      </div>
                    </div>

                    <div className="company_info-text">
                      Sundry Amount Round Off
                    </div>
                    <div className="">
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">

                          <select
                            className="modify_im_input mode_category"
                            ref={input9Ref}
                            onKeyDown={(event) => handleKeyPress(event, input10Ref)}
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="roundOffSundryAmount"
                            value={inputValues.roundOffSundryAmount}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Round Off Sundry Amount(Y/N)
                          </label>
                        </div>
                        <div className="input-box-m1">
                          <select
                            className="modify_im_input mode_limit mode_category"
                            ref={input10Ref}
                            onKeyDown={(event) => handleKeyPress(event, input11Ref)}
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="amtRoundOffConfig"
                            value={inputValues.amtRoundOffConfig}
                          >
                            <option Classname="form-control s-1" value="1">
                              Upper
                            </option>
                            <option Classname="form-control s-1" value="2">
                              Lower
                            </option>
                            <option Classname="form-control s-1" value="3">
                              Automatic
                            </option>
                          </select>
                          <label className="modify_lbl_text4">
                            Amount Round Off Configuration
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="company-info-top-right"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <div className="company_info-text-im">
                        Document Details
                      </div>
                      <div className="input-box-Im input-height_h1">
                        <div className="input-box-m11 ">
                          <select
                            className="modify_im_input mode_category"
                            ref={input11Ref}
                            onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                            type="text"
                            required
                            // onChange={handleInputChange}
                            onChange={handleInputChangeadjust1}
                            name="amtAdjustSaleAccount"
                            value={inputValues.amtAdjustSaleAccount}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Amount Adjust in Sale A/c
                          </label>
                        </div>
                        {showAdjust1 && (
                          <div className="input-box-m11">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="selectedAccountSale"
                              value={inputValues.selectedAccountSale}
                            />
                            <label className="modifyTextLabel">Select A/c</label>
                          </div>
                        )}
                      </div>
                      <div className="input-box-Im input-height_h1">
                        <div className="input-box-m11">
                          <select
                            className="modify_im_input mode_category"
                            ref={input12Ref}
                            onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                            type="text"
                            required
                            // onChange={handleInputChange}
                            onChange={handleInputChangeadjust}
                            name="amtAdjustPurchaseAccount"
                            value={inputValues.amtAdjustPurchaseAccount}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Amount Adjust in Pur. A/c
                          </label>
                        </div>
                        {showAdjust2 && (
                          <div className="input-box-m11">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="selectedAccountPurchase"
                              value={inputValues.selectedAccountPurchase}
                            />
                            <label className="modifyTextLabel">Select A/c</label>
                          </div>
                        )}
                      </div>
                      <div className="input-box-Im input-height_h1">
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_category"
                            ref={input13Ref}
                            onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                            type="number"
                            required
                            onChange={handleInputChange}
                            name="roundOffLimit"
                            value={inputValues.roundOffLimit}
                          />
                          <label className="modify_p_text2">Round-Off Limit</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Sundry Master is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Sundry Master is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default SundryMaster;
