import { useContext, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import MyContext from "../../../Inventory/MyContext";
import axios from "axios";
import ItemMaster from "../../../API/APIServices/ItemMaster";

const Imagemodal = (props) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [urlInput, setUrlInput] = useState("");
  const [newImagesAdded, setNewImagesAdded] = useState(false);
  const inputRef = useRef(null);
  const { TemplateData, imageBaseUrl, handleStyleChange } = useContext(MyContext)
  console.log(TemplateData);

  const GetAllimageOfTemplate = async () => {
    var templateId = localStorage.getItem("templateId")
    var imageList = await WebTemplate.CustomizedPageTemplateImageDetailWithTemplateId(templateId)
    if (imageList) {
      setImageUrl(imageList)
    }
  }
  useEffect(() => {
    GetAllimageOfTemplate()
  }, [])

  const addImage = () => {
    inputRef.current.click();
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    console.log(TemplateData);
    if (file) {
      var templateId = localStorage.getItem("templateId")
      formData.append(`files`, file);
      var imageUpload = await WebTemplate.UpdateCustomizedPageTemplateImage(formData, templateId)
      if (imageUpload) {
        await GetAllimageOfTemplate()
      }
    }
  };

  const handleAddImageFromUrl = () => {
    setImageUrl((prevUrls) => [...prevUrls, urlInput]);
    setNewImagesAdded(true);
  };

  useEffect(() => {
    if (newImagesAdded) {
      localStorage.setItem("imageURL", JSON.stringify(imageUrl));
      setNewImagesAdded(false);
    }
  }, [imageUrl, newImagesAdded]);


  useEffect(() => {
    const savedImageUrl = JSON.parse(localStorage.getItem("imageURL"));
    if (savedImageUrl) {
      setImageUrl(savedImageUrl);
    }
  }, []);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  // const handleValuePassing = (localValue) => {
  //   const fullUrl = `${imageBaseUrl}${localValue}`;
  //   handleStyleChange("background-image", `url(${fullUrl})`, '', props.getId)
  //   handleStyleChange("height", "400", "px", props.getId)
  //   handleStyleChange("width", "100", "%", props.getId)
  //   handleStyleChange("border", "none", "", props.getId)
  // };

  const handleValuePassing = async (localValue) => {
    const fullUrl = `${imageBaseUrl}${localValue}`;
    try {
      // const response = await axios.get(fullUrl, {
      //   withCredentials: true,
      //   credentials: "include",
      //   responseType: 'blob',
      // });
      // const blob = response.data;
      // const reader = new FileReader();
      // debugger;
      // reader.onloadend = () => {
      //   handleStyleChange("background-image", `url(${reader.result})`, '', props.getId);
      // };

      // reader.readAsDataURL(blob);

      handleStyleChange("background-image", `url(${fullUrl})`, '', props.getId);
      handleStyleChange("height", "400", "px", props.getId);
      handleStyleChange("width", "100", "%", props.getId);
      handleStyleChange("border", "none", "", props.getId);
      handleStyleChange("top", "250", "", props.getId);
      handleStyleChange("left", "50", "", props.getId);

    } catch (error) {
      console.error('Error fetching the image:', error.message);
    }
    console.log("fullUrl", fullUrl);
  };

  const readBlobAsDataURL = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    // const response = await fetch(imageUrl, {
    //   method: 'GET', 
    //   headers: {
    //     Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJKVEkiOiJZRVM2Mzg2Nzg4MDE1MjAyNzIyMzEiLCJSb2xlcyI6IkFQUC1VU0VSIiwiQnJhbmNoSWQiOiIxIiwiVUlEIjoiMSIsIlVOTSI6ImFkbWluIiwiREJUUCI6IjEiLCJEQjEiOiJOWUdpR1lZdjlHTnAvTmdtWDhEc25JMVdSb0gxOWliYnlyaGdSZ0Z6SWJjPSIsIkRCMiI6Ik5ZR2lHWVl2OUdOcC9OZ21YOERzbkxiWGlKbC9raWt5NXhTMzRKTUVzZ2s9IiwiQ1JJRCI6IiIsIkVDT00iOiJGYWxzZSIsIkVDT01fVUlEIjoiMCIsIkNOX1NUUjEiOiI0RVZJTWFXSkp5VFhkRkJnSThrTXZxM0I4dE5kSVFENkdEQmZ0MVVBZUIydVcvbkhCQlcvWkFPK0ZzM3J6ZjdyemVmQVN4bUxQallOdTMyb1FySGJSdlpSQ3JWZXljdFpGTHNVMlNCQ25SMD0iLCJDTl9TVFIyIjoiNEVWSU1hV0pKeVRYZEZCZ0k4a012cTNCOHROZElRRDZHREJmdDFVQWVCMnVXL25IQkJXL1pBTytGczNyemY3cnplZkFTeG1MUGpZTnUzMm9RckhiUmpQR2lKQnlESXUxYzVDU29SbkNrejA9IiwibmJmIjoxNzMyMjYzNTUyLCJleHAiOjE3MzI1MjI3NTIsImlhdCI6MTczMjI2MzU1MiwiaXNzIjoiaHR0cHM6Ly95ZXMtYm0uY29tIiwiYXVkIjoiTW90aGVyJ3MgSHV0In0.JqvsncBEMtyXVYJsGg2CkWSxyCJ5IVzeVwlzPWBlJ8g', // Replace with your token
    //     // You can add other headers if needed (e.g., Content-Type)
    //   }
    // });

    // debugger
    // if (!response.ok) {
    //   throw new Error("Failed to fetch image");
    // }

    // const blob = await response.blob();
    // return URL.createObjectURL(blob);
  };

  const handleImageDelete = (imageId) => {
    const cnfm = window.confirm("Are you sure, you want delete this Image")
    if (cnfm) {
      var deleteImage = ItemMaster.DeleteImage(imageId)
      if (deleteImage) {
        GetAllimageOfTemplate();
        alert("Deleted Successfully")
      }
    }
  }

  return (
    <div>
      <div className="modal-back">
        <div className="image-modal">
          <div className="viewcode-modal-heading">Image-selector-box</div>
          <i
            id="modal_box_close"
            onClick={props.handleclick}
            className="fa-sharp fa-solid fa-xmark"
          ></i>
          <div className="image-modal-main">
            <div className="image-modal-left" onClick={addImage}>
              <p className="upload-image-txt">
                Drop file here or click to upload
              </p>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleImageUpload}
              />
            </div>
            <div className="image-modal-right">
              <div className="image-modal-rignt-top">
                <div>
                  <input
                    className="add-image-input"
                    type="text"
                    placeholder="Enter URL"
                    value={urlInput}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <button
                    className="add-image-btn"
                    onClick={handleAddImageFromUrl}
                  >
                    Add Image
                  </button>
                </div>
              </div>
              <div className="image-modal-right-bottom">
                {imageUrl.map((localValue, index) => (
                  <div className="right-bottom-box" key={index} style={{ cursor: 'pointer' }}>
                    <div className="space-evenly"
                      onKeyDown={() => handleValuePassing(localValue)}
                    >
                      <img
                        // src={localValue}
                        src={`${imageBaseUrl}${localValue.id}`}
                        alt=""
                        style={{
                          width: "150px",
                          height: "90px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleValuePassing(localValue.id)}
                      />
                      <button style={{ margin: "28px 10px", borderRadius: "5px", backgroundColor: "ghostWhite" }} onClick={() => handleImageDelete(localValue.id)}>
                        <i class="fa-solid fa-trash" style={{ padding: "5px", color: "#e50f0f" }}></i>
                      </button>
                    </div>
                    <div>
                      <p></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Imagemodal