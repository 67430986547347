import React from 'react';
import ColumnOne from './ColumnOne';


const TwoColumn = (props) => {

  let getId1 = props.keyValueId;
  let getId2 = getId1 + "CHILD";

  let widthClass;
  switch (true) {
    case props.widthVa > 0 && props.widthVa <= 450:
      widthClass = "Two-column-mobile";
      break;
    case props.widthVa > 450 && props.widthVa <= 850:
      widthClass = "Two-column-tablet";
      break;
    default:
      widthClass = "";
      break;
  }
  const handleColumnClick = (id) => {
    props.onComponentClick(id);
  };

  return (
    <div id={getId1 + "innerHTML"}>
      <div
        onDragStart={props.handleDrag}
        onDrop={props.handleDrop}
        onDragOver={props.dragover}
        className={`two-column-one-web-template ${widthClass} `}
        style={{ display: 'flex', gap: "20px", width: "100%" }}
      >
        {/* <div > */}
        <ColumnOne isSingleOrTwoCol={true}
          keyValueId={getId1}
          handleDrag={props.handleDrag}
          handleDrop={props.handleDrop}
          dragover={props.dragover}
          onClick={() => handleColumnClick(getId1)}
        // initialWidthPercent={50}
        />
        {/* </div>
        <div > */}
        <ColumnOne isSingleOrTwoCol={true}
          keyValueId={getId2}
          handleDrag={props.handleDrag}
          handleDrop={props.handleDrop}
          dragover={props.dragover}
          onClick={() => handleColumnClick(getId2)}
        // initialWidthPercent={50}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default TwoColumn;
