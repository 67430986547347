import React, { useContext, useEffect, useState, useRef } from "react";
import PaymentModeAPI from "../../API/APIServices/BillPaymentMode";
import MyContext from "../MyContext";
import BillInvoiceModal from "./BillInvoiceModal";
import Transaction from "../../API/APIServices/Transaction";

// import { useEffect } from "react";

function BillPaymentInvoice(props) {
  const {
    inputValues,
    setInputValues,
    mainPageInputFocus2,
    ReferenceNumber,
    currencyDecimalPlaces,
  } = props;
  let billSeriesId = inputValues.billSeriesId;
  let TransName = inputValues.TransName;

  const inputRefs = useRef([]);
  const [SettingModal, setSettingModal] = useState(false);

  const OpenSettingModal = () => {
    setSettingModal(!SettingModal);
  };

  const CloseSettingModal = () => {
    setSettingModal(false);
  };

  /**********************Start From(Shivendra 21062024)**********************/

  //------------------Payment Mode Setting From Invoice Page-------------------

  const { OpenAlertBoxDelete, alertBoxDelete, setAlertBoxDelete } =
    useContext(MyContext);

  const [loading, setLoading] = useState(false);
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } =
    useContext(MyContext);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const [isEditPaymentMode, setEditPaymentMode] = useState(false);

  const [inputValuesPaymentStatic, setInputValuesPaymentStatic] = useState([
    {
      id: "1",
      PaymentMode: "Mode 1",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "2",
      PaymentMode: "Mode 2",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "3",
      PaymentMode: "Mode 3",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "4",
      PaymentMode: "Mode 4",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
  ]);

  //---------------Fill Payment Modes (Load Event)-----------------------

  const fetchPaymentModeList = async (billSeriesId) => {
    // debugger;
    try {
      const data1 = await PaymentModeAPI.getvoucherPaymentModeId(billSeriesId);
      if (data1) {
        setEditPaymentMode(true);
      }
      // /      debugger;
      console.log(data1);
      setInputValuesPaidAmount(data1);

      setInputValuesPaymentStatic((prevValues) =>
        Array.from({ length: 4 }, (_, index) => {
          if (index < data1.length) {
            const item = data1[index];
            return {
              ...prevValues[index],
              id: index + 1,
              PaymentMode: "Mode " + (index + 1),
              disabled: "disabled",
              billSeries: item.billSeries,
              assignToShow: item.assignToShow,
              accountId: item.accountId,
              accountTitle: item.accountTitle,
            };
          } else {
            return {
              id: index + 1,
              PaymentMode: "Mode " + (index + 1),
              disabled: "disabled",
              billSeries: "",
              assignToShow: "",
              accountId: "",
              accountTitle: "",
            };
          }
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPaymentModeList(billSeriesId);
  }, [billSeriesId]);

  //------------------Change Payment Mode-------------------

  const handleInputChangeActivePaymentMode = (index, e) => {
    const { name, checked } = e.target;

    setInputValuesPaymentStatic((prevValues) => {
      const updatedInputValues = [...prevValues];
      updatedInputValues[index].assignToShow = checked;
      return updatedInputValues;
    });

    handleInputChangePaymentMode(index, e);
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInputChangePaymentMode = (index, e) => {
    const { name, type, value, checked } = e.target; // Destructure name, type, value, and checked from event.target
    // Check the type of input element
    let updatedValue;
    if (type === "checkbox") {
      updatedValue = checked;
    } else {
      updatedValue = value;
    }

    setInputValuesPaymentStatic((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[index]) {
        updatedValues[index][name] = updatedValue;
        updatedValues[index].billSeries = billSeriesId;
      }
      return updatedValues;
    });
    //console.log(inputValuesPaymentMode);
  };

  const [inputValuesPaidAmount, setInputValuesPaidAmount] = useState([
    {
      assignToShow: "",
      accountId: "",
      accountTitle: "",
      PayAmt: 0,
      dt: "",
    },
  ]);

  useEffect(() => {
    console.log(inputValuesPaidAmount);
  }, [inputValuesPaidAmount]);

  const handleChangePayAmt = (index, e) => {
    debugger;
    const { name, value } = e.target; // Extract name and value from the event

    setInputValuesPaidAmount((prevValues) => {
      const updatedValues = [...prevValues];

      if (updatedValues[index]) {
        updatedValues[index][name] = value;

        // Update the 'dt' field with the current date and time (ISO format)
        updatedValues[index].dt = new Date().toISOString();
      }

      return updatedValues;
    });
  };

  useEffect(() => {
    // Calculate the sum of PayAmt
    let totalPayAmt = inputValuesPaidAmount.reduce(
      (sum, item) => sum + parseFloat(item.PayAmt || 0),
      0
    );
    let GrossAmt_ = inputValues.GrossAmt;
    setInputValues((prev) => ({
      ...prev,
      PaidAmt: totalPayAmt,
      DueAmt: GrossAmt_ - totalPayAmt,
    }));

    console.log(inputValuesPaidAmount);
  }, [inputValuesPaidAmount, inputValues.GrossAmt]);

  // Function to handle Enter key press
  const handleKeyPress = (index, event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputValuesPaidAmount.length + 1) {
        inputRefs.current[nextIndex].focus();
      } else {
        mainPageInputFocus2.current.focus();
      }
    }
  };

  //------------------Save Payment Mode-------------------

  const SaveBtnPaymentMode = async () => {
    setLoading(true);
    debugger;
    const shouldStop = inputValuesPaymentStatic.some(
      (item) => item.billSeries === 0
    );
    if (shouldStop) {
      alert("Please select a valid bill series to continue.");
      setLoading(false);
      return;
    }
    if (isEditPaymentMode === true) {
      const isSuccess = await PaymentModeAPI.updateVoucherPaymentMode(
        inputValuesPaymentStatic
      );
      debugger;
      if (isSuccess) {
        OpenAlertBoxEdit();
        OpenSettingModal();
        fetchPaymentModeList(billSeriesId);
        setLoading(false);
      } else {
        alert("error while updating");
        setLoading(false);
      }
    } else {
      const isSuccess = await PaymentModeAPI.insertVoucherPaymentMode(
        inputValuesPaymentStatic
      );
      if (isSuccess) {
        OpenAlertBox();
        fetchPaymentModeList(billSeriesId);
        setLoading(false);
      } else {
        setLoading(false);
        alert("Error inserting data");
      }
    }
    // setLoading(false);
    // setInputValuesPaymentStatic([
    //   {
    //     billSeries: "",
    //     assignToShow: "",
    //     accountId: "",
    //     accountTitle: "",
    //   },
    // ]);
  };

  const onChangeSavePayment = async () => {
    var AccountTempData = await Transaction.SetAccountTempData(
      inputValuesPaidAmount,
      ReferenceNumber
    );
    if (AccountTempData.isSuccess === true) {
      console.log("Product Temp Data Data saved");
    } else {
      alert("Error in Product Temp Data");
    }
  };
  return (
    <div>
      <div
        className="tab-btn-box1 tab-b3 bg-color space-between"
        style={{ backgroundColor: "#5e709e", paddingRight: "10px" }}
      >
        <label className="c-w">&nbsp; Bill Payment Details</label>
        <i
          className="fa-solid fa-gear settingIcon"
          onClick={OpenSettingModal}
          style={{ color: "white", fontSize: "14px" }}
        ></i>
      </div>
      <div className="">
        <div className="input-box-content-l1 container_over ">
          <div className="" style={{ marginTop: "5px" }}>
            <div className="space-between input-box-bill alignCenter">
              {/* <table id="tblTransactionList" className="">
                <tbody className="tbody_body_m1">
                  {inputValuesPaidAmount.map((data1, index) => (
                    <tr key={data1.accountId}>
                      <td>
                        <span>{data1.accountTitle}</span>
                        <input
                          type="hidden"
                          name="accountId"
                          value={data1.accountId}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="text"
                          className="modify_im_input mode_category input-s1"
                          name="PayAmt"
                          value={data1.PayAmt}
                          onBlur={onChangeSavePayment}
                          onChange={(e) => handleChangePayAmt(index, e)}
                          onKeyDown={(e) => handleKeyPress(index, e)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50px",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ width: "16px", height: "16px" }}
                      />
                      <input
                        type="hidden"
                        name="accountId"
                        value={inputValuesPaidAmount.accountId}
                      />
                      <label>&nbsp;Credit Note Adjustment</label>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="modify_im_input mode_category input-s1"
                        type="text"
                        placeholder="Cr note/Sale return bill no"
                        name="PayAmt"
                        value={inputValuesPaidAmount.PayAmt}
                        onChange={(e) =>
                          handleChangePayAmt(inputValuesPaidAmount.length, e)
                        }
                        onKeyDown={(e) =>
                          handleKeyPress(inputValuesPaidAmount.length, e)
                        }
                        ref={(el) =>
                          (inputRefs.current[inputValuesPaidAmount.length] = el)
                        }
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="pay_amt1">
                        Paid Amount =&nbsp;{inputValues.PaidAmt}{" "}
                      </span>
                    </td>
                    <td>
                      <span className="pay_amt1">
                        Due Amt =&nbsp;{inputValues.DueAmt}{" "}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <div className="BillPayAccountDetails-box">
                <div
                  className="BillPayAccountDetails-box-2"
                  style={{ display: "flex" }}
                >
                  <div className="BillPayAccountDetails">
                    {inputValuesPaidAmount.map((data1, index) => (
                      <div key={data1.accountId}>
                        {/* <div className="BillPayAccountDetails-input">
                        {" "}
                        <input
                          type="hidden"
                          name="accountId"
                          value={data1.accountId}
                        />
                        <span>{data1.accountTitle}</span>
                      </div>
                      <div className="BillPayAccountDetails-input">
                        {" "}
                        <input
                          type="text"
                          className="modify_im_input mode_category input-s1"
                          name="PayAmt"
                          value={data1.PayAmt}
                          onBlur={onChangeSavePayment}
                          onChange={(e) => handleChangePayAmt(index, e)}
                          onKeyDown={(e) => handleKeyPress(index, e)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div> */}
                        <div className="BillPayAccountDetails-input">
                          <div
                            className="input-containerEffect width50mobile"
                            id="DivCreditLimit"
                          >
                            <input
                              id="CreditLimit"
                              type="text"
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                              name="PayAmt"
                              value={data1.PayAmt}
                              onBlur={onChangeSavePayment}
                              onChange={(e) => handleChangePayAmt(index, e)}
                              onKeyDown={(e) => handleKeyPress(index, e)}
                              ref={(el) => (inputRefs.current[index] = el)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              {data1.accountTitle}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="BillPayAccountDetails">
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Remark
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Remark 1
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Remark 2
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Remark 3
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="BillPayAccountDetails-box-2"
                  style={{ display: "flex" }}
                >
                  <div className="BillPayAccountDetails">
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Adv Adjst Series
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Pay Adjst Amt
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Credit note amount
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="BillPayAccountDetails">
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Adv Adjst Vch No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Due Amount
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            // onKeyDown={(e) => handleKeyPress(index, e)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Due Amount
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="BillPayAccountDetails-1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <input
                      type="hidden"
                      name="accountId"
                      value={inputValuesPaidAmount.accountId}
                    />
                    <label>&nbsp;Credit Note Adjustment</label>
                  </div>
                </div>

                <div className="BillPayAccountDetails-2">
                  <div style={{ fontSize: "14px" }}>
                    Paid Amount =&nbsp;
                    <span className="pay_amt1">
                      {/* {inputValues.PaidAmt}  */}
                      {parseFloat(inputValues.PaidAmt).toFixed(
                        currencyDecimalPlaces
                      )}
                    </span>
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    Due Amt =&nbsp;
                    <span className="pay_amt1">
                      {/* {inputValues.DueAmt} */}
                      {parseFloat(inputValues.DueAmt).toFixed(
                        currencyDecimalPlaces
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/************************* Bill Payment Setting Modal *****************************/}
      {SettingModal && (
        <BillInvoiceModal
          hideModal={OpenSettingModal}
          inputValuesPaymentStatic={inputValuesPaymentStatic}
          handleInputChangeActivePaymentMode={
            handleInputChangeActivePaymentMode
          }
          inputValuesPaidAmount={inputValuesPaidAmount}
          billSeriesId={billSeriesId}
          SaveBtnPaymentMode={SaveBtnPaymentMode}
          handleInputChangePaymentMode={handleInputChangePaymentMode}
          loading={loading}
          PageName="saleInvoice"
        />
      )}
    </div>
  );
}

export default BillPaymentInvoice;
