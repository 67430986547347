import React, { useState, useEffect } from "react";

function PopforSelectingLens(props) {
  const {
    lensType,
    // changeLensType,
    closePopUpForLensSelection,
    selectedValue,
    setSelectedValue,
    handleClick,
    selectedPopup,
    setSelectedPopup,
  } = props;
  // const [lensType, setLensType] = useState(1); // To manage the lens type
  const [loopContent, setLoopContent] = useState([]); // To store loop div elements

  // Function to generate the loop based on lens type
  useEffect(() => {
    generateLoop();
  }, [lensType]);

  const generateLoop = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      for (let i = -20; i <= 20; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else if (lensType === 2) {
      for (let i = -10; i <= 10; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else if (lensType === 3) {
      for (let i = 0; i <= 180; i += 1) {
        newLoopContent.push(i.toFixed(0));
      }
    } else if (lensType === 4) {
      for (let i = 0; i <= 12; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    }

    setLoopContent(newLoopContent);
  };

  // Show only negative values in the loop
  const showOnlyNegative = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      for (let i = -20; i < 0; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else if (lensType === 2) {
      for (let i = -10; i < 0; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else {
      newLoopContent = ["No negative values for this lens type!"];
    }

    setLoopContent(newLoopContent);
  };

  // Show only positive values in the loop
  const showOnlyPositive = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      for (let i = 0; i <= 20; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else if (lensType === 2) {
      for (let i = 0; i <= 10; i += 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else {
      newLoopContent = ["Only positive values for this lens type!"];
    }

    setLoopContent(newLoopContent);
  };

  return (
    <>
      <div className="popup-back"></div>
      <div onClick={() => closePopUpForLensSelection()} class="xmark-ms-2">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div className="PopforSelectingLens">
        <h1 className="PopforSelectingLens_heading">Distance-SPH-Power</h1>

        <div className="PopforSelectingLens_Loop" id="loopContainer">
          {lensType == 0 ? (
            <button
              className="PopforSelectingLens_nxt-button"
              onClick={() => handleClick()}
            >
              Next
            </button>
          ) : (
            loopContent.map((value, index) => (
              <div
                key={index}
                className="PopforSelectingLens_Loop_div"
                onClick={() => handleClick(value, lensType)}
              >
                {value}
              </div>
            ))
          )}
        </div>

        {(lensType == 1 || lensType == 2) && (
          <div
            id="PopforSelectingLens_Buttons"
            className="PopforSelectingLens_Buttons"
          >
            <button onClick={showOnlyNegative}>-</button>
            <button>0.00</button>
            <button onClick={showOnlyPositive}>+</button>
          </div>
        )}

        {selectedPopup && (
          <div id="customAlert">You selected {selectedValue}</div>
        )}
      </div>
    </>
  );
}

export default PopforSelectingLens;
