import React, { useContext, useState } from "react";
import EditSetting from "./ChildComponents/EditSetting";
import GeneralSection from "./ChildComponents/GeneralSection";
import DimensionSection from "./ChildComponents/DimensionSection";
import DecorationSection from "./ChildComponents/DecorationSection";
import ExtraSection from "./ChildComponents/ExtraSection";
import FlexSection from "./ChildComponents/FlexSection";
import TypographySection from "./ChildComponents/TypographySection";
import { useEffect } from "react";
import MyContext from "../../Inventory/MyContext";
import ReportDesignSection from "./ChildComponents/ReportDesignSection";

function Edit() {
    const {
        clickedItemId,
        clickedItemName,
        handleStyleChange,
        updateStyle,
        stylesArray } = useContext(MyContext)

    const [settingBlock, setSettingBlock] = useState(false);
    const [generalBlock, setGeneralBlock] = useState(false);
    const [dimensionBlock, setDimensionBlock] = useState(false);
    const [typographyBlock, setTypographyBlock] = useState(false);
    const [decorationBlock, setDecorationBlock] = useState(false);
    const [extraBlock, setExtraBlock] = useState(false);
    const [flexBlock, setFlexBlock] = useState(false);
    const [reportBlock, setReportBlock] = useState(false);



    const [getValeStrg, setTheStrgValue] = useState()


    useEffect(() => {
        if (clickedItemId != '') {
            setTheStrgValue(clickedItemId)
        } else {
            setTheStrgValue('')
        }

    }, [clickedItemId]);
    console.log(getValeStrg);
    console.log("clickedItemName", clickedItemName);

    const currentStyle = stylesArray.find(styleObj => styleObj.id === clickedItemId);

    const extractValueAndUnit = (styleValue) => {
        if (typeof styleValue !== 'string' || styleValue.trim() === '') {
            return { value: 'auto', unit: '' };
        }
        if (styleValue.trim().toLowerCase() === 'auto') {
            return { value: 'auto', unit: '' };
        }
        // Split the styleValue into an array of numeric and non-numeric parts
        let value = parseFloat(styleValue);
        let unit = styleValue.replace(value, '').trim();

        if (isNaN(value)) {
            value = 'auto';
            unit = '';
        }
        const integerValue = unit === '' ? Math.round(value) : parseInt(value, 10);
        return { value: integerValue, unit };
    };


    const generalLeftRow = () => {
        document.getElementById('general-leftrow').classList.toggle('rotateicon')
    };
    const decorationLeftRow = () => {
        document.getElementById('decoration-leftrow').classList.toggle('rotateicon')
    };
    const dimensionLeftRow = () => {
        document.getElementById('dimension-leftrow').classList.toggle('rotateicon')
    };
    const extraLeftRow = () => {
        document.getElementById('extra-leftrow').classList.toggle('rotateicon')
    };
    const flexLeftRow = () => {
        document.getElementById('flex-leftrow').classList.toggle('rotateicon')
    };
    const typographyLeftRow = () => {
        document.getElementById('typography-leftrow').classList.toggle('rotateicon')
    };
    const reportLeftRow = () => {
        document.getElementById('report-leftrow').classList.toggle('rotateicon')
    };

    const openSettingBlock = () => {
        setSettingBlock(!settingBlock);
    }
    const openGeneralBlock = () => {
        setGeneralBlock(!generalBlock);
        generalLeftRow();
    }
    const openDimensionBlock = () => {
        setDimensionBlock(!dimensionBlock);
        dimensionLeftRow()
    }
    const openTypographyBlock = () => {
        setTypographyBlock(!typographyBlock);
        typographyLeftRow();
    }
    const openDecorationBlock = () => {
        setDecorationBlock(!decorationBlock);
        decorationLeftRow();
    }
    const openExtraBlock = () => {
        setExtraBlock(!extraBlock);
        extraLeftRow();
    }
    const openFlexBlock = () => {
        setFlexBlock(!flexBlock);
        flexLeftRow();
    }
    const openReportDesignBlock = () => {
        setReportBlock(!reportBlock);
        reportLeftRow();
    }

    return (
        <div className="edit-options">
            <div className="textarea-edit-box">

                <div className="edit-classes">
                    <i className="fa-solid fa-magnifying-glass" style={{ color: "white" }} ></i>
                    <p className="edit-paragraph">Classes</p>
                    <select name="function" className="edit-selector" >

                        <option value="state">-State-</option>
                        <option value="Hover">Hover</option>
                        <option value="Click">Click</option>
                        <option value="Even/Odd">Even/Odd</option>

                    </select>
                </div>
            </div>
            <div className="textarea-edit-box">
                <textarea name="" id="" cols="" rows="" className="edit-inputbox"></textarea>
            </div>

            <div className="edit-component-status">
                <div className="edit-component">
                    <p className="edit-paragraph">Selected:</p>
                    <p className="edit-paragraph">&nbsp;{clickedItemName}</p>
                    {/* <p className="edit-paragraph">&nbsp; : Header</p> */}
                </div>
                <div className="edit-status">
                    <p className="edit-style-para">{clickedItemId}</p>

                    <p className="edit-paragraph"> &nbsp; Active</p>
                </div>

            </div>
            <div className="edit-setting-box-main">

                <p className="edit-settingbutton" onClick={openSettingBlock}><i className="icon-edit fa-sharp fa-solid fa-gear p-8"></i> Setting</p>
                {settingBlock && <EditSetting id={getValeStrg} handleStyleChange={handleStyleChange} />

                }
                <p className="edit-openbar" onClick={openGeneralBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="general-leftrow"></i >General</p>
                {
                    generalBlock && <GeneralSection id={getValeStrg} handleStyleChange={handleStyleChange} updateStyle={updateStyle} />
                }
                <p className="edit-openbar" onClick={openDimensionBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="dimension-leftrow"></i>Dimension</p>
                {
                    dimensionBlock && <DimensionSection id={getValeStrg} handleStyleChange={handleStyleChange} stylesArray={stylesArray} currentStyle={currentStyle} extractValueAndUnit={extractValueAndUnit} />
                }
                <p className="edit-openbar" onClick={openTypographyBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="typography-leftrow"></i>Typography</p>
                {
                    typographyBlock && <TypographySection id={getValeStrg} handleStyleChange={handleStyleChange} updateStyle={updateStyle} currentStyle={currentStyle} extractValueAndUnit={extractValueAndUnit} />
                }
                <p className="edit-openbar" onClick={openDecorationBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="decoration-leftrow"></i>Decoration</p>
                {
                    decorationBlock && <DecorationSection id={getValeStrg} handleStyleChange={handleStyleChange} updateStyle={updateStyle} stylesArray={stylesArray} currentStyle={currentStyle} extractValueAndUnit={extractValueAndUnit} />
                }
                <p className="edit-openbar" onClick={openExtraBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="extra-leftrow"></i>Extra</p>
                {
                    extraBlock && <ExtraSection id={getValeStrg} />
                }
                <p className="edit-openbar" onClick={openFlexBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="flex-leftrow"></i>Flex</p>
                {
                    flexBlock && <FlexSection id={getValeStrg} handleStyleChange={handleStyleChange} updateStyle={updateStyle} />
                }
                <p className="edit-openbar" onClick={openReportDesignBlock}><i className="icon-edit fa-solid fa-caret-right p-8" id="report-leftrow"></i>Report Design</p>
                {
                    reportBlock && <ReportDesignSection id={getValeStrg} />
                }

            </div>
        </div>

    )

}

export default Edit;
