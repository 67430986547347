
import React, { useState, useRef, useEffect, useContext } from "react";
// import 'react-quill/dist/quill.snow.css'
// import ImageUploadWithPreview from "./../../Components/ImagePreview"
import MainButton from "./../Props/MainButton";
import PageTabs from "../Components/PageTabs";
import JoditEditor from "jodit-react";
import { useLocation } from "react-router-dom";
// const [loading, setLoading] = useState(false);
import Order from "../../API/APIServices/Order";
import MyContext from "../MyContext";
import AlertBox from "../../Inventory/Props/AlertBox";
import ItemMaster from "../../API/APIServices/ItemMaster";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function LensMaster() {

    useState(() => {
        document.title = 'Lens Master';
    }, []);

    const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, } = useContext(MyContext)

    const location = useLocation();
    const { item } = location.state;
    console.log(item);


    //*********************{Button Script}*********************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        // console.log(isInputVisiblemc1);
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // {/* ********************* {modal box} *********************/}
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    };

    // const handleInputChange13 = (rowIndex, e) => {
    //     // Handle input changes and update the corresponding row data
    //     const { name, value } = e.target;
    //     const updatedRows2 = [...rows2];
    //     updatedRows2[rowIndex][name] = value;
    //     setRows2(updatedRows2);
    // };

    const handleDeleteRow2 = (rowIndex) => {
        // Create a copy of the rows1 array without the row to be deleted
        const updatedRows = rows.filter((_, index) => index !== rowIndex);
        setRows(updatedRows);
    }

    const [ItemImage, setItemImage] = useState([]);
    // const handleImageUpload = (indx, e) => {
    //     // const file = event.target.files[0]; // Get the selected file
    //     // const updatedRows = [...rows]; // Create a copy of the rows array

    //     // // Update the specific row's image property with the selected file
    //     // updatedRows[rowIndex].image = file;

    //     // // Update the state with the modified rows array
    //     // setRows(updatedRows);

    //     const file = e.target.files[0];
    //     const newItemImage = [...ItemImage];
    //     if (indx >= 0 && indx < newItemImage.length) {
    //         newItemImage[indx] = file;
    //     } else {
    //         newItemImage.push(file);
    //     }
    //     setItemImage(newItemImage);
    // };

    const handleImageUpload = (indx, e) => {
        const file = e.target.files[0];
        if (!file.type.startsWith("image/")) {
            alert("Please upload a valid image file.");
            return;
        }
        const newItemImage = [...ItemImage];
        newItemImage[indx] = file;
        setItemImage(newItemImage);
    };

    const editorSd = useRef(null);
    const editorLd = useRef(null);
    const [fullDescription, setFullDescription] = useState('');
    const [longDescription, setLongDescription] = useState("");
    const [selectedLensType, setSelectedLensType] = useState('');

    console.log(fullDescription);

    const columns = ['features', 'price', 'group', 'remarks', 'image'];

    const handleKeyPress1 = (index, columnName, event) => {
        const { key } = event;
        if (key === 'Enter') {
            const columnIndex = columns.indexOf(columnName);
            if (columnIndex === columns.length - 1) {
                const nextRow = document.getElementsByName(`${columns[0]}-${index + 1}`);
                if (nextRow.length > 0) {
                    nextRow[0].focus();
                }

            } else {
                const nextColumnName = columns[columnIndex + 1];
                const nextColumn = document.getElementsByName(`${nextColumnName}-${index}`);
                if (nextColumn.length > 0) {
                    nextColumn[0].focus();
                }
            }
        }
    };

    const [inputValues, setInputValues] = useState({
        CompanyName: "",
        CompanyTitle: "",
        CompanyBanner: "",
        LensType: "",
        fullDescription: "",
        lensOffer: "",
        LE_SPH: "",
        LE_CYL: "",
        LE_AXIS: "",
        LE_ADD: "",
        RE_SPH: "",
        RE_CYL: "",
        RE_AXIS: "",
        RE_ADD: "",
        Price: "",
        OldPrice: "",
        sph_Step: "",
        cyl_Step: "",
        axis_Step: "",
        add_Step: "",

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        setSelectedLensType(event.target.value);
    };

    const UpdateProduct = async () => {
        // setLoading(true);
        try {
            const updatedValues = [

                {
                    path: "CompanyName",
                    op: "replace",
                    from: "string",
                    value: inputValues.CompanyName
                },
                {
                    path: "CompanyTitle",
                    op: "replace",
                    from: "string",
                    value: inputValues.CompanyTitle
                },
                {
                    path: "CompanyBanner",
                    op: "replace",
                    from: "string",
                    value: inputValues.CompanyBanner
                },
                {
                    path: "LensType",
                    op: "replace",
                    from: "string",
                    value: inputValues.LensType
                },
                {
                    path: "fullDescription",
                    op: "replace",
                    from: "string",
                    value: fullDescription
                },
                {
                    path: "LensOffer",
                    op: "replace",
                    from: "string",
                    value: inputValues.lensOffer
                },
                {
                    path: "LE_SPH",
                    op: "replace",
                    from: "string",
                    value: inputValues.LE_SPH
                },
                {
                    path: "LE_CYL",
                    op: "replace",
                    from: "string",
                    value: inputValues.LE_CYL
                },
                {
                    path: "LE_AXIS",
                    op: "replace",
                    from: "string",
                    value: inputValues.LE_AXIS
                },
                {
                    path: "LE_ADD",
                    op: "replace",
                    from: "string",
                    value: inputValues.LE_ADD
                },
                {
                    path: "RE_SPH",
                    op: "replace",
                    from: "string",
                    value: inputValues.RE_SPH
                },
                {
                    path: "RE_CYL",
                    op: "replace",
                    from: "string",
                    value: inputValues.RE_CYL
                },
                {
                    path: "RE_AXIS",
                    op: "replace",
                    from: "string",
                    value: inputValues.RE_AXIS
                },
                {
                    path: "RE_ADD",
                    op: "replace",
                    from: "string",
                    value: inputValues.RE_ADD
                },
                {
                    path: "Price",
                    op: "replace",
                    from: "string",
                    value: inputValues.Price
                },
                {
                    path: "OldPrice",
                    op: "replace",
                    from: "string",
                    value: inputValues.OldPrice
                },
                {
                    path: "sph_Step",
                    op: "replace",
                    from: "string",
                    value: inputValues.sph_Step
                },
                {
                    path: "cyl_Step",
                    op: "replace",
                    from: "string",
                    value: inputValues.cyl_Step
                },
                {
                    path: "axis_Step",
                    op: "replace",
                    from: "string",
                    value: inputValues.axis_Step
                },
                {
                    path: "add_Step",
                    op: "replace",
                    from: "string",
                    value: inputValues.add_Step
                },
                // {
                //     path: "RE_SPH_Intval",
                //     op: "replace",
                //     from: "string",
                //     value: inputValues.RE_SPH_Intval
                // },
                // {
                //     path: "RE_CYL_Intval",
                //     op: "replace",
                //     from: "string",
                //     value: inputValues.RE_CYL_Intval
                // },
            ];
            const isSuccess = await Order.updateItemRelatedLinkedLensProdSetting(updatedValues, item.d2Id);
            if (isSuccess === true) {
                OpenAlertBox();
                const featuresData = Array.from({ length: rows.length }, (_, index) => {
                    const DataAdd = rows[index];
                    return {
                        // ...prevValues[index], // Spread previous values (if any)
                        id: index + 1, // Use the index for unique ID
                        features: DataAdd.features || '', // Ensure a default value if undefined
                        price: DataAdd.price || '',
                        disableBuy: DataAdd.disableBuy || '',
                        illustrationImage: DataAdd.illustrationImage || '',
                        remarks: DataAdd.remarks || '',
                        group: DataAdd.group || '',
                        displayAtoZ: DataAdd.displayAtoZ || '',
                        extraFt1: DataAdd.extraFt1 || '',
                        extraFt2: DataAdd.extraFt2 || '',
                    };
                });
                setInputFeature((prevValues) => {
                    return Array.from({ length: rows.length }, (_, index) => {
                        if (index < rows.length) {
                            const DataAdd = rows[index];
                            return {
                                ...prevValues[index],
                                id: index + 1,
                                features: DataAdd.features,
                                price: DataAdd.price,
                                disableBuy: DataAdd.disableBuy,
                                illustrationImage: DataAdd.illustrationImage,
                                remarks: DataAdd.remarks,
                                group: DataAdd.group,
                                displayAtoZ: DataAdd.displayAtoZ,
                                extraFt1: DataAdd.extraFt1,
                                extraFt2: DataAdd.extraFt2,
                            };

                        } else {
                            return {
                                id: index + 1,
                                features: '',
                                price: '',
                                disableBuy: '',
                                illustrationImage: '',
                                remarks: '',
                                group: '',
                                displayAtoZ: '',
                                extraFt1: '',
                                extraFt2: '',
                            };
                        }
                    });
                });
                const lensData = await Order.UpdateLensMasterSettAndFeatures(featuresData, item.d2Id);
                if (lensData.isSuccess) {
                    setInputFeature(lensData.isSuccess)

                    // ***************** Update Image *******************
                    try {
                        const results = await Promise.all(
                            lensData.result.map((result, i) => {
                                console.log(ItemImage);
                                debugger
                                if (ItemImage[i]) {
                                    const formData = new FormData();
                                    formData.append("files", ItemImage[i]);
                                    return ItemMaster.UpdateLensImage(formData, result, item.d2Id);
                                }
                            })
                        );
                        console.log("Image uploads successful:", results);
                    } catch (error) {
                        console.error("Error during image upload:", error);
                    }
                } else {
                    throw new Error("Error while adding Feature");
                }
            } else {
                throw new Error("Error while updating cart");
            }
        } catch (error) {
            console.error("Error while updating cart:", error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData2 = async () => {
            try {
                const data = await Order.GetLensMasterSettAndFeatures(item.d2Id);
                console.log(data)
                if (data) {
                    setInputValues({
                        d2Id: data.itemId || "",
                        CompanyName: data.companyBanner || "",
                        CompanyTitle: data.companyTitle || "",
                        CompanyBanner: data.companyBanner || "",
                        LensType: data.lensType || "",
                        enableBuy: data.enableBuy || "",
                        // fullDescription: data.fullDescription || "",
                        lensOffer: data.lensOffer || "",
                        LE_SPH: data.lE_SPH || "",
                        LE_CYL: data.lE_CYL || "",
                        LE_AXIS: data.lE_AXIS || "",
                        LE_ADD: data.lE_ADD || "",
                        RE_SPH: data.rE_SPH || "",
                        RE_CYL: data.rE_CYL || "",
                        RE_AXIS: data.rE_AXIS || "",
                        RE_ADD: data.rE_ADD || "",
                        Price: data.price || "",
                        OldPrice: data.oldPrice || "",
                        enablePayForOne: data.enablePayForOne || "",
                        priorityShowInLensDetail: data.priorityShowInLensDetail || "",
                        extraField1: data.extraField1 || "",
                        sph_Step: data.sph_Step,
                        cyl_Step: data.cyl_Step,
                        axis_Step: data.axis_Step,
                        add_Step: data.add_Step,

                    });
                    setInputFeature({
                        addFeatures: data.getAddFeatures || []

                    })
                    console.log(inputFeature)
                    setFullDescription(data.fullDescription || "")
                    // if (data.getAddFeatures.length) {
                    //     debugger
                    //     setRows([
                    //         {
                    //             itemId: data.getAddFeatures.itemId,
                    //             features: data.getAddFeatures.features,
                    //             price: data.getAddFeatures.price,
                    //             disableBuy: data.getAddFeatures.disableBuy,
                    //             illustrationImage: data.getAddFeatures.illustrationImage,
                    //             remarks: data.getAddFeatures.remarks,
                    //             group: data.getAddFeatures.group,
                    //             displayAtoZ: data.getAddFeatures.displayAtoZ,
                    //             extraFt1: data.getAddFeatures.extraFt1,
                    //             extraFt2: data.getAddFeatures.extraFt2,
                    //         },
                    //     ]);
                    // }
                    if (data.getAddFeatures.length) {
                        setRows(
                            data.getAddFeatures.map((item) => ({
                                itemId: item.itemId,
                                features: item.features,
                                price: item.price,
                                disableBuy: item.disableBuy,
                                illustrationImage: item.illustrationImage,
                                remarks: item.remarks,
                                group: item.group,
                                displayAtoZ: item.displayAtoZ,
                                extraFt1: item.extraFt1,
                                extraFt2: item.extraFt2,
                            }))
                        );
                    }
                    // const newItemImage = await Promise.all(
                    //     data?.getAddFeatures?.map(async (row) => {
                    //         if (row.illustrationImage) {
                    //             const response = await fetch(`${imageBaseUrl}${row.illustrationImage}`);
                    //             console.log(response);
                    //             if (!response.ok) {
                    //                 console.error(`Failed to fetch image for D1ItemId: ${row.illustrationImage}`);
                    //                 return null; 
                    //             }
                    //             const blob = await response.blob();
                    //             return URL.createObjectURL(blob);
                    //         }
                    //         return null;
                    //     })
                    // );
                    // setItemImage(newItemImage);

                    // else {
                    //     setRows(data.getAddFeatures);
                    // }
                    //   const imageUrl = `http://localhost:5000/api/v1/Company/Image?ImageId=${data.companyImageId}`;
                    // setSelectedImage(imageUrl);
                    // console.log(data.businessTypes)
                    // setBusinessType(data.businessTypes)
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData2();
    }, []);

    const [inputFeature, setInputFeature] = useState({
        addFeatures: [{
            masterType: 224,
            itemId: item.d2Id,
            features: "",
            price: "",
            disableBuy: "",
            illustrationImage: "",
            remarks: "",
            group: "",
            displayAtoZ: "",
            extraFt1: "",
            extraFt2: "",
        }]
    });

    const [rows, setRows] = useState(Array(1).fill({}));

    const handleAddRow = () => {
        setRows([...rows, {}]); // Add a new empty row to the array
    };
    // const [inputFeatureAdd, setInputFeatureAdd] = useState({ addFeatures: [] });
    const handleInputChange1 = (rowIndex, e) => {
        const { name, value } = e.target;
        console.log(inputFeature)

        setRows((prevTableData) => {
            const updatedData = [...prevTableData];
            const updatedRow = { ...updatedData[rowIndex], [name]: value };
            updatedData[rowIndex] = updatedRow;
            return updatedData;
        });

        setInputFeature((prevInputFeature) => {
            const updatedInputValues = {
                ...prevInputFeature,
                addFeatures: Array.isArray(prevInputFeature.addFeatures)
                    ? [...prevInputFeature.addFeatures]
                    : [],
            };
            updatedInputValues.addFeatures[rowIndex] = {
                ...updatedInputValues.addFeatures[rowIndex],
                [name]: value,
            };
            return updatedInputValues;
        });
    };

    const handleSubmit = () => {
        const lensTypeMapping = {
            '0': 'Single Vision',
            '1': 'Bifocal',
            '2': 'Zero Power',
            '3': 'Progressive'
        };

        const selectedLens = lensTypeMapping[selectedLensType];

        if (selectedLens) {
            console.log(`Selected lens type: ${selectedLens}`);
            // Fetch data based on the selected lens type
            // Example: fetchData(selectedLensType);
        } else {
            console.log('No lens type selected');
        }
    };

    useEffect(() => {
        return () => {
            // Cleanup: revoke the object URL when the component unmounts
            ItemImage.forEach((img) => {
                if (img instanceof Blob) {
                    URL.revokeObjectURL(img);
                }
            });
        };
    }, [ItemImage]);


    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Lens Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton isBtnSetting={true} SaveButton={UpdateProduct} />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im">
                                                <div className="input-box-m1 input-height">

                                                    <input name="CompanyName" value={inputValues.CompanyName} onChange={handleInputChange} className="modify_im_input mode_category" ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                    <label class="modify_p_text2">Company Name* </label>
                                                </div>
                                                <div className="input-box-m1 input-height">
                                                    <input name="CompanyTitle" value={inputValues.CompanyTitle} onChange={handleInputChange} className="modify_im_input mode_category" ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                    <label class="modify_p_text2 left-side-tre5">Company Title* </label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1 input-height">
                                                    <input
                                                        name="CompanyBanner"
                                                        value={inputValues.CompanyBanner}
                                                        onChange={handleInputChange}
                                                        className="modify_im_input mode_category"
                                                        ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                        type="text"
                                                        required
                                                    />
                                                    <label className="modify_p_text2">Company Banner*</label>
                                                </div>

                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input3Ref} onChange={handleInputChange}
                                                        onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" value={inputValues.LensType} name="LensType" placeholder="" required>
                                                        <option>--Select--</option>
                                                        <option value="0" >Single Vision</option>
                                                        <option value="1" >Bifocal</option>
                                                        <option value="2" >Zero Power</option>
                                                        <option value="3" >Progressive</option>
                                                    </select>
                                                    <label class="modify_lbl_text4">Lens Type</label>
                                                </div>

                                            </div>
                                            <div>
                                                <div className="company_info-text-im">
                                                    Short Description&nbsp;
                                                    <span className="StarClr">*</span>
                                                </div>
                                                {/* <div className="input-box-editer"> */}
                                                <div style={{ width: "100%", marginBottom: "15px" }}>
                                                    <JoditEditor
                                                        ref={editorSd}
                                                        name="fullDescription"
                                                        value={fullDescription}
                                                        onChange={(newContent) =>
                                                            setFullDescription(newContent)
                                                        }
                                                    />
                                                    {/* </div> */}
                                                </div>
                                            </div>

                                            <div>
                                                <div className="company_info-text-im">
                                                    Long Description
                                                </div>
                                                {/* <div className="input-box-editer"> */}
                                                <div style={{ width: "100%", marginBottom: "15px" }}>
                                                    <JoditEditor
                                                        ref={editorLd}
                                                        value={longDescription}
                                                        onChange={(newContent1) =>
                                                            setLongDescription(newContent1)
                                                        }

                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        <div className="">
                                            <div className="company_info-text-im">Lens Details</div>

                                        </div>
                                        <div className="table_head_mmbb1" style={{ fontWeight: "600" }}><u>Left EYE</u></div>
                                        <div className="input-box-SI-2 ">
                                            <div className="input-box-m6">
                                                <div className="space-between row mt-2 PriceMarket">
                                                    <label className="colLensRange " style={{ marginLeft: "15px" }}>SPH</label>
                                                    <label className="colLensRange " style={{ marginLeft: "15px" }}>CYL</label>
                                                    <label className="colLensRange " style={{ marginLeft: "15px" }}>AXIS</label>
                                                    <label className="colLensRange " style={{ marginLeft: "15px" }}>ADD</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m6">
                                                <div className="space-between row mt-2b mj-m1" >

                                                    <input name="LE_SPH" value={inputValues.LE_SPH} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input5Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="text" required />

                                                    <input name="LE_CYL" value={inputValues.LE_CYL} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input7Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required />

                                                    <input name="LE_AXIS" value={inputValues.LE_AXIS} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input9Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input10Ref)} type="text" required />

                                                    <input
                                                        name="LE_ADD"
                                                        value={inputValues.LE_ADD}
                                                        onChange={handleInputChange}
                                                        className="colLensRange col-sm-3-w1 table-input-w1 box-size1"
                                                        ref={input11Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                                                        type="text"
                                                        required
                                                        style={{ minWidth: "70px" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table_head_mmbb1" style={{ fontWeight: "600" }}><u>Right EYE</u></div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m6">
                                                <div className="space-between row mt-2 mj-m1">
                                                    <input name="RE_SPH" value={inputValues.RE_SPH} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input13Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text" required />

                                                    <input name="RE_CYL" value={inputValues.RE_CYL} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input15Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input16Ref)} type="text" required />

                                                    <input name="RE_AXIS" value={inputValues.RE_AXIS} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input17Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input18Ref)} type="text" required />

                                                    <input name="RE_ADD" value={inputValues.RE_ADD} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input19Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input20Ref)} type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                        {/* *********************** Interval ************************** */}
                                        <div className="table_head_mmbb1" style={{ fontWeight: "600" }}><u>Interval</u></div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m6">
                                                <div className="space-between row mt-2 mj-m1">
                                                    <input name="sph_Step" value={inputValues.sph_Step} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input13Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text" required />

                                                    <input name="cyl_Step" value={inputValues.cyl_Step} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input15Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input16Ref)} type="text" required />

                                                    <input name="axis_Step" value={inputValues.axis_Step} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input17Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input18Ref)} type="text" required />

                                                    <input name="add_Step" value={inputValues.add_Step} onChange={handleInputChange} style={{ minWidth: "70px" }} className="col-sm-3-w1 table-input-w1 box-size1" ref={input19Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input20Ref)} type="text" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-box-Im">
                                            <div className="input-box-m1 input-height">
                                                <input name="Price" value={inputValues.Price} onChange={handleInputChange} className="modify_im_input mode_category" ref={input20Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input21Ref)} type="text" required />
                                                <label class="modify_p_text2">Price</label>
                                            </div>
                                            <div className="input-box-m1 input-height ">
                                                <input name="OldPrice" value={inputValues.OldPrice} onChange={handleInputChange} className="modify_im_input mode_limit mode_category" ref={input21Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input22Ref)} type="text" required />
                                                <label class="modify_lbl_text10">Old Price</label>
                                            </div>
                                        </div>
                                        <div className="input-box-Im input-height2">
                                            <div className="input-box-m1 input-height">
                                                <select className="modify_im_input mode_category" ref={input3Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" placeholder="" required>
                                                    <option>Yes</option>
                                                    <option>No</option>

                                                </select>
                                                <label class="modify_p_text2">Enable Pay For One(?)  </label>
                                            </div>

                                        </div>
                                        <div className="input-box-Im input-height2">
                                            <div className="input-box-m1 input-height">
                                                <input name="" className="modify_im_input mode_category" ref={input1Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                <label class="modify_p_text2">Priority Show In Lens Detail  </label>
                                            </div>

                                            <div className="input-box-m1">
                                                <select className="modify_im_input mode_category" ref={input3Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" placeholder="" required>
                                                    <option>Testing Lens Product</option>
                                                </select>
                                                <label class="modify_lbl_text4">Previous Lens Detail</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="features-lens-itemNew">
                                    <div className="company_info-text-im">Add Features</div>
                                    <div className="">
                                        <div>
                                            <table className="table-scrol12">
                                                <thead className="table-tbody">
                                                    <tr className="table-tr1">
                                                        <th className="table-th-1 TabCOlorWhite">SN</th>
                                                        <th className=" td-col33 TabCOlorWhite">Features</th>
                                                        <th className="table-th-22 TabCOlorWhite">Price</th>
                                                        <th className="table-th-22 TabCOlorWhite">Group</th>
                                                        <th className="table-th-22 TabCOlorWhite">Remarks</th>
                                                        <th className="table-th-22 TabCOlorWhite">Image</th>
                                                        <th className="table-th-22 TabCOlorWhite">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=" table-container-tbody" >
                                                    {rows.map((row, rowIndex) => (
                                                        <tr className="table-tr1" key={rowIndex} >
                                                            <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                {rowIndex + 1}
                                                            </td>
                                                            <td className="table-td-3">
                                                                <input
                                                                    className="table-input-13 input-box-td1"
                                                                    type="text"
                                                                    name="features"
                                                                    // value={row[`Features-${rowIndex}`] || ""}
                                                                    value={row.features || ""}
                                                                    onChange={(e) => handleInputChange1(rowIndex, e)}
                                                                    style={{ height: "78px", textAlign: "center", padding: "10px" }}

                                                                />
                                                            </td>
                                                            <td className="table-td-2">
                                                                <input
                                                                    className="table-input-13 input-box-td2"
                                                                    type="text" name="price"
                                                                    // value={row[`Price-${rowIndex}`] || ""}
                                                                    value={row.price || ""}
                                                                    onChange={(e) => handleInputChange1(rowIndex, e)}
                                                                    style={{ height: "78px", textAlign: "center", padding: "10px" }}
                                                                />
                                                            </td>
                                                            <td className="table-td-2">
                                                                <input
                                                                    className="table-input-13 input-box-td2"
                                                                    type="text" name="group"
                                                                    // value={row[`Group-${rowIndex}`] || ""}
                                                                    value={row.group || ""}
                                                                    onChange={(e) => handleInputChange1(rowIndex, e)}
                                                                    style={{ height: "78px", textAlign: "center", padding: "10px" }}
                                                                />
                                                            </td>
                                                            <td className="table-td-2">
                                                                <input
                                                                    className="table-input-13 input-box-td2"
                                                                    type="text" name="remarks"
                                                                    // value={row[`Remarks-${rowIndex}`] || ""}
                                                                    value={row.remarks || ""}
                                                                    onChange={(e) => handleInputChange1(rowIndex, e)}
                                                                    style={{ height: "78px", textAlign: "center", padding: "10px" }}
                                                                />
                                                            </td>
                                                            <td className="table-td-2 img-bm" style={{ textAlign: "center" }}>
                                                                {/* <input
                                                                    className="img-bm" type="file" accept="image/*"
                                                                    onChange={(e) => handleImageUpload(rowIndex, e)}
                                                                /> */}
                                                                <div className="">
                                                                    <input className="img-bm" style={{ width: "78px" }}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        capture="environment"
                                                                        onChange={(e) => handleImageUpload(rowIndex, e)}
                                                                    />
                                                                    {(ItemImage[rowIndex]) ? (
                                                                        <img
                                                                            className=""
                                                                            alt="Uploaded"
                                                                            src={ItemImage[rowIndex] ? URL.createObjectURL(ItemImage[rowIndex]) : ""}
                                                                            style={{ height: "55px", width: "75px" }}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className=""
                                                                            alt="Default"
                                                                            src={`${imageBaseUrl}${row.illustrationImage}`}
                                                                            style={{ height: "55px", width: "75px" }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="table-td-2" style={{ textAlign: "center" }}>
                                                                <button className="btn-table1" onClick={() => handleDeleteRow2(rowIndex)}><i class="fa-solid fa-trash delete-btn"></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                                <div onClick={handleAddRow} className="add-row-btn-feature">
                                                    <i class="fa-solid fa-circle-plus add-fea-icon"></i>
                                                </div>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i class="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Lens Master is Updated"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}


        </div >
    );
}


export default LensMaster;
