
import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Components/PageTabs";
import ItemDepartmentMaster from "./ItemDepartmentMaster";
import LoginTypeMaster from "./LoginTypeMaster";
import DeliveryIncentive from "./DeliveryIncentive";
// import VehicleMaster from "./VehicleMaster";
import MainButton from "../../Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserCredentialApi from '../../../API/APIServices/UserCredential';
import ItemDepartmentApi from "../../../API/APIServices/ItemDepartment";
import AgentApi from "../../../API/APIServices/Agent";
import LoginTypeApi from "../../../API/APIServices/LoginType";
import AgentMaster from "../AccountMaster/AgentMaster";
import TransportMaster from '../AccountMaster/TransportMaster';
import TransportMasterApi from "../../../API/APIServices/Transporter";
import DeliveryIncentiveApi from "../../../API/APIServices/DeliveryIncentive";
import TableApi from "../../../API/APIServices/Table";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import TableAllocation from "../../../API/APIServices/TableAllocation";
import { useNavigate } from 'react-router-dom';
import IncentiveMaster from "./IncentiveMaster";
import BillSeriesMaster from "../../../API/APIServices/BillSeriesMaster";
import Order from "../../../API/APIServices/Order";

function UserMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit, UserDetails, fetchCompanyData } = useContext(MyContext);

    const navigate = useNavigate();

    useEffect(() => {

        if (UserDetails.userRoleCreateBtnVisible === false) {
            // Get all elements with the class name "dm_4"
            const elements = document.getElementsByClassName("dm_4");

            // Loop through all the elements and set their display style to "none"
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = "none";
            }
        }
    }, [UserDetails])

    useState(() => {
        document.title = 'User Master';
    }, []);

    const containerRef = useRef(null);

    const containerRef2 = useRef(null);

    const [, setIsOpenx1] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenx1(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef], []);

    const [BillSeriesData, setBillSeriesData] = useState([])

    useEffect(() => {
        const fetchBillSeries = async () => {
            var billSeriesData = await BillSeriesMaster.getBillSeriesMaster();
            console.log(billSeriesData);
            if (billSeriesData.length > 0) {
                setBillSeriesData(billSeriesData)
            }
        }
        fetchBillSeries()
    }, [])

    //****************{control modal box 1}*********************//
    const [isModalOpen01, setModalOpen1] = useState(true);

    const [restaurantModal, setRestaurantModal] = useState(true);

    const [itemDepartment, setItemDepartment] = useState(true);

    const [isIPAddres, setIPAddres] = useState(false);

    //***************{alloted modal box}*****************//
    const [AllocatedTable, setAlocatedTable] = useState(false);

    //*********** Item Department Modal *****************//
    const [itemDepartmentModal, setItemDepartmentModal] = useState(false);

    const ItemDepartmentPopup = () => {
        setItemDepartmentModal(!itemDepartmentModal)
    };

    //********** Login Type Modal *****************//
    const [loginTypeModal, setLoginTypeModal] = useState(false);

    const LoginTypePopup = () => {
        setLoginTypeModal(!loginTypeModal);
    };

    //**********Agent Modal*****************//
    const [agentModal, setAgentModal] = useState(false);

    const AgentModal = () => {
        setAgentModal(!agentModal);
    };

    //********** Delivery Incentive Modal *****************//
    const [deliveryIncentiveModal, setDeliveryIncentiveModal] = useState(false);

    const DeliveryIncentivePopup = () => {
        setDeliveryIncentiveModal(!deliveryIncentiveModal);
    };

    //**********{Delivery Incentive modal box}*****************//
    const [vehicleModal, setvehicleModal] = useState(false);

    const VehicleModalPopup = () => {
        setvehicleModal(!vehicleModal);
    };
    // ************************{Modal Back}**************************
    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    // *********************************************** API INTEGRATION *********************************
    const [errorMessages, setErrorMessages] = useState({
        businessTypes: '',
        userName: '',
        password: '',
        confirmPassword: '',
        // mobileNo: ''
    });

    const [inputValues, setInputValues] = useState({
        branchId: 0,
        businessTypes: '',
        userName: '',
        password: '',
        mobileNo: '',
        confirmPassword: '',
        fixAgentBookedByForTransaction: 0,
        loginType: 0,
        isAllowModifyCompany: false,
        isAllowModifyMaster: false,
        isAllowModifyVoucher: true,
        isAllowDeleteMaster: false,
        isAllowDeleteVoucher: false,
        isAllowEmailReporting: false,
        isAllowSMSReporting: true,
        isAllowExportReport: true,
        isShwTotalAmtInRpt: false,
        isAllowPrntOfRpt: true,
        isShowPurchasePriceForItem: false,
        isAllowDataBackup: true,
        isAllowBackdateFurtherEntries: false,
        isAllowLast3DigitNo: false,
        isRestrictionToAccessOtherSystem: false,
        isAllowChangeInSalePriceDisc: true,
        isAllowUseCancelOrdrFeature: false,
        isEnableBirthdayPopup: false,
        isAllowTableGuest: false,
        isAllowBiometricAttendance: false,
        isAllowCheckAttendance: false,
        userLoginStartTime: '12:00:00',
        userLoginEndTime: '12:00:00',
        allowSpecificComputerName: '',
        isAllowNoOfLoginsAsSameUser: 0,
        userRoleCreateBtnVisible: true,
        isAllowStaticIP: false,
        ipAddress: '',
        selectFirmToAllowAccess: '',
        selectFormToShwSettngBtn: '',
        recordVisibility: false,
        itemDepartment: 0,
        userWiseBillSeries: 0,
        canRestAllowDelvryApp: false,
        canRestAllowKOTApp: false,
        canRestUseChefApp: false,
        canRestUseOrderApp: false,
        vehicleNo: 0,
        deliveryBoyIncentive: 0,
        serviceType: "",
        userTables: [0],
        isActive: true,
    });

    const [error, setError] = useState('');
    const [error1, setError1] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        if (name === "canRestUseOrderApp") {
            if (value === "true") {
                setAlocatedTable(true);
            }
            else {
                setAlocatedTable(false);
            }
        }
        if (name === 'password') {
            if (value.length < 6) {
                setError('Password should be atleast of 6 characters');
            } else {
                setError('');
            }
        }
        else if (name === "confirmPassword") {
            if (inputValues.password === value) {
                setError1('');
            } else {
                setError1('Passwords do not match');
            }
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['userName', 'password', 'confirmPassword', 'userLoginStartTime', 'userLoginEndTime'];  //businessTypes

    const SaveBtn = async () => {
        if (error != "") {
            alert(error)
        }
        else if (error1 != "") {
            alert(error1)
        }
        else {
            if ((inputValues.password).length > 5) {
                if (inputValues.password === inputValues.confirmPassword) {
                    console.log(inputValues);
                    let allValid = true;
                    fieldNames.forEach((fieldName) => {
                        const isValid = validateInput(fieldName);
                        if (!isValid) {
                            allValid = false;
                        }
                    });

                    if (allValid) {
                        if (error === '' || error === null) {
                            setLoading(true);
                            if (Boolean(props.modelOpen) === true) {
                                const isSuccess = await UserCredentialApi.updateUserCredential(inputValues);
                                fetchCompanyData();
                                if (isSuccess) {
                                    OpenAlertBoxEdit();
                                    setLoading(false);
                                    // ************** Add Table Allocation ***************************
                                    var filteredData = [];
                                    for (var i = 0; i < tableSelection.length; i++) {
                                        filteredData.push({
                                            userId: inputValues.id,
                                            tableId: tableSelection[i],
                                            tableName: "",
                                            userName: ""
                                        });
                                    }
                                    await TableAllocation.updateTableAllocation(filteredData);
                                    props.fetchData()
                                    props.setSliderCallBack(false);
                                    //window.location.reload();
                                } else {
                                    //alert("Error while Updating Data");
                                }
                            }
                            else {
                                setLoading(true);
                                const id = await UserCredentialApi.insertUserCredential(inputValues);
                                if (id !== null && id !== "") {
                                    //alert(id);
                                    setLoading(false);
                                    fetchCompanyData();
                                    OpenAlertBox();
                                    // ************** Add Table Allocation ***************************
                                    var filteredData = [];
                                    for (var i = 0; i < tableSelection.length; i++) {
                                        filteredData.push({
                                            userId: id,
                                            tableId: tableSelection[i],
                                            tableName: "",
                                            userName: ""
                                        });
                                    }
                                    await TableAllocation.updateTableAllocation(filteredData);
                                } else {
                                    alert("Error inserting data");
                                }
                            }
                            setInputValues({
                                branchId: 0,
                                businessTypes: '',
                                userName: '',
                                password: '',
                                mobileNo: '',
                                confirmPassword: '',
                                fixAgentBookedByForTransaction: 0,
                                loginType: 0,
                                isAllowModifyCompany: false,
                                isAllowModifyMaster: false,
                                isAllowModifyVoucher: true,
                                isAllowDeleteMaster: false,
                                isAllowDeleteVoucher: false,
                                isAllowEmailReporting: false,
                                isAllowSMSReporting: true,
                                isAllowExportReport: true,
                                isShwTotalAmtInRpt: false,
                                isAllowPrntOfRpt: true,
                                isShowPurchasePriceForItem: false,
                                isAllowDataBackup: true,
                                isAllowBackdateFurtherEntries: false,
                                isAllowLast3DigitNo: false,
                                isRestrictionToAccessOtherSystem: false,
                                isAllowChangeInSalePriceDisc: true,
                                isAllowUseCancelOrdrFeature: false,
                                isEnableBirthdayPopup: false,
                                isAllowTableGuest: false,
                                isAllowBiometricAttendance: false,
                                isAllowCheckAttendance: false,
                                userLoginStartTime: '',
                                userLoginEndTime: '',
                                allowSpecificComputerName: '',
                                isAllowNoOfLoginsAsSameUser: 0,
                                userRoleCreateBtnVisible: true,
                                isAllowStaticIP: false,
                                ipAddress: '',
                                selectFirmToAllowAccess: '',
                                selectFormToShwSettngBtn: '',
                                recordVisibility: false,
                                itemDepartment: 0,
                                userWiseBillSeries: 0,
                                canRestAllowDelvryApp: false,
                                canRestAllowKOTApp: false,
                                canRestUseChefApp: false,
                                canRestUseOrderApp: false,
                                vehicleNo: 0,
                                deliveryBoyIncentive: 0,
                                serviceType: "",
                                userTables: 0,
                                isActive: true,
                            });
                            setTableSelection([]);
                        }
                    }
                }
                else {
                    alert("Password & Confirm Password does not Match");
                }
            }
            else {
                alert("Password should be atleast of 6 character");
            }
        }
    }

    // ********************* Get Table ******************************
    const [tableValue, setTableValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TableApi.getTable();
                console.log(data);
                setTableValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    // ********************* Get Item Department *********************
    const [itemDepartmentValue, setItemDepartmentValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemDepartmentApi.getItemDepartment();;
                console.log(data);
                setItemDepartmentValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [itemDepartmentModal]);
    // ********************* Get Agent *******************************
    const [agentValue, setAgentValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AgentApi.GetAgent();
                console.log(data);
                setAgentValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [agentModal]);
    // ********************* Get Login Type **************************
    const [loginTypeValue, setLoginTypeValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await LoginTypeApi.getLoginType();
                console.log(data);
                setLoginTypeValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [loginTypeModal]);
    // ********************* Get Vehicle ******************************
    const [vehicleValue, setvehicleValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TransportMasterApi.GetTransporter();
                console.log(data);
                setvehicleValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [vehicleModal]);

    // ********************* Get Delivery Incentive ********************
    const [deliveryIncentiveValue, setDeliveryIncentiveValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await DeliveryIncentiveApi.GetIncentiveNames();
                console.log(data);
                setDeliveryIncentiveValue(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [deliveryIncentiveModal]);

    // ********************* Get Service Type ********************
    const [serviceType, setServiceType] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Order.getServiceType();
                console.log(data);
                setServiceType(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [deliveryIncentiveModal]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData; // Assuming props.editData is a Promise
                        console.log(data);
                        // setInputValues(data);
                        let updatedOptions = [...tableSelection];

                        for (var i = 0; i < data.userTables.length; i++) {
                            const id = data.userTables[i];
                            if (updatedOptions.includes(id)) {
                                updatedOptions = updatedOptions.filter((item) => item !== id);
                            } else {
                                updatedOptions.push(id);
                            }
                            console.log(updatedOptions);
                        }
                        setTableSelection(updatedOptions);
                        const updatedData = updatedOptions.join(', ');
                        setInputValues((prevValues) => ({
                            ...prevValues,
                            "userTables": updatedData,
                        }));
                        console.log(updatedData);

                        setInputValues({
                            id: data.id,
                            branchId: data.branchId,
                            businessTypes: '',
                            userName: data.userName,
                            password: data.password,
                            mobileNo: data.mobileNo,
                            confirmPassword: data.password,
                            fixAgentBookedByForTransaction: data.defaultAgent,
                            loginType: data.userRole,
                            isAllowModifyCompany: data.canEditComp,
                            isAllowModifyMaster: data.canModifyMaster,
                            isAllowModifyVoucher: data.canModifyVoucher,
                            isAllowDeleteMaster: data.canDelMaster,
                            isAllowDeleteVoucher: data.canDelVoucher,
                            isAllowEmailReporting: data.canEmail,
                            isAllowSMSReporting: data.canSms,
                            isAllowExportReport: data.canExportReport,
                            isShwTotalAmtInRpt: data.isTotalAmtInRpt,
                            isAllowPrntOfRpt: data.isAllowPrntOfRpt,
                            isShowPurchasePriceForItem: data.isShowPrchsePrcForItm,
                            isAllowDataBackup: data.canDatabaseBackup,
                            isAllowBackdateFurtherEntries: data.canBckDateEntry,
                            isAllowLast3DigitNo: data.canLst3DgtNo,
                            isRestrictionToAccessOtherSystem: data.isRestricted,
                            isAllowChangeInSalePriceDisc: data.canChgSalePrcDis,
                            isAllowUseCancelOrdrFeature: data.canUseCancelOrdrFeature,
                            isEnableBirthdayPopup: data.isEnbleBdayPopup,
                            isAllowTableGuest: data.isAllowTableGuest,
                            isAllowBiometricAttendance: data.canAllowBiometric,
                            isAllowCheckAttendance: data.canCheckAttendance,
                            userLoginStartTime: data.usrLogStrtTm,
                            userLoginEndTime: data.usrLogEndTm,
                            allowSpecificComputerName: data.spcfCompName,
                            isAllowNoOfLoginsAsSameUser: data.noUserLogin,
                            userRoleCreateBtnVisible: data.userRoleCreateBtnVisible,
                            isAllowStaticIP: data.canStatIp,
                            ipAddress: data.ipAddress,
                            selectFirmToAllowAccess: '',
                            selectFormToShwSettngBtn: '',
                            recordVisibility: data.recordVisibility,
                            itemDepartment: data.restItemDpartmentId,
                            userWiseBillSeries: data.userWiseBillSeries,
                            canRestAllowDelvryApp: data.canRestAllowDelvryApp,
                            canRestAllowKOTApp: data.canRestAllowKOTApp,
                            canRestUseChefApp: data.canRestUseChefApp,
                            canRestUseOrderApp: data.canRestUseOrderApp,
                            vehicleNo: data.restVehicleNo,
                            deliveryBoyIncentive: data.restDelvryBoyIncentive,
                            //userTables: updatedData,
                            serviceType: data.serviceType,
                            isActive: true,
                        });

                        setServiceTypeOption("")
                        initializeServiceOptions(data);

                        // const serviceData = data.serviceType;
                        // const splitData = serviceData.split(", ")

                        // splitData.forEach(element => {
                        //     handleServiceType(Number(element), setServiceTypeOption, serviceTypeOption, 'serviceType');
                        // });

                        if (data.canRestUseOrderApp === true) {
                            setAlocatedTable(true);
                        }
                        // ***************** Table Allocation *****************
                        const tableData = await TableAllocation.getTableAllocationById(data.id);
                        let updatedTableOptions = [...tableSelection];
                        if (tableSelection.includes(tableData.tableId)) {
                            updatedTableOptions = tableSelection.filter((item) => item !== tableData.tableId);
                        } else {
                            updatedTableOptions = [...tableSelection, tableData.tableId];
                        }
                        setTableSelection(updatedOptions);
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    const options = ["1-Inventory", "2-Optical", "3-Medical", '4-Restaurant', '5-ECom'];
    const options1 = ["Company1", "Company2", "Company3", "Company4", "Company5"];
    const options2 = ["Company Master", "Account Master", "Item Master", "Item Group", "Agent Master"];

    const [firmNameOption1, setFirmNameOption1] = useState([]);
    const [firmNameOption2, setFirmNameOption2] = useState([]);
    const [firmNameOption3, setFirmNameOption3] = useState([]);
    const [serviceTypeOption, setServiceTypeOption] = useState([]);

    const handleFirmName = (option, setFirmNameOption, firmName, inputName) => {
        let updatedOptions;
        if (firmName.includes(option)) {
            updatedOptions = firmName.filter((item) => item !== option);
        } else {
            updatedOptions = [...firmName, option];
        }
        setFirmNameOption(updatedOptions);
        console.log(updatedOptions);

        const updatedData = updatedOptions.join(', ');
        setInputValues((prevValues) => ({
            ...prevValues,
            [inputName]: updatedData,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [inputName]: '' }));
        console.log(updatedOptions);

        if (firmName === firmNameOption1) {
            // ********* Optical ************
            if (option === "2-Optical") {
                setModalOpen1(true);
                //setRestaurantModal(false);
            }
            // ********* Restaurant **********
            if (option === "4-Restaurant") {
                setModalOpen1(true);
                setRestaurantModal(!restaurantModal);
                setItemDepartment(!itemDepartment);
            }
            // ********* ECom ****************
            if (option === "5-ECom") {
                setIPAddres(!isIPAddres);
            }
        }
    };

    const handleServiceType = (option, setServiceType, serviceType, inputName) => {
        let updatedOptions;
        if (serviceType.includes(option)) {
            updatedOptions = serviceType.filter((item) => item !== option);
        } else {
            updatedOptions = [...serviceType, option];
        }
        setServiceType(updatedOptions);

        const updatedData = updatedOptions.join(', ');
        setInputValues((prevValues) => ({
            ...prevValues,
            [inputName]: updatedData,
        }));
    };

    const initializeServiceOptions = (data) => {
        const serviceData = data.serviceType;
        const splitData = serviceData.split(", ").map(Number); // Convert to numbers

        splitData.forEach(element => {
            // Ensure the state is updated with all selected options
            setServiceTypeOption(prevOptions => {
                if (!prevOptions.includes(element)) {
                    return [...prevOptions, element];
                }
                return prevOptions;
            });
        });
    };

    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);

    const firmNameToggle = () => {
        setIsOpen3(!isOpen3);
        setIsOpen2(false);
        setIsOpen(false);
    };

    const firmNameClose = () => {
        setIsOpen3(false);
        setIsOpen2(false);
        setIsOpen(false);
    }

    const [isOpen2, setIsOpen2] = useState(false);

    const allowAcessToggle = () => {
        setIsOpen(false);
        setIsOpen2(!isOpen2);
        setIsOpen3(false);
        setIsOpen4(false);
    };

    const [isOpen, setIsOpen] = useState(false);

    const showSettingBtnToggle = () => {
        setIsOpen(!isOpen);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsOpen4(false);
    };

    const showServiceTypeToggle = () => {
        setIsOpen(false);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsOpen4(!isOpen4);
    }

    const inputClick = () => {
        setIsOpen4(false);
        setIsOpen3(false);
        setIsOpen2(false);
        setIsOpen(false);
    }

    const getSelectedServiceNames = () => {
        return serviceType
            .filter(option => serviceTypeOption.includes(option.id))
            .map(option => option.name)
            .join(', ');
    };

    const [tableSelection, setTableSelection] = useState([]);

    const handleCheckBoxChange = (id) => {
        let updatedOptions = [...tableSelection];
        if (tableSelection.includes(id)) {
            updatedOptions = tableSelection.filter((item) => item !== id);
            console.log("if")
        } else {
            updatedOptions = [...tableSelection, id];
            console.log("else")
        }
        setTableSelection(updatedOptions);
        console.log(tableSelection);

        const updatedData = updatedOptions.join(', ');
        setInputValues((prevValues) => ({
            ...prevValues,
            "userTables": updatedData,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, "userTables": '' }));
        console.log("id" + updatedOptions);
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                window.history.back();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);
    const input24Ref = useRef(null);
    const input25Ref = useRef(null);
    const input26Ref = useRef(null);
    const input27Ref = useRef(null);
    const input28Ref = useRef(null);
    const input29Ref = useRef(null);
    const input30Ref = useRef(null);
    const input31Ref = useRef(null);
    const input32Ref = useRef(null);
    const input33Ref = useRef(null);
    const input34Ref = useRef(null);
    const input35Ref = useRef(null);
    const input36Ref = useRef(null);
    const input37Ref = useRef(null);
    const input38Ref = useRef(null);
    const input39Ref = useRef(null);
    const input40Ref = useRef(null);
    const input41Ref = useRef(null);
    const input42Ref = useRef(null);
    const input43Ref = useRef(null);
    const input44Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    const sliderClose = () => {
        setItemDepartmentModal(false);
        setLoginTypeModal(false);
        setAgentModal(false);
        setDeliveryIncentiveModal(false);
        setvehicleModal(false);
    }

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (val) => {
        if (val === 'password') {
            setShowPassword(!showPassword);
        }
        else if (val === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };


    return (
        <div className="" onClick={firmNameClose}>
            <PageTabs locationTab={"/UserMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} modal={true} modalClose={sliderClose} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">User Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box" style={{ height: "98vh" }}>
                                <div className="tab-content-1">
                                    <div className="company-info-top-left1">
                                        <div className="configure_radius">
                                            <div className="company_info-text-im td-1">User&nbsp;Details
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="input-box-Imo1 input-height2 " ref={containerRef2} onClick={handleModalClick}>

                                                    {/* ############################## Show only when..Company master(business type) data is present ################################### */}

                                                    {/* <div className="input-box-mok1" onClick={firmNameToggle}>
                                                        <div className="selected-options modify_im_input mode_category1" style={{ height: 'auto' }} ref={input1Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)}>
                                                            {firmNameOption1.length > 0 ? firmNameOption1.join(', ') : 'Select firm names'}
                                                        </div>
                                                        <label className="UseMasterLabelFix">Firm Name
                                                            <span className="StarClr">*
                                                                {errorMessages.businessTypes && <label className="errorMessage">{errorMessages.businessTypes}</label>}
                                                            </span>
                                                        </label>
                                                        <div className="overSelect"></div>
                                                    </div> */}
                                                    {isOpen3 && (
                                                        <div id="checkBoxes" style={{ width: "86%", margin: "0px 18px" }}>
                                                            {options.map((option) => (
                                                                <label key={option}>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={option}
                                                                        checked={firmNameOption1.includes(option)}
                                                                        onChange={() => handleFirmName(option, setFirmNameOption1, firmNameOption1, 'businessTypes')}
                                                                    />
                                                                    &nbsp; {option}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} onClick={inputClick}
                                                            ref={input2Ref} onKeyDown={(event) => handleKeyPress(event, input3Ref)} name="userName" value={inputValues.userName} required />
                                                        <label className="modify_p_text2">Add Username
                                                            <span className="StarClr">*
                                                                {errorMessages.userName && <label className="errorMessage">{errorMessages.userName}</label>}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} onClick={inputClick}
                                                            ref={input2Ref} onKeyDown={(event) => handleKeyPress(event, input3Ref)} name="mobileNo" value={inputValues.mobileNo} required />
                                                        <label className="modify_p_text2">Mobile No
                                                            {/* <span className="StarClr">*
                                                                {errorMessages.mobileNo && <label className="errorMessage">{errorMessages.mobileNo}</label>}
                                                            </span> */}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="flex-end" style={{ width: "100%" }}>
                                                        <div className="image-input-container" style={{ width: "100%" }}>
                                                            <div className="input-box-mok1">
                                                                <input className="modify_im_input mode_category1" type={showPassword ? 'text' : 'password'}
                                                                    onChange={handleInputChange} onClick={inputClick}
                                                                    ref={input3Ref} onKeyDown={(event) => handleKeyPress(event, input4Ref)} name="password" value={inputValues.password} required />
                                                                <label className="modify_p_text2" style={{ left: "10px" }}>Password
                                                                    <span className="StarClr">*
                                                                        {errorMessages.password && <label className="errorMessage">{errorMessages.password}</label>}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="input-image" onClick={() => togglePasswordVisibility('password')}>{showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</div>
                                                        </div>
                                                    </div>
                                                    {error && <div style={{ color: 'red', fontSize: '12px' }}>{error}</div>}
                                                    {error1 && <div style={{ color: 'red', fontSize: '12px' }}>{error1}</div>}
                                                </div>

                                                {(() => {
                                                    var val = itemDepartment ? input5Ref : input6Ref;
                                                    return (
                                                        <div className="input-box-Imo1 input-height2">
                                                            <div className="flex-end" style={{ width: "100%" }}>
                                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                                    <div className="input-box-mok1">
                                                                        <input className="modify_im_input mode_category1" type={showConfirmPassword ? 'text' : 'password'}
                                                                            onChange={handleInputChange} onClick={inputClick}
                                                                            ref={input4Ref} onKeyDown={(event) => handleKeyPress(event, val)} name="confirmPassword" value={inputValues.confirmPassword} required />
                                                                        <label className="modify_p_text2" style={{ left: "10px" }}>Re-Enter Password
                                                                            <span className="StarClr">*
                                                                                {errorMessages.confirmPassword && <label className="errorMessage">{errorMessages.confirmPassword}</label>}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="input-image" onClick={() => togglePasswordVisibility('confirmPassword')}>{showConfirmPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })()}

                                                {itemDepartment && (
                                                    <div className="input-box-Imo1 input-height2 user_add">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="itemDepartment"
                                                                ref={input5Ref} onKeyDown={(event) => handleKeyPress(event, input6Ref)} value={inputValues.itemDepartment} onClick={inputClick} required>
                                                                <option Classname="form-control s-1" value="" ></option>
                                                                {(() => {
                                                                    if (itemDepartmentValue !== null && Array.isArray(itemDepartmentValue)) {
                                                                        return itemDepartmentValue.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label className="modify_p_text2">Item Department</label>
                                                        </div>
                                                        <div>
                                                            <i onClick={ItemDepartmentPopup} className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="input-box-Imo1 input-height2 user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" onChange={handleInputChange} name="fixAgentBookedByForTransaction"
                                                            ref={input6Ref} onKeyDown={(event) => handleKeyPress(event, input7Ref)} value={inputValues.fixAgentBookedByForTransaction} onClick={inputClick} required>
                                                            <option Classname="form-control s-1" value="" selected></option>
                                                            {(() => {
                                                                if (agentValue !== null && Array.isArray(agentValue)) {
                                                                    return agentValue.map((data) => (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            })()}
                                                        </select>
                                                        <label className="UseMasterLabelFix ">Fix Agent/Booked By For Transaction</label>
                                                    </div>
                                                    <div>
                                                        <i onClick={AgentModal} className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" onChange={handleInputChange} name="loginType"
                                                            ref={input7Ref} onKeyDown={(event) => handleKeyPress(event, input8Ref)} value={inputValues.loginType} onClick={inputClick} required>
                                                            <option Classname="form-control s-1" value="" selected></option>
                                                            {(() => {
                                                                if (loginTypeValue !== null && Array.isArray(loginTypeValue)) {
                                                                    return loginTypeValue.map((data) => (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            })()}
                                                        </select>
                                                        <label className="UseMasterLabelFix ">Login Type</label>
                                                    </div>
                                                    <div>
                                                        <i onClick={LoginTypePopup} className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                                                    </div>
                                                </div>
                                                {/* *********************************** Item Department Modal ******************************** */}
                                                <div>
                                                    {itemDepartmentModal && (
                                                        <div className="modal" onClick={ItemDepartmentPopup} >
                                                            <div className="OpticalField-modal increase_modal" onClick={handleModalClick}>
                                                                <div className="modal-title">
                                                                    <span className="close_modal" onClick={ItemDepartmentPopup}>
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                                <div className="tab-content-modal">
                                                                    <div className="company-info-top-modal control_flex_modal ">
                                                                        <ItemDepartmentMaster />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* ***************************************** Login Type Modal ***************************** */}
                                                <div>
                                                    {loginTypeModal && (
                                                        <div className="modal" onClick={LoginTypePopup} >
                                                            <div className="OpticalField-modal increase_modal" onClick={handleModalClick}>
                                                                <div className="modal-title">
                                                                    <span className="close_modal" onClick={LoginTypePopup}>
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                                <div className="tab-content-modal">
                                                                    <div className="company-info-top-modal control_flex_modal ">
                                                                        <LoginTypeMaster />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* ***************************************** Agent Modal ***************************** */}
                                                <div>
                                                    {agentModal && (
                                                        <div className="modal" onClick={AgentModal} >
                                                            <div className="OpticalField-modal increase_modal" onClick={handleModalClick}>
                                                                <div className="modal-title">
                                                                    <span className="close_modal" onClick={AgentModal}>
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                                <div className="tab-content-modal">
                                                                    <div className="company-info-top-modal control_flex_modal ">
                                                                        <AgentMaster />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="company-info-top-right control_flex">
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">
                                                {restaurantModal && (<span>Restaurant </span>)}
                                                &nbsp;{isModalOpen01 && (
                                                    <span>
                                                        Controls
                                                    </span>)}&nbsp;
                                                Details</div>
                                            <div className="HeadContentInputBpx">
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowModifyCompany"
                                                                ref={input8Ref} onKeyDown={(event) => handleKeyPress(event, input9Ref)} value={inputValues.isAllowModifyCompany} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Modification Of Company` </label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowModifyMaster"
                                                                ref={input9Ref} onKeyDown={(event) => handleKeyPress(event, input10Ref)} value={inputValues.isAllowModifyMaster} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Modification Of Master `</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowModifyVoucher"
                                                                ref={input10Ref} onKeyDown={(event) => handleKeyPress(event, input11Ref)} value={inputValues.isAllowModifyVoucher} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Modification Of Voucher</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowDeleteMaster"
                                                                ref={input11Ref} onKeyDown={(event) => handleKeyPress(event, input12Ref)} value={inputValues.isAllowDeleteMaster} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Deletion Of Master</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowDeleteVoucher"
                                                                ref={input12Ref} onKeyDown={(event) => handleKeyPress(event, input13Ref)} value={inputValues.isAllowDeleteVoucher} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Deletion Of Voucher</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowEmailReporting"
                                                                ref={input13Ref} onKeyDown={(event) => handleKeyPress(event, input14Ref)} value={inputValues.isAllowEmailReporting} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Email Of Reporting</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowSMSReporting"
                                                                ref={input14Ref} onKeyDown={(event) => handleKeyPress(event, input15Ref)} value={inputValues.isAllowSMSReporting} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow SMS Of Reporting</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowExportReport"
                                                                ref={input15Ref} onKeyDown={(event) => handleKeyPress(event, input16Ref)} value={inputValues.isAllowExportReport} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Export Of Report`</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isShwTotalAmtInRpt"
                                                                ref={input16Ref} onKeyDown={(event) => handleKeyPress(event, input17Ref)} value={inputValues.isShwTotalAmtInRpt} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Show Total Amount in Report</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowPrntOfRpt"
                                                                ref={input17Ref} onKeyDown={(event) => handleKeyPress(event, input18Ref)} value={inputValues.isAllowPrntOfRpt} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Print Of Reports`</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isShowPurchasePriceForItem"
                                                                ref={input18Ref} onKeyDown={(event) => handleKeyPress(event, input19Ref)} value={inputValues.isShowPurchasePriceForItem} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Show Purchase Price for Item` </label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowDataBackup"
                                                                ref={input19Ref} onKeyDown={(event) => handleKeyPress(event, input20Ref)} value={inputValues.isAllowDataBackup} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Data Backup</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowBackdateFurtherEntries"
                                                                ref={input20Ref} onKeyDown={(event) => handleKeyPress(event, input21Ref)} value={inputValues.isAllowBackdateFurtherEntries} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Backdate/Further Entries</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowLast3DigitNo"
                                                                ref={input21Ref} onKeyDown={(event) => handleKeyPress(event, input22Ref)} value={inputValues.isAllowLast3DigitNo} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Hide Last 3 Digit Mobile No`</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isRestrictionToAccessOtherSystem"
                                                                ref={input22Ref} onKeyDown={(event) => handleKeyPress(event, input23Ref)} value={inputValues.isRestrictionToAccessOtherSystem} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Restriction To Access Other System </label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowChangeInSalePriceDisc"
                                                                ref={input23Ref} onKeyDown={(event) => handleKeyPress(event, input24Ref)} value={inputValues.isAllowChangeInSalePriceDisc} onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Changes In Sale Prc & Discount</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowUseCancelOrdrFeature"
                                                                ref={input24Ref} onKeyDown={(event) => handleKeyPress(event, input25Ref)} value={inputValues.isAllowUseCancelOrdrFeature} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Use Cancel Order Feature</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        {(() => {
                                                            var val = restaurantModal ? input26Ref : input27Ref;
                                                            return (
                                                                <div className="input-box-mok1">
                                                                    <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isEnableBirthdayPopup"
                                                                        ref={input25Ref} onKeyDown={(event) => handleKeyPress(event, val)} value={inputValues.isEnableBirthdayPopup} onClick={inputClick} required>
                                                                        <option value="false">No</option>
                                                                        <option value="true">Yes</option>
                                                                    </select>
                                                                    <label className="modify_lbl_text3 userLeft1">Enable Birthday Popup</label>
                                                                </div>
                                                            )
                                                        })()}
                                                    </div>
                                                )}
                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowTableGuest"
                                                                ref={input26Ref} onKeyDown={(event) => handleKeyPress(event, input27Ref)} value={inputValues.isAllowTableGuest} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Table Option for Guest</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowBiometricAttendance"
                                                                ref={input27Ref} onKeyDown={(event) => handleKeyPress(event, input28Ref)} value={inputValues.isAllowBiometricAttendance} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Biometric Attendance</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="isAllowCheckAttendance"
                                                                ref={input28Ref} onKeyDown={(event) => handleKeyPress(event, input29Ref)} value={inputValues.isAllowCheckAttendance} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Check Attendance</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="MargeTwoInput">
                                                            <div className="input-box-mok1">
                                                                <input type="time" step="1" className="modify_im_input mode_category1" placeholder="HH:mm:ss"
                                                                    ref={input29Ref} onKeyDown={(event) => handleKeyPress(event, input30Ref)} onChange={handleInputChange} name="userLoginStartTime" onClick={inputClick} value={inputValues.userLoginStartTime} />
                                                                {/* <input className="modify_im_input mode_category1" type="time" onChange={handleInputChange} name="userLoginStartTime" value={inputValues.userLoginStartTime} onClick={inputClick} required /> */}
                                                                <label className="UseMasterLabelFix">User LogIn Start Time
                                                                    <span className="StarClr">*
                                                                        {errorMessages.userLoginStartTime && <label className="errorMessage">{errorMessages.userLoginStartTime}</label>}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="input-box-mok1">
                                                                <input type="time" step="1" className="modify_im_input mode_category1" placeholder="HH:mm:ss"
                                                                    ref={input30Ref} onKeyDown={(event) => handleKeyPress(event, input31Ref)} onChange={handleInputChange} name="userLoginEndTime" onClick={inputClick} value={inputValues.userLoginEndTime} />
                                                                {/* <input className="modify_im_input mode_category1" type="time" onChange={handleInputChange} name="userLoginEndTime" value={inputValues.userLoginEndTime} onClick={inputClick} required /> */}
                                                                <label className="UseMasterLabelFix FixEndH"> End Time
                                                                    <span className="StarClr">*
                                                                        {errorMessages.userLoginEndTime && <label className="errorMessage">{errorMessages.userLoginEndTime}</label>}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} name="allowSpecificComputerName"
                                                                ref={input31Ref} onKeyDown={(event) => handleKeyPress(event, input32Ref)} value={inputValues.allowSpecificComputerName} onClick={inputClick} required />
                                                            <label className="modify_p_text2">Allow Specific Computer Name</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="number" onChange={handleInputChange} name="isAllowNoOfLoginsAsSameUser"
                                                                ref={input32Ref} onKeyDown={(event) => handleKeyPress(event, input33Ref)} value={inputValues.isAllowNoOfLoginsAsSameUser} onClick={inputClick} required />
                                                            <label className="modify_p_text2">Allow No Of Logins For Same User</label>
                                                        </div>
                                                    </div>)}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="userRoleCreateBtnVisible"
                                                                ref={input33Ref} onKeyDown={(event) => handleKeyPress(event, input34Ref)} value={inputValues.userRoleCreateBtnVisible}
                                                                type="text" onClick={inputClick} required>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Show Add Button to Create User`</label>
                                                        </div>
                                                    </div>)}

                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        {(() => {
                                                            let val = "";
                                                            switch (true) {
                                                                case isIPAddres:
                                                                    val = input35Ref;
                                                                    break;
                                                                case restaurantModal:
                                                                    val = input36Ref;
                                                                    break;
                                                                default:
                                                                    val = input42Ref;
                                                            }
                                                            return (
                                                                <div className="input-box-mok1">
                                                                    <select className="modify_im_input mode_category" onChange={handleInputChange} name="isAllowStaticIP" value={inputValues.isAllowStaticIP}
                                                                        ref={input34Ref} onKeyDown={(event) => handleKeyPress(event, val)}
                                                                        type="text" onClick={inputClick} required>
                                                                        <option value="No">No</option>
                                                                        <option value="Yes">Yes</option>
                                                                    </select>
                                                                    <label className="modify_lbl_text3 userLeft1">Allow Static IP</label>
                                                                </div>
                                                            )
                                                        })()}
                                                    </div>
                                                )}
                                                {isIPAddres && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="">
                                                            <div className="input-box-mok1">
                                                                <input className="modify_im_input mode_category1" onChange={handleInputChange} name="ipAddress" value={inputValues.ipAddress}
                                                                    ref={input35Ref} onKeyDown={(event) => handleKeyPress(event, input36Ref)} onClick={inputClick} required />
                                                                <label className="modify_p_text2">IP Address</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="canRestAllowDelvryApp"
                                                                ref={input36Ref} onKeyDown={(event) => handleKeyPress(event, input37Ref)} value={inputValues.canRestAllowDelvryApp} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Use Delivery Boy App</label>
                                                        </div>
                                                    </div>)}
                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="canRestAllowKOTApp"
                                                                ref={input37Ref} onKeyDown={(event) => handleKeyPress(event, input38Ref)} value={inputValues.canRestAllowKOTApp} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Use KOT App</label>
                                                        </div>
                                                    </div>)}
                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="canRestUseChefApp"
                                                                ref={input38Ref} onKeyDown={(event) => handleKeyPress(event, input39Ref)} value={inputValues.canRestUseChefApp} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Use Chef App</label>
                                                        </div>
                                                    </div>)}
                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="canRestUseOrderApp"
                                                                ref={input39Ref} onKeyDown={(event) => handleKeyPress(event, input42Ref)} value={inputValues.canRestUseOrderApp} onClick={inputClick} required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">Allow Use Order App</label>
                                                        </div>
                                                    </div>
                                                )}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-Imo1 input-height2" //ref={containerRef2} 
                                                            style={{ padding: "0px" }} ref={input40Ref} onKeyDown={(event) => handleKeyPress(event, input41Ref)} onClick={handleModalClick}>
                                                            <div className="input-box-mok1" onClick={allowAcessToggle}>
                                                                <div className="selected-options modify_im_input mode_category1" style={{ height: 'auto' }}>
                                                                    {firmNameOption2.length > 0 ? firmNameOption2.join(', ') : 'Select Firm Names'}
                                                                </div>
                                                                <label className="UseMasterLabelFix">Select Firm to Allow Access</label>
                                                                <div className="overSelect"></div>
                                                            </div>
                                                            {isOpen2 && (
                                                                <div id="checkBoxes" style={{}}>
                                                                    {options1.map((option) => (
                                                                        <label key={option}>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={option}
                                                                                checked={firmNameOption2.includes(option)}
                                                                                onChange={() => handleFirmName(option, setFirmNameOption2, firmNameOption2, 'selectFirmToAllowAccess')}
                                                                            />
                                                                            &nbsp; {option}
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {isModalOpen01 && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-Imo1 input-height2 " //ref={containerRef2} 
                                                            style={{ padding: "0px" }} ref={input41Ref} onKeyDown={(event) => handleKeyPress(event, input42Ref)} onClick={handleModalClick}>
                                                            <div className="input-box-mok1" onClick={showSettingBtnToggle}>
                                                                <div className="selected-options modify_im_input mode_category1" style={{ height: 'auto' }}>
                                                                    {firmNameOption3.length > 0 ? firmNameOption3.join(', ') : 'Select Firm Names'}
                                                                </div>
                                                                <label className="UseMasterLabelFix">Select Form to Show Setting Button</label>
                                                                <div className="overSelect"></div>
                                                            </div>
                                                            {isOpen && (
                                                                <div id="checkBoxes" style={{}}>
                                                                    {options2.map((option) => (
                                                                        <label key={option}>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={option}
                                                                                checked={firmNameOption3.includes(option)}
                                                                                onChange={() => handleFirmName(option, setFirmNameOption3, firmNameOption3, 'selectFormToShwSettngBtn')}
                                                                            />
                                                                            &nbsp; {option}
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="CompWidthMasterUser" ref={containerRef}>
                                                    {(() => {
                                                        var val = restaurantModal ? input43Ref : input2Ref;
                                                        return (
                                                            <div className="input-box-mok1">
                                                                <select className="modify_im_input mode_category1" onChange={handleInputChange} name="recordVisibility"
                                                                    ref={input42Ref} onKeyDown={(event) => handleKeyPress(event, val)} value={inputValues.recordVisibility} onClick={inputClick} required>
                                                                    <option value="false">Only the Creator</option>
                                                                    <option value="true">All</option>
                                                                </select>
                                                                <label className="UseMasterLabelFix">Record Visibility</label>
                                                            </div>
                                                        )
                                                    })()}

                                                </div>

                                                {restaurantModal && (
                                                    <div className="CompWidthMasterUser">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" onChange={handleInputChange} name="userWiseBillSeries"
                                                                ref={input37Ref} onKeyDown={(event) => handleKeyPress(event, input38Ref)} value={inputValues.userWiseBillSeries} onClick={inputClick} required>
                                                                <option value="0">--Select--</option>
                                                                {BillSeriesData.map(bill => (
                                                                    <option value={bill.id}>{bill.billSeriesName}</option>
                                                                ))}
                                                            </select>
                                                            <label className="modify_lbl_text3 userLeft1">User Wise Bill Series</label>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="CompWidthMasterUser">
                                                    {/* ****************** Service Type **************** */}
                                                    {restaurantModal && (
                                                        <div className="input-box-Imo1 input-height2 "
                                                            style={{ padding: "0px" }} ref={input42Ref} onKeyDown={(event) => handleKeyPress(event, input42Ref)} onClick={handleModalClick}>
                                                            <div className="input-box-mok1" onClick={showServiceTypeToggle}>
                                                                <div className="selected-options modify_im_input mode_category1" style={{ height: 'auto' }}>
                                                                    {serviceTypeOption.length > 0 ? getSelectedServiceNames() : 'Select Service Type'}
                                                                </div>
                                                                <label className="UseMasterLabelFix">Allow Service to User</label>
                                                                <div className="overSelect"></div>
                                                            </div>
                                                            {isOpen4 && (
                                                                <div id="checkBoxes" style={{}}>
                                                                    {serviceType.map((option) => (
                                                                        <label key={option}>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={option.id}
                                                                                checked={serviceTypeOption.includes(option.id)}
                                                                                onChange={() => handleServiceType(option.id, setServiceTypeOption, serviceTypeOption, 'serviceType')}
                                                                            />
                                                                            &nbsp; {option.name}
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {restaurantModal && (
                                            <div className="control_radius">
                                                <div className="company_info-text-im depart_top">Delivery Details</div>
                                                <div className="company_control">
                                                    <div className="stock-im-b1">
                                                        <div className="input-box-Imo1 input-height2 user_add">
                                                            <div className="input-box-mok1">
                                                                <select className="modify_im_input mode_category1" onChange={handleInputChange} name="vehicleNo"
                                                                    ref={input43Ref} onKeyDown={(event) => handleKeyPress(event, input44Ref)} value={inputValues.vehicleNo} onClick={inputClick} required>
                                                                    <option Classname="form-control s-1" value="" ></option>
                                                                    {(() => {
                                                                        if (vehicleValue !== null && Array.isArray(vehicleValue)) {
                                                                            return vehicleValue.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label className="modify_p_text2">Vehicle No. </label>
                                                            </div>
                                                            <div>
                                                                <i onClick={VehicleModalPopup} className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="stock-im-b1">
                                                        <div className="input-box-Imo1 input-height2 user_add">
                                                            <div className="input-box-mok1">
                                                                <select className="modify_im_input mode_category1" onChange={handleInputChange} name="deliveryBoyIncentive"
                                                                    ref={input44Ref} onKeyDown={(event) => handleKeyPress(event, input1Ref)} value={inputValues.deliveryBoyIncentive} onClick={inputClick} required>
                                                                    <option Classname="form-control s-1" value="" >--- Select ---</option>
                                                                    {(() => {
                                                                        if (deliveryIncentiveValue !== null && Array.isArray(deliveryIncentiveValue)) {
                                                                            return deliveryIncentiveValue.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label className="modify_lbl_text3">Delivery Boy Incentive</label>
                                                            </div>
                                                            <div>
                                                                <i onClick={DeliveryIncentivePopup} className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ************************ Vehicle Modal **************************** */}
                                                    <div>
                                                        {vehicleModal && (
                                                            <div className="modal" onClick={VehicleModalPopup} >
                                                                <div className="OpticalField-modal increase_modal" onClick={handleModalClick}>
                                                                    <div className="modal-title">
                                                                        <span className="close_modal" onClick={VehicleModalPopup}>
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                    <div className="tab-content-modal">
                                                                        <div className="company-info-top-modal control_flex_modal item_scroll">
                                                                            <TransportMaster />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>)}

                                        {restaurantModal && (
                                            <div>
                                                {deliveryIncentiveModal && (
                                                    <div className="modal" onClick={DeliveryIncentivePopup} >
                                                        <div className="OpticalField-modal increase_modal" onClick={handleModalClick}>
                                                            <div className="modal-title">
                                                                <span className="close_modal" onClick={DeliveryIncentivePopup}>
                                                                    &times;
                                                                </span>
                                                            </div>
                                                            <div className="tab-content-modal">
                                                                <div className="company-info-top-modal control_flex_modal item_scroll">
                                                                    <IncentiveMaster />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>)}
                                        {restaurantModal && (
                                            <div>
                                                {AllocatedTable && (
                                                    <div>
                                                        <div className="control_radius">
                                                            <div className="company_info-text-im depart_top">Alloted Tables</div>
                                                            {/* <div className="tab-content-02">
                                                                <div className="tableGridView">
                                                                    {(() => {
                                                                        if (tableValue !== null && Array.isArray(tableValue)) {
                                                                            return tableValue.map((data, i) => (
                                                                                // console.log(data.tableName),
                                                                                <div key={data.id} value={data.id} style={{ padding: "5px" }}>
                                                                                    <input type="checkbox" checked={data.id == tableSelection[i]} onChange={() => handleCheckBoxChange(data.id)} />
                                                                                    &nbsp;{data.tableName}
                                                                                </div>
                                                                            ))
                                                                        }              //checked={inputValues.userTables[i]}
                                                                    })()}
                                                                </div>
                                                            </div> */}
                                                            <div className="tab-content-02">
                                                                <div className="tableGridView">
                                                                    {tableValue && Array.isArray(tableValue) && tableValue.map((data) => (
                                                                        <div key={data.id} value={data.id} style={{ padding: "5px" }}>
                                                                            <input type="checkbox" checked={tableSelection.includes(data.id)} onChange={() => handleCheckBoxChange(data.id)} />
                                                                            &nbsp;{data.tableName}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your User Master is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your User Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default UserMaster;
