import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TransactionTypeSetting = {
    insertTransactionTypeSetting: async (data) => {
        function parseBoolean(v) {
            if (typeof v === "boolean") {
              return v;
            } else if (typeof v === "string") {
              v = v.toLowerCase().trim();
              return v === "true";
            } else {
              console.log("error", v);
              return false;
            }
          }
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/CreateTransactionTypeSetting`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                transType:Number(data.transType),
                transName: data.transName,
                transAccount: Number(data.transAccount),
                enableStockCalculation: parseBoolean(data.enableStockCalculation),
                stockNature: parseBoolean(data.stockNature),
                transAccTaxAccAssetsAcc: parseBoolean(data.transAccTaxAccAssetsAcc),
                transAccTaxAccAssetsAccSign: parseBoolean(data.transAccTaxAccAssetsAccSign),
                otherSettlementAcc: parseBoolean(data.otherSettlementAcc),
                otherSettlementAccSign: parseBoolean(data.otherSettlementAccSign),
                cashFlow: parseBoolean(data.cashFlow),
                cashFlowSign: parseBoolean(data.cashFlowSign),
                settlementAmountNature: parseBoolean(data.settlementAmountNature),
                enableLedgerCalculation: parseBoolean(data.enableLedgerCalculation),
                enableTax: parseBoolean(data.enableTax),
                taxType: parseBoolean(data.taxType),
                price: Number(data.price),
                enableSellingPrice: parseBoolean(data.enableSellingPrice),
                sellingPrice: Number(data.sellingPrice),
                invoiceType: Number(data.invoiceType),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getAllTransactionTypeSetting: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetAllTransactionTypeSetting`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
     // ************************* Get All ***************************
     getTransactionTypeSettingFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetAllTransactionTypeSetting`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteTransactionTypeSetting: async (transTypeId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/DeleteTransactionTypeSetting?transTypeId=${transTypeId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {   
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getTransactionTypeSettingById: async (transTypeId) => {
        debugger
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetTransactionTypeSetting?transTypeId=${transTypeId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
      // ************************* Get By Id ***************************

      GetTransactionTypeSetting: async (TransType) => {
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetTransactionTypeSetting?TransType=${TransType }`;
          const response = await axios.get(apiUrl, {}, {
            withCredentials: true,
          });
          console.log('Response:', response.data);
          return response.data.result;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
    
    // *************************** Update ***************************
    updateTransactionTypeSetting: async (data) => {
        function parseBoolean(v) {
            if (typeof v === "boolean") {
              return v;
            } else if (typeof v === "string") {
              v = v.toLowerCase().trim();
              return v === "true";
            } else {
              console.log("error", v);
              return false;
            }
          }
        debugger
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/UpdateTransactionTypeSetting?transTypeId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: Number(data.id),
                transType:Number(data.transType),
                transName: data.transName,
                transAccount: Number(data.transAccount),
                enableStockCalculation: parseBoolean(data.enableStockCalculation),
                stockNature: parseBoolean(data.stockNature),
                transAccTaxAccAssetsAcc: parseBoolean(data.transAccTaxAccAssetsAcc),
                transAccTaxAccAssetsAccSign: parseBoolean(data.transAccTaxAccAssetsAccSign),
                otherSettlementAcc: parseBoolean(data.otherSettlementAcc),
                otherSettlementAccSign: parseBoolean(data.otherSettlementAccSign),
                cashFlow: parseBoolean(data.cashFlow),
                cashFlowSign: parseBoolean(data.cashFlowSign),
                settlementAmountNature: parseBoolean(data.settlementAmountNature),
                enableLedgerCalculation: parseBoolean(data.enableLedgerCalculation),
                enableTax: parseBoolean(data.enableTax),
                taxType: parseBoolean(data.taxType),
                price: Number(data.price),
                enableSellingPrice: parseBoolean(data.enableSellingPrice),
                sellingPrice: Number(data.sellingPrice),
                invoiceType: Number(data.invoiceType),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    
      // ************************* Get All ***************************
  /*
      GetAllTransactionTypeSetting_WithFilter: async (filterData) => {
        try {
          const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
          }));
  
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetAllTransactionTypeSetting`;
          const response = await axios.post(apiUrl, {
            filter: filteredData,
            order: [
                {
                    propertyName: "id",
                    ascending: false
                }
            ],
            pageNumber: 0,
            pageSize: 500000
          }, {
            withCredentials: true,
          });
          console.log('Response:', response.data.result);
          return response.data.result;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
      */
    
      // ************************* Delete ****************************
  
      DeleteTransactionTypeSetting: async (TransType) => {
        try { 
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/DeleteTransactionTypeSetting?TransType=${TransType}`;
          const response = await axios.delete(apiUrl, {
            withCredentials: true,
          });
          console.log('Delete Response:', response.data.result);
          return true;
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
  
      /*----------------------Voucher Wise Input Setting---------------------------------------------------------------------*/
      
      // *************************** Insert ***************************
  
      CreateVoucherWise_InputSettingAsync: async (data) => {
        console.log(data);
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/CreateVoucherWise_InputSettingAsync`;
          console.log(apiUrl);
          const response = await axios.post(apiUrl, {
           
            masterType: data.masterType,
            transType: data.transType,
            transName: data.transName,
  
            title: data.title,
            dateOfBirth: data.dateOfBirth,
            anniversaryDate: data.anniversaryDate,
            pos: data.pos,
            accBal: data.accBal,
            creditLimit: data.creditLimit,
            referredBy: data.referredBy,
            bookedBy: data.bookedBy,
            godown: data.godown,
            deliveryStatus: data.deliveryStatus,
            deliveryDate: data.deliveryDate,
            remark: data.remark,
            remark1: data.remark1,
            remark2: data.remark2,
            remark3: data.remark3,
  
            extraField1: data.extraField1,
            extraField2: data.extraField2,
            extraField3: data.extraField3,
            extraField4: data.extraField4,
            extraField5: data.extraField5,
            createdBy: data.createdBy,
            createdOn: data.createdOn,
          }, {
            withCredentials: true,
            credentials: 'include',
          });
    
          console.log("Response:", response.data);
          return true;
        } catch (error) {
          console.error("Error:", error.message);
    
          if (error.response && error.response.status === 401) {
            alert("Failed");
          } else {
            alert("An error occurred. Please try again.");
          }
        }
      },
      
      // *************************** Update ***************************
    
      UpdateVoucherWise_InputSettingAsync: async (data) => {
        console.log(data);debugger;
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/UpdateVoucherWise_InputSettingAsync`;
          console.log(apiUrl);
          const response = await axios.put(apiUrl, {
  
            id: data.id,
            masterType: data.masterType,
            transType: data.transType,
            transName: data.transName,
            
            title: data.title,
            dateOfBirth: data.dateOfBirth,
            anniversaryDate: data.anniversaryDate,
            pos: data.pos,
            accBal: data.accBal,
            creditLimit: data.creditLimit,
            referredBy: data.referredBy,
            bookedBy: data.bookedBy,
            godown: data.godown,
            deliveryStatus: data.deliveryStatus,
            deliveryDate: data.deliveryDate,
            remark: data.remark,
            remark1: data.remark1,
            remark2: data.remark2,
            remark3: data.remark3,
  
            extraField1: data.extraField1,
            extraField2: data.extraField2,
            extraField3: data.extraField3,
            extraField4: data.extraField4,
            extraField5: data.extraField5,
            createdBy: data.createdBy,
            createdOn: data.createdOn,
            updatedBy: data.updatedBy,
            updatedOn: data.updatedOn,
          }, {
            withCredentials: true,
            credentials: 'include',
          });
    
          console.log("Response:", response.data);
          return response.data.isSuccess;
        } catch (error) {
          console.error("Error:", error.message);
    
          if (error.response && error.response.status === 401) {
            alert("Failed");
          } else {
            alert("An error occurred. Please try again.");
          }
        }
      },
   
      // ************************* Get By Id ***************************
  
      GetVoucherWise_InputSetting: async (TransType) => {
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetVoucherWise_InputSetting?TransType=${TransType}`;
          const response = await axios.get(apiUrl, {}, {
            withCredentials: true,
          });
          console.log('Response:', response.data);
          return response.data;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
    
      // ************************* Get All ***************************
      /*
      GetAllVoucherWise_InputSetting_WithFilter: async (filterData) => {
        try {
          const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
          }));
  
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetAllVoucherWise_InputSetting`;
          const response = await axios.post(apiUrl, {
            filter: filteredData,
            order: [
                {
                    propertyName: "id",
                    ascending: false
                }
            ],
            pageNumber: 0,
            pageSize: 500000
          }, {
            withCredentials: true,
          });
          console.log('Response:', response.data.result);
          return response.data.result;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
      */
  
      // ************************* Delete ****************************
      
      DeleteVoucherWise_InputSetting: async (TransType) => {
        try { 
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/DeleteVoucherWise_InputSetting?id=${TransType}`;
          const response = await axios.delete(apiUrl, {
            withCredentials: true,
          });
          console.log('Delete Response:', response.data.result);
          return true;
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
  
  
      /*----------------------Voucher Wise Column Setting---------------------------------------------------------------------*/
  
      // *************************** Insert ***************************
  
      CreateVoucherWise_ColumnSettingAsync: async (data) => {
        debugger
        console.log(data);
        const processedData = data.map(loop => ({
            masterType: loop.masterType,
            transType: loop.transType,
            transName: loop.transName,
  
            colId: loop.colId || '',
            colName: loop.colName || '',
            colShow: loop.colShow || '',         
            colWidth: loop.colWidth || '',
            colType: loop.colType || '',
  
            extraField1: loop.extraField1 || '',
            extraField2: loop.extraField2 || '',
            extraField3: loop.extraField3 || '',
            createdBy: loop.createdBy || '',
            createdOn: loop.createdOn || '',
        }));
  
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/CreateVoucherWise_ColumnSettingAsync`;
          console.log(apiUrl);
          const response = await axios.post(apiUrl, {
            processedData
          }, {
            withCredentials: true,
            credentials: 'include',
          });
    
          console.log("Response:", response.data);
          return response.data.isSuccess;
        } catch (error) {
          console.error("Error:", error.message);
    
          if (error.response && error.response.status === 401) {
            alert("Failed");
          } else {
            alert("An error occurred. Please try again.");
          }
        }
      },
      
      // *************************** Update ***************************
    
      UpdateVoucherWise_ColumnSettingAsync: async (data) => {
        debugger
        console.log(data);
        const processedData = data.map(loop => ({
          id: loop.id || 0,
          masterType: loop.masterType,
          transType: loop.transType,
          transName: loop.transName,
  
          colId: loop.colId || '',
          colName: loop.colName || '',
          colShow: loop.colShow || '',         
          colWidth: loop.colWidth || '',
          colType: loop.colType || '',
  
          extraField1: loop.extraField1 || '',
          extraField2: loop.extraField2 || '',
          extraField3: loop.extraField3 || '',
          createdBy: loop.createdBy || '',
          createdOn: loop.createdOn || '',
          updatedBy: loop.updatedBy || '',
          updatedOn: loop.updatedOn || '',
        }));
  
  
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/UpdateVoucherWise_ColumnSettingAsync`;
          console.log(apiUrl);
          const response = await axios.put(apiUrl, {
            processedData
          }, {
            withCredentials: true,
            credentials: 'include',
          });
    
          console.log("Response:", response.data);
          return response.data.isSuccess;
        } catch (error) {
          console.error("Error:", error.message);
    
          if (error.response && error.response.status === 401) {
            alert("Failed");
          } else {
            alert("An error occurred. Please try again.");
          }
        }
      },
   
      // ************************* Get By Id ***************************
  
      GetVoucherWise_ColumnSetting: async (TransType) => {
        try {
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetVoucherWise_ColumnSetting?TransType=${TransType}`;
          const response = await axios.get(apiUrl, {}, {
            withCredentials: true,
          });
          console.log('Response:', response.data);
          return response.data;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
    
      // ************************* Get All ***************************
      /*
      GetAllVoucherWise_InputSetting_WithFilter: async (filterData) => {
        try {
          const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
          }));
  
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/GetAllVoucherWise_ColumnSetting`;
          const response = await axios.post(apiUrl, {
            filter: filteredData,
            order: [
                {
                    propertyName: "id",
                    ascending: false
                }
            ],
            pageNumber: 0,
            pageSize: 500000
          }, {
            withCredentials: true,
          });
          console.log('Response:', response.data.result);
          return response.data.result;
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
      */
  
      // ************************* Delete ****************************
      
      DeleteVoucherWise_ColumnSetting: async (TransType) => {
        try { 
          const apiUrl = `${WebApiUrl}/api/TransactionTypeSetting/DeleteVoucherWise_ColumnSetting?TransType=${TransType}`;
          const response = await axios.delete(apiUrl, {
            withCredentials: true,
          });
          console.log('Delete Response:', response.data.result);
          return true;
        } catch (error) {
          console.error('Error:', error.message);
        }
      },
       





};

export default TransactionTypeSetting;
